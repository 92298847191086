import React from 'react';
import {View, Text, TouchableOpacity, ScrollView, TextInput, Image, Dimensions, ActivityIndicator, Modal, Animated, TouchableWithoutFeedback, Keyboard} from 'react-native';
import IonIcon from '@expo/vector-icons/Ionicons';
import MaterialCommunityIcon from '@expo/vector-icons/MaterialCommunityIcons';
import FontAwesomeBasicIcon from '@expo/vector-icons/FontAwesome';
import { CommonActions } from '@react-navigation/native'
import AntIcon from '@expo/vector-icons/AntDesign';
import AsyncStorage from '@react-native-async-storage/async-storage';
import API from './API.js';
import Colors from './Colors';
import OrderAdditional from './components/OrderAdditionals.js';
import BottomSheet from "react-native-gesture-bottom-sheet";
import { WebView } from 'react-native-webview';
import OrderReviewDestination from './components/OrderReviewDestination.js';
import PaymentMethod from './components/PaymentMethod.js';
import Utils from './Utils.js';
import FontistoIcon from '@expo/vector-icons/Fontisto';
const Amplitude = Platform.select({
    web: require('./FakeAmplitude').default,
    default: require('@amplitude/analytics-react-native')
  })
import EvilIcon from '@expo/vector-icons/EvilIcons';
import DemandIcon from './components/DemandIcon.js';
import SavedCard from './components/SavedCard.js';
import AddCardModal from './components/AddCardModal.js';
import CustomModal from './CustomModal.js';
import { CheckBox } from 'react-native-elements';
import RBSheet from 'react-native-raw-bottom-sheet';
import OTPInputView from './components/otp'
import ParamLogo from './components/ParamLogo';
import PromotionsModal from './components/PromotionsModal.js';
import Constants from 'expo-constants';
import { Platform } from 'expo-modules-core';
import { KeyboardAvoidingView } from 'react-native';

const YapiKrediAPI = require('./PassportYapiKrediMobile.js');

const LiteCreditCardInput = Platform.select({
    web: () => <View></View>,
    default: () => require('react-native-credit-card-input').LiteCreditCardInput,
  })();

var vehicleImgs = [
    require('./assets/vehicles/Motor.png'),
   undefined, // require('./assets/vehicles/Sedan.png'),
    require('./assets/vehicles/Doblo.png'),
    undefined, //require('./assets/vehicles/Transit.png'),
    require('./assets/vehicles/Panelvan.png'),
    require('./assets/vehicles/Kamyonet.png')
];

const cardIcons = {
    "american-express": require('./node_modules/react-native-credit-card-input/src/icons/stp_card_amex.png'),
    "diners-club": require('./node_modules/react-native-credit-card-input/src/icons/stp_card_cvc_amex.png'),
    "discover": require('./node_modules/react-native-credit-card-input/src/icons/stp_card_discover.png'),
    "jcb": require('./node_modules/react-native-credit-card-input/src/icons/stp_card_jcb.png'),
    "mastercard": require('./node_modules/react-native-credit-card-input/src/icons/stp_card_mastercard.png'),
    "unknown": require('./node_modules/react-native-credit-card-input/src/icons/stp_card_unknown.png'),
    "visa": require('./node_modules/react-native-credit-card-input/src/icons/stp_card_visa.png')
  }
export default class OrderDetailsMenu extends React.Component {

    constructor({route, navigation}) {
        super();

        this.navigation = navigation;
        this.state = {additionalSelections: [false,false,false,false,0],driverNotificationPopup:false, helperServiceSelected: false, helperServiceError: false,webViewSource: "",installmentSelected:0,installmentRates: [],selectedPaymentMethod: (API.user.business)?3:0,popupText: "", orderInProcess: false, paymentMethodOpened:false, note: "",savedCards: []}
        this.order = {...route.params.order};


        if(this.order.calculation.coupon) {
            this.state.coupon = this.order.calculation.coupon;
            delete this.order.calculation.coupon;
            delete this.order.calculation.discount;
        }
        this.fetchPricing();
        
        
        if(typeof(this.order.date) == 'number') this.order.date = new Date(this.order.date);
        
        //global.showLoadingPanel();
        this.additionalPrices = [0,0,0,50,30];
        this.popupAnimation = new Animated.Value(0);

        this.help = this.order.waypoints.find(s => s.help) != null;
        



        
        //this.fetchPricing();
    }

    async fetchPricing() {
        
        //const dat = await API.fetch("calculate", {vehicle: this.order.vehicle,waypoints:this.order.waypoints,date:(this.order.date.getTime() <= Date.now()?0:this.order.date.getTime())});
        
        if(Platform.OS == 'web') {
            this.state.selectedPaymentMethod = 4;
            this.forceUpdate();
            global.hideLoadingPanel();

            return;
        }


        var paymentMethod = await AsyncStorage.getItem("paymentMethod");
        
        if(paymentMethod && paymentMethod.length == 1) {
            try {
                paymentMethod = parseInt(paymentMethod);
            } catch {
                paymentMethod = undefined;
            }
        }

        if(paymentMethod == undefined || (paymentMethod == 1 && this.state.coupon))
            paymentMethod = 0;
        else if(typeof paymentMethod == 'string') {
            var card = API.user.cards.find((s) => s._id == paymentMethod);
            if(!card) paymentMethod = 0;
            else {
                global.showLoadingPanel();
                
                var f = await API.fetch('installments', {number: card.number,price: this.calculateEstimate(true)});
                if(!f.error) this.state.installmentRates = f;
            }
        }

        if(this.help) paymentMethod = 1;
        
        this.state.selectedPaymentMethod = paymentMethod;
        //console.log(dat);

        //this.order.calculation = dat;
        this.forceUpdate();
        

        global.hideLoadingPanel();
    }

    getDateString() {

        var now = new Date();

        var time = global.getText("asap");
        
        if(this.order.date > now) {
            time = digit(this.order.date.getHours()) + ":" + digit(this.order.date.getMinutes());
        }

        return (this.order.date.getDate() == now.getDate()?global.getText("today"):this.order.date.getDate() == now.getDate()+1?global.getText("tomorrow"):global.getText("days")[this.order.date.getDay()]) + " " + this.order.date.getDate() + " " + global.getText("months")[this.order.date.getMonth()] + ", " + time; 
    }

    getExtras() {
        
        var a = [];

        for(var i = 0; i < this.state.additionalSelections.length; i++) {
            if(i == 4) {
                if(this.state.additionalSelections[i] > 0) a.push({t: global.getText("additionalPrices")[4]+" x" + this.state.additionalSelections[i], p: (API.additionalPrices[4][this.order.vehicle]*Math.max(this.state.additionalSelections[4]-1,0))+(Math.min(this.state.additionalSelections[4],1)*API.additionalPrices[3][this.order.vehicle])});
                continue;
            }
            if(this.state.additionalSelections[i] && API.additionalPrices[i] > 0) a.push({t: global.getText("additionalPrices")[i], p: API.additionalPrices[i]});
        }

        return a;
    }

    getTimeDisplay() {
        var minutes = (this.order.calculation)?this.order.calculation.time:0;
        var hours = Math.floor(minutes/60);
        minutes -= hours*60;

        return Utils.getHoursAndMinutes(hours, minutes);
        //return ((hours > 0)?hours + "hr"+(hours > 1?"s":""):"") + ((minutes> 0)?((hours > 0)?" ":"")+minutes+"m":"");
    }


    calculateEstimate(ignoreInterest) {
        
        if(!this.order.calculation) return 0;
        var price = this.order.calculation.price;

        for(var i = 0; i < this.state.additionalSelections.length; i++) {
            if(i == 4) {
                
                
                price += ((API.additionalPrices[4][this.order.vehicle]*Math.max(this.state.additionalSelections[4]-1,0))+(Math.min(this.state.additionalSelections[4],1)*API.additionalPrices[3][this.order.vehicle]))
                //if(this.state.additionalSelections[i] > 0) price += this.state.additionalSelections[4]*API.additionalPrices[4];
                continue;
            }
            if(this.state.additionalSelections[i]) price += API.additionalPrices[i];
        }

        if(this.state.coupon) {
            price -= this.calculateCouponDiscount();
        }
        if(!ignoreInterest && typeof this.state.selectedPaymentMethod == 'string') price *= 1+(this.state.installmentRates[this.state.installmentSelected]/100);

        
        return price;
    }

    calculateCouponDiscount() {

        return API.calculateCouponDiscount(this.state.coupon, {...this.order,additionalSelections: this.state.additionalSelections});
        if(!this.state.coupon) return 0;


        var price = this.order.calculation.price;

        for(var i = 0; i < this.state.additionalSelections.length; i++) {
            if(i == 4) {
                if(this.state.additionalSelections[i] > 0) price += this.state.additionalSelections[4]*API.additionalPrices[4];
                continue;
            }
            if(this.state.additionalSelections[i]) price += API.additionalPrices[i];
        }

        if(this.state.coupon.type == 0) {
            var discountType = (typeof this.state.coupon.discounts == 'number' || typeof this.state.coupon.discounts == 'string')?this.state.coupon.discounts:this.state.coupon.discounts[this.order.vehicle];

            if(typeof discountType == 'number') return discountType;
            return price * (1 - (parseInt(discountType)/100));
        }
    }

    async createOrder(paymentDetails) {
        
        this.setState({orderInProcess: true});
        
        global.startPayment("order", {additionals: this.state.additionalSelections, coupon: this.state.coupon != undefined?this.state.coupon._id:undefined,installments: this.state.installmentSelected,vehicle:this.order.vehicle,paymentMethod: this.state.selectedPaymentMethod, paymentDetails,note: this.state.note, price: this.calculateEstimate(),saveCard: (this.state.selectedPaymentMethod == 0)?this.state.onlinePaymentSaveCard:false, cardName: this.state.creditCardName},(response) => {
            if(response.error) {
                Amplitude.track("ORDER_FAIL", {vehicle:this.order.vehicle});
                return;
            }
            if(response._id) {
                this.openOrder(response._id);
            }

            console.log(response);
            if(response.paymentToken) {
                YapiKrediAPI.startPayment(response.paymentToken);
            }
        });
        
        return;
        global.showLoadingPanel();
        this.setState({orderInProcess: true});
        
        var response = await API.fetch('order', {additionals: this.state.additionalSelections, coupon: this.state.coupon != undefined?this.state.coupon._id:undefined,installments: this.state.installmentSelected,vehicle:this.order.vehicle,paymentMethod: this.state.selectedPaymentMethod, paymentDetails,note: this.state.note, price: this.calculateEstimate(),saveCard: (this.state.selectedPaymentMethod == 0)?this.state.onlinePaymentSaveCard:false, cardName: this.state.creditCardName});
            
        if(response.error) {
            Amplitude.track("ORDER_FAIL", {vehicle:this.order.vehicle});
            return;
        }
        if(response.savedCard) {
            API.user.cards.push(response.savedCard);
            this.forceUpdate();
        }

        if(response.UCD_URL) {
            // WebBrowser.openBrowserAsync(response.UCD_URL).then((browser) => {
                
            // });
            global.hideLoadingPanel();
            this.setState({webViewSource: response.UCD_URL});
            this.secureCustomModal.open();
            return;
        }

        if(response._id) {
            this.openOrder(response._id);
        }
        
    }

    openPopup(text, callback) {
        this.setState({popupText: text});
        this.popupSuccessCallback = callback;
        Animated.timing(this.popupAnimation, {toValue:1, useNativeDriver: true, duration:100}).start();
    }
    
    showMoverInfo() {

        global.showCustomPopup(global.getText("moverSelectionPopupHeader"), <>

            <Text style={{fontWeight:"600", marginTop: 10}}>{global.getText("moverSelectionPopup")[0]}</Text>
            <Text style={{textAlign:"center", marginTop:3}}>{global.getText("moverSelectionPopup")[1]}</Text>

            <Text style={{fontWeight:"600", marginTop: 10}}>{global.getText("moverSelectionPopup")[2]}</Text>
            <Text style={{textAlign:"center", marginTop:3}}>{global.getText("moverSelectionPopup")[3]}</Text>

            <Text style={{fontWeight:"600", marginTop: 10}}>{global.getText("moverSelectionPopup")[4]}</Text>
            <Text style={{textAlign:"center", marginTop:3}}>{global.getText("moverSelectionPopup")[5]}</Text>

            <Text style={{fontWeight:"600", marginTop: 10}}>{global.getText("moverSelectionPopup")[6]}</Text>
            <Text style={{textAlign:"center", marginTop:3}}>{global.getText("moverSelectionPopup")[7]}</Text>

        </>, "left");
//         global.showPopup("Yardımcı Hizmetleri", `
// Sürücünün yardımı
// Bu hizmette gelen sürücü sizinle beraber ağır eşyalarınızı taşımanıza yardımcı olucaktır. Hafif ağırlıkta, koli, kutu, sandalye vb. eşyalarınızı taşıtmak için de tercih edebilirsiniz.
        
// Sürücü yardımı ve 1 ekstra yardımcı
// Sürücü ve yanında gelen bir yardımcı olacaktır. Komidin, kitaplık, sehpa, çamaşır makinesi, koltuk vb. eşyalarınızı taşıtmak için tercih edebilirsiniz.

// Sürücü yardımı ve 2 ekstra yardımcı
// Sürücü ve yanında gelen iki yardımcı olacaktır. Buzdolabı, yemek masası, gardolap, baza, yatak vb. eşyalarınızı taşıtmak için tercih edebilirsiniz.

// Sürücü yardımı ve 3 ekstra yardımcı
// Sürücü ve yanında gelen üç yardımcı olacaktır. Gardrop tipi buzdolabı, çift kişilik baza ve yatak, mobilya takımı vb. eşyalarınızı taşıtmak için tercih edebilirsiniz.
//         `, "left");
        //this.setState({driverNotificationPopup: true});
    }

    openOrder(_id) {

        API.fetch("getorder",{_id}).then((response) => {
            if(!response.error) {
                Amplitude.track("ORDER", {vehicle:this.order.vehicle});
                API.user.orders.push(response);
                global.checkSocketConnection();
                //console.log(response);
        
                
                global.hideLoadingPanel();
                this.navigation.dispatch(CommonActions.reset({
                    index:1,
                    routes: [{name: "Home"},{name: "OrderPending", params: {order: response}}]
                }));
            }
        })
        
    }

    render() {
        var basePrice = this.order.calculation.price;
        this.order.calculation.bridges.forEach((s) => basePrice -= s.price);
        basePrice -= this.order.calculation.waypointFee;

        var selectedPaymentCard = (typeof this.state.selectedPaymentMethod == 'string')?API.user.cards.find((s) => s._id == this.state.selectedPaymentMethod):undefined;

        {/* <OrderAdditional onPress={(v) => {this.state.additionalSelections[1] = v; this.forceUpdate()}} selected={this.state.additionalSelections[1]} price={this.additionalPrices[1]} title={global.getText("additionalPrices")[1]} icon={<FontAwesomeIcon name='luggage-cart'  size={20} style={{marginRight:5}}/>}/> */}
                {/* <OrderAdditional onPress={(v) => {this.state.additionalSelections[3] = v; this.forceUpdate()}} selected={this.state.additionalSelections[3]} price={this.additionalPrices[3]} title={global.getText("additionalPrices")[3]} icon={<MaterialCommunityIcon name='snowflake' size={30} />} /> */}
                {/* <OrderAdditional onPress={(v) => {this.state.additionalSelections[4] = v; this.forceUpdate()}} selected={this.state.additionalSelections[4] > 0} price={this.additionalPrices[4]} title={"Move door-to-door"} icon={<FontAwesomeIcon name='people-carry' size={20} style={{marginRight:5}} />} /> */}
        return <View style={{display:"flex", flex:1, backgroundColor:"white"}}>

            <View style={{paddingTop:Constants.statusBarHeight+15, paddingBottom:15, backgroundColor:"white", justifyContent:"center",flexDirection:"row",alignItems:"center"}}>
                <Text style={{fontWeight:"500",fontSize:18}}>{global.getText("orderDetails")}</Text>
                <TouchableOpacity onPress={() => this.navigation.pop()} style={{position:"absolute", left:"5%",marginLeft:-10,bottom:5}}><EvilIcon name='chevron-left' size={50}  /></TouchableOpacity>
            </View>
            
            <KeyboardAvoidingView behavior='padding' style={{display:"flex", flex:1, backgroundColor:"#F3F2F5"}}>
            <ScrollView >
                {this.order.vehicle != 0 && <><OrderAdditional onPress={(v) => {
                    this.state.additionalSelections[0] = v; 
                    if(!v && this.state.additionalSelections[1]) this.state.additionalSelections[1] = false;
                    
                    this.forceUpdate()
                    
                }} selected={this.state.additionalSelections[0]} price={this.additionalPrices[0]} title={global.getText("additionalPrices")[0]} icon={<MaterialCommunityIcon name='seatbelt' size={30} />} />
                {this.order.vehicle == 2 && <OrderAdditional onPress={(v) => {
                    
                    if(v) 
                    this.openPopup(global.getText("petTransportInfo"), () => {
                        this.state.additionalSelections[1] = true;
                        this.state.additionalSelections[0] = true;
                        this.forceUpdate();
                    });
                    else
                    {
                        this.state.additionalSelections[1] = v;
                        this.forceUpdate();
                    }
                    //this.state.additionalSelections[1] = v; this.forceUpdate()
                    
                    
                    
                    }} selected={this.state.additionalSelections[1]} price={API.additionalPrices[1]} title={global.getText("additionalPrices")[1]} icon={<MaterialCommunityIcon name='paw' size={30} />} />}
               
                {this.order.vehicle == 5 && <OrderAdditional onPress={(v) => {this.state.additionalSelections[2] = v; this.forceUpdate()}} selected={this.state.additionalSelections[2]} price={API.additionalPrices[2]} title={global.getText("additionalPrices")[2]} icon={<MaterialCommunityIcon name='van-utility' size={30} />} />}
                {/* <OrderAdditional onPress={(v) => {this.state.additionalSelections[3] = v; this.forceUpdate()}} selected={this.state.additionalSelections[3]} price={API.additionalPrices[3]} title={global.getText("additionalPrices")[3]} icon={<MaterialCommunityIcon name='human-greeting' size={30} />} /> */}
               
                {/* <View style={{backgroundColor:"white", paddingLeft:"4%", paddingRight:"4%", paddingTop:10, paddingBottom:10, display:"flex",flexDirection:"row", justifyContent:"space-between"}}>
            <View style={{display:"flex", flexDirection:"row", alignItems:"center",flex:1}}><FontAwesomeIcon name='people-carry' size={20} style={{marginRight:5}} />
            
            <View style={{marginLeft:10,flex:1, paddingRight:5}}>
            <Text style={{fontSize:16}}>{global.getText("moveDoorToDoor")}</Text>
            {this.state.additionalSelections[4] > 0 && <Text style={{marginTop:5}}>{global.getText("driverAsMover")} {this.state.additionalSelections[4] > 1?"+ "+(this.state.additionalSelections[4]-1)+" "+global.getText("helper"):""}</Text>}
            </View>
            </View>
            <View style={{display:"flex", flexDirection:"row", alignItems:"center"}}>
                <Text style={{color:(this.state.additionalSelections[4] > 0)?"black":"gray", fontWeight:"700", marginRight:10}}>{((API.additionalPrices[4][this.order.vehicle]*Math.max(this.state.additionalSelections[4]-1,0))+(Math.min(this.state.additionalSelections[4],1)*API.additionalPrices[3][this.order.vehicle]))}₺</Text>

                <View style={{display:"flex", flexDirection:"row", backgroundColor:(this.state.additionalSelections[4]>0)?Colors.LightBlue:"#F3F2F5", paddingTop:3, paddingBottom:3, paddingLeft:10, paddingRight:10, borderRadius:100, alignItems:"center"}}>
                    <TouchableOpacity hitSlop={{left:5,top:5,bottom:5,right:5}} onPress={() => {
                        if(this.state.additionalSelections[4] > 0) {
                            this.state.additionalSelections[4]--;
                            this.forceUpdate();
                        }
                    }}><AntIcon style={{fontSize:16, marginRight:7}}color={(this.state.additionalSelections[4] > 0)?"white":"black"} name='minus' /></TouchableOpacity>
                    <Text style={{fontWeight:"600",color:(this.state.additionalSelections[4] > 0)?"white":"black", fontSize:17}}>{this.state.additionalSelections[4]}</Text>
                    <TouchableOpacity hitSlop={{left:5,top:5,bottom:5,right:5}}  onPress={() => {
                        if(this.state.additionalSelections[4] < 6) {
                            this.state.additionalSelections[4]++;
                            this.forceUpdate();
                        }
                    }}><AntIcon color={(this.state.additionalSelections[4] > 0)?"white":"black"} style={{fontSize:16, marginLeft:7}} name='plus' /></TouchableOpacity>
                </View>
            </View>

            
        </View> */}
        
        
            <Text style={{color:"gray", fontWeight:"bold", marginLeft:"3%", marginTop:25}}>{global.getText("moverServices")}</Text>
            <View style={{marginTop:5}}>
                <HelperOption error={this.state.helperServiceError} icon={<IonIcon name='circle' />} selected={this.state.additionalSelections[4] == 0 && this.state.helperServiceSelected} onPress={() => {
                    this.state.additionalSelections[4] = 0;
                    this.state.helperServiceSelected = true;
                    this.state.helperServiceError = false;
                    this.forceUpdate();
                }} price={0} title={global.getText("handleCarrying")} />
                <HelperOption error={this.state.helperServiceError} icon={<IonIcon name='circle' />} selected={this.state.additionalSelections[4] == 1} onPress={() => {
                    this.state.additionalSelections[4] = 1;
                    this.state.helperServiceSelected = true;
                    this.state.helperServiceError = false;
                    this.forceUpdate();

                    if(!this.state.driverNotificationPopup) this.showMoverInfo();
                }} price={API.additionalPrices[3][this.order.vehicle]} title={global.getText("handleHelper")} />
                <HelperOption error={this.state.helperServiceError} icon={<IonIcon name='circle' />} selected={this.state.additionalSelections[4] == 2} onPress={() => {
                    this.state.additionalSelections[4] = 2;
                    this.state.helperServiceSelected = true;
                    this.state.helperServiceError = false;
                    this.forceUpdate();

                    if(!this.state.driverNotificationPopup) this.showMoverInfo();
                }} price={API.additionalPrices[3][this.order.vehicle]+API.additionalPrices[4][this.order.vehicle]} title={global.getText("handleHelper1")} />
                <HelperOption error={this.state.helperServiceError} icon={<IonIcon name='circle' />} selected={this.state.additionalSelections[4] == 3} onPress={() => {
                    this.state.additionalSelections[4] = 3;
                    this.state.helperServiceSelected = true;
                    this.state.helperServiceError = false;
                    this.forceUpdate();

                    if(!this.state.driverNotificationPopup) this.showMoverInfo();
                }} price={API.additionalPrices[3][this.order.vehicle]+(2*API.additionalPrices[4][this.order.vehicle])} title={global.getText("handleHelper2")} />
                {this.order.vehicle > 2 && <HelperOption error={this.state.helperServiceError} icon={<IonIcon name='circle' />} selected={this.state.additionalSelections[4] == 4} onPress={() => {
                    this.state.additionalSelections[4] = 4;
                    this.state.helperServiceSelected = true;
                    this.state.helperServiceError = false;
                    this.forceUpdate();

                    if(!this.state.driverNotificationPopup) this.showMoverInfo();
                }} price={API.additionalPrices[3][this.order.vehicle]+(3*API.additionalPrices[4][this.order.vehicle])} title={global.getText("handleHelper3")} />}

            </View>

            {this.state.helperServiceError && <Text style={{color: "crimson", fontWeight:"bold", marginLeft:"4%",marginTop:10}}>{global.getText("selectHandling")}</Text>}
        </>}


        <Text style={{color:"gray", fontWeight:"bold", marginLeft:"3%", marginTop:25}}>Ödeme yöntemi</Text>
                <View style={{paddingLeft:"5%", paddingRight:"5%", display:"flex", backgroundColor:"white", marginTop:5,paddingTop:10,paddingBottom:10}}>

<View style={{flexDirection:"row",alignItems:"center", justifyContent:"space-between"}}>
                <View style={{flexDirection:"row", alignItems:"center", gap: 10}}>
                {typeof this.state.selectedPaymentMethod == 'number' && <IonIcon name={typeof this.state.selectedPaymentMethod == 'number' && this.state.selectedPaymentMethod == 1?'cash-outline':'card-outline'} size={25}/>}
                    
                {typeof this.state.selectedPaymentMethod == 'number' && <Text style={{fontWeight:"600"}}>{global.getText("paymentMethods")[(this.state.selectedPaymentMethod)]}</Text>}
                        {selectedPaymentCard != undefined && <View style={{flexDirection:"row",marginTop:2, alignItems:"center"}}>
                            <Image source={cardIcons[selectedPaymentCard.type]} style={{height:24,width:32, marginRight:10}} resizeMode='contain' />
                            <Text>{(selectedPaymentCard.name != "")?selectedPaymentCard.name:selectedPaymentCard.number}</Text>
                        </View>}

                        </View>

                        {Platform.OS != 'web' &&  <TouchableOpacity onPress={() => this.setState({paymentMethodOpened: true})} style={{padding:6, paddingHorizontal:10, borderRadius:5, borderWidth:1, borderColor:Colors.Blue}}>
                            <Text style={{textAlign:"center", color: Colors.Blue, fontSize:15, fontWeight:"600"}}>{global.getText("changePaymentMethod")}</Text>
                        </TouchableOpacity>}
                </View>

                
                {typeof this.state.selectedPaymentMethod == 'number' && <Text style={{color:"gray", fontWeight:"500", fontSize:13, marginTop:5}}>{global.getText("paymentDesc")[this.state.selectedPaymentMethod]}</Text>}
                </View>
                
                {typeof this.state.selectedPaymentMethod == 'string' && <View style={{marginTop:20}}>
                    <Text style={{fontWeight:"600",fontSize:15, marginLeft:"5%",marginBottom:10}}>{global.getText("installmentPlans")}</Text>
                    {this.state.installmentRates.map((x, i) => <TouchableOpacity onPress={() => {
                        this.setState({installmentSelected: i});
                    }} style={{backgroundColor:"white",paddingTop:15, paddingBottom:15,paddingLeft:"5%", paddingRight:"5%", flexDirection:"row", justifyContent:"space-between"}} key={i}>
                        <Text style={{fontSize:16}}>{(i == 0?"":i+1)+global.getText("installments")[i == 0?0:1]}</Text>
                        <View style={{alignItems:"center", flexDirection:"row"}}>
                            <Text style={{fontWeight:"600",fontSize:11,color:"gray"}}>{i+1}x </Text>
                            <Text style={{fontWeight:"600",fontSize:16}}>{((this.calculateEstimate(true)*(1+(x/100)))/(i+1)).toFixed(2)}₺</Text>
                            {this.state.installmentSelected == i && <AntIcon name='checkcircle' color={Colors.Blue} size={14} style={{marginLeft:10}} />}
                        </View>
                    </TouchableOpacity>)}
                </View>}

                
                <View style={{paddingLeft:"5%", paddingRight:"5%", display:"flex", flexDirection:"row", backgroundColor:"white", alignItems:"center", marginTop:20, paddingTop:10, paddingBottom:10}}>
                    
                    <FontAwesomeBasicIcon name='edit' size={30} style={{marginRight:7}} />

                    <TextInput placeholderTextColor={"gray"} placeholder={global.getText("noteToDriver")} maxLength={100} value={this.state.note} onChangeText={(t) => this.setState({note: t})} style={{fontSize:16, flex:1, height:40}} />
                    
                </View>

                <Text style={{marginLeft:"5%", marginRight:"5%", marginTop:10,marginBottom:20, fontWeight:"700", color:"gray",fontSize:12}}>{global.getText("driverAlt")}</Text>


                
            </ScrollView>
            </KeyboardAvoidingView>

            <View style={{backgroundColor:"white", paddingBottom:30, paddingLeft:"5%", paddingRight:"5%", paddingTop:10}}>

                <View style={{display:"flex", flexDirection:"row", justifyContent:"space-between", alignItems:"center"}}>
                    <Text></Text>
                    {/* <TouchableOpacity onPress={() => {
                        //if(API.user.business) return this.paymentModal.open();
                        this.setState({paymentMethodOpened: true})
                        
                        }} style={{flexDirection:"row",maxWidth:"45%"}}>

                        <View style={{}}>
                        <View style={{flexDirection:"row"}}><Text style={{fontWeight:"600", fontSize:16}}>{global.getText("payment")}</Text><IonIcon size={17} style={{marginLeft:5}} name='chevron-down' /></View>
                        
                        {typeof this.state.selectedPaymentMethod == 'number' && <Text style={{fontWeight:"600"}}>{global.getText("paymentMethods")[(this.state.selectedPaymentMethod)]}</Text>}
                        {selectedPaymentCard != undefined && <View style={{flexDirection:"row",marginTop:2, alignItems:"center"}}>
                            <Image source={cardIcons[selectedPaymentCard.type]} style={{height:24,width:32}} resizeMode='contain' />
                            <Text>{(selectedPaymentCard.name != "")?selectedPaymentCard.name:selectedPaymentCard.number}</Text>
                        </View>}
                        </View>
                        
                    </TouchableOpacity> */}
                <View>
                        
                        <View style={{flexDirection:"row",alignItems:"center",justifyContent:"flex-end"}}>{this.order.calculation.traffic && <DemandIcon />}<Text style={{textAlign:"right", fontWeight:"600", fontSize:20}}>{this.calculateEstimate().toFixed(2)}₺</Text></View><Text style={{fontWeight:"500",textAlign:"right"}}>{global.getText("estimatedTotal")}</Text></View>
                </View>
            <TouchableOpacity onPress={() => {

                if(!this.state.helperServiceSelected && this.order.vehicle != 0) return this.setState({helperServiceError: true});


                Amplitude.track("REVIEW_ORDER");
                this.reviewOrderModal.show();
        //   var foundWaypoint = false;

        //   this.state.waypoints.forEach((s) => {
        //     if(!s) foundWaypoint = true;
        //   })

        //   if(!foundWaypoint) this.navigation.navigate('Route', {waypoints:this.state.waypoints});

        }} style={{backgroundColor:Colors.Blue,marginTop:10, paddingBottom:12, paddingTop:12, display:"flex", justifyContent:"center", alignItems:"center", borderRadius:30}}><Text style={{color:"white", fontWeight:"700", fontSize:17}}>{global.getText("reviewOrder")}</Text></TouchableOpacity>
        </View>

        {/* <View style={{position:"absolute", backgroundColor:"rgba(0,0,0,.2)", left:0, right:0, top:0, bottom:0}}>
        <BottomSheet enablePanDownToClose={true} enableOverDrag={true} enableContentPanningGesture={true} enableHandlePanningGesture={true} snapPoints={["90%"]}>
            <View style={{flex:1, backgroundColor:"#F4F3F6"}}>

                <View style={{display:"flex", flexDirection:"row", justifyContent:"space-between", alignItems:"center", backgroundColor:"white", paddingLeft:"3%", paddingRight:"3%"}}>
                    <View>
                        <Text style={{fontWeight:"600", fontSize:20}}>Motorcycle</Text>
                        <Text style={{fontSize:16}}>Mon 14 Mar, 08:20</Text>
                    </View>

                    

                    <Image source={require('./assets/Motorcycle.png')} />
                </View>

                <View style={{paddingLeft:"3%", paddingRight:"3%", backgroundColor:"white"}}>
                        <OrderReviewDestination first />
                        <OrderReviewDestination />
                </View>


                <View style={{marginTop:10, paddingLeft:"3%", paddingRight:"3%", backgroundColor:"white", paddingTop:10, paddingBottom:10}}>
                    <View style={{display:"flex", justifyContent:"space-between", alignContent:"center", flexDirection:"row"}}><Text>Base fee</Text><Text style={{fontWeight:"600"}}>11$</Text></View>
                    <View style={{display:"flex", justifyContent:"space-between", alignContent:"center", flexDirection:"row", marginTop:15}}><Text>Promo code</Text><Text style={{fontWeight:"600", color:Colors.Blue}}>Add</Text></View>
                    <View style={{display:"flex", justifyContent:"space-between", alignContent:"center", flexDirection:"row", marginTop:15}}><Text>Service time (1hr)</Text><Text style={{fontWeight:"600"}}>$0</Text></View>

                    <View style={{backgroundColor:"#F4F3F6", height:1.5, width:"100%", marginTop:15}}></View>
                    <View style={{display:"flex", justifyContent:"space-between", alignContent:"center", flexDirection:"row", marginTop:15}}><Text style={{fontWeight:"600"}}>Estimated total</Text><Text style={{fontWeight:"600"}}>11$</Text></View>
                    <View style={{display:"flex", justifyContent:"space-between", alignContent:"center", flexDirection:"row", marginTop:15}}><Text style={{fontWeight:"500", color:"gray", fontSize:12}}>The total fee is subject to change if extra services are used.</Text></View>
                    

                </View>

                <Text style={{paddingLeft:"3%", paddingRight:"3%", marginTop:15, fontSize:11}}>By placing an order via GOGOX, you hereby confirm that you have possession of goods to be transported.</Text>

                <Text style={{paddingLeft:"3%", paddingRight:"3%", marginTop:15, fontSize:16, color:Colors.Blue}}>GOGOX pricing guide</Text>

                
        <TouchableOpacity onPress={() => {
          
          var foundWaypoint = false;

          this.state.waypoints.forEach((s) => {
            if(!s) foundWaypoint = true;
          })

          if(!foundWaypoint) this.navigation.navigate('Route', {waypoints:this.state.waypoints});

        }} style={{backgroundColor:Colors.Blue, position:"absolute", bottom:30, left:"5%", right:"5%", paddingBottom:12, paddingTop:12, display:"flex", justifyContent:"center", alignItems:"center", borderRadius:30}}><Text style={{color:"white", fontWeight:"700", fontSize:17}}>Place order</Text></TouchableOpacity>

            </View>
        </BottomSheet>
        </View> */}

<BottomSheet sheetBackgroundColor={"white"} height={Dimensions.get('window').height-100} ref={(re) => this.reviewOrderModal = re} hasDraggableIcon >

            
            <View style={{flex:1, backgroundColor:"#F4F3F6"}}>
            
                <View style={{display:"flex", flexDirection:"row", justifyContent:"space-between", alignItems:"center", backgroundColor:"white", paddingLeft:"3%", paddingRight:"3%"}}>
                    <View>
                        <Text style={{fontWeight:"600", fontSize:20}}>{Utils.getVehicleName(this.order.vehicle)}</Text>
                        <Text style={{fontSize:16}}>{this.getDateString()}</Text>
                    </View>

                    

                    <Image style={{height:77,width:100}} resizeMode='contain' source={vehicleImgs[this.order.vehicle]} />
                </View>

                <View style={{paddingLeft:"3%", paddingRight:"3%", backgroundColor:"white"}}>

                        {this.order.waypoints.map((waypoint, i) => <OrderReviewDestination key={i} first={i == 0} selected={waypoint}/>)}
                        
                </View>


                <View style={{marginTop:10, paddingLeft:"3%", paddingRight:"3%", backgroundColor:"white", paddingTop:10, paddingBottom:10}}>
                    <View style={{display:"flex", justifyContent:"space-between", alignContent:"center", flexDirection:"row"}}><Text>{global.getText("baseFee")}</Text><Text style={{fontWeight:"600"}}>{basePrice.toFixed(2)}₺</Text></View>
                    <View style={{display:"flex", justifyContent:"space-between", alignContent:"center", flexDirection:"row", marginTop:15}}><Text>{global.getText("promoCode")}</Text><TouchableOpacity hitSlop={{left:10,right:10,top:10, bottom:10}} style={{flexDirection:"row"}} onPress={() => {
                        
                        if(this.state.coupon) {
                        this.setState({coupon: undefined})
                        } else {
                        this.reviewOrderModal.close();
                        this.promotionsModal.open()
                        }
                        
                    }}><Text style={{fontWeight:"600", color:Colors.Blue}}>{global.getText(this.state.coupon?"remove":"promoCodeAdd")}</Text>{this.state.coupon != undefined && <Text style={{fontWeight:"600"}}>  -{this.calculateCouponDiscount().toFixed(2)}₺</Text>}</TouchableOpacity></View>
                    {this.order.waypoints.length > 2 && <View style={{display:"flex", justifyContent:"space-between", alignContent:"center", flexDirection:"row", marginTop:15}}><Text>{global.getText("waypointFee")} x{this.order.waypoints.length-2}</Text><Text style={{fontWeight:"600"}}>{this.order.calculation.waypointFee}₺</Text></View>}

                    {this.order.calculation && this.order.calculation.bridges.map((x,i) => <View key={i} style={{display:"flex", justifyContent:"space-between", alignContent:"center", flexDirection:"row", marginTop:15}}><Text>{global.getText("bridgePricings")[x.id]}</Text><Text style={{fontWeight:"600"}}>{x.price}₺</Text></View>)}
                    

                    
                    <View style={{display:"flex", justifyContent:"space-between", alignContent:"center", flexDirection:"row", marginTop:15}}><Text>{global.getText("serviceTime")} ({this.getTimeDisplay()})</Text><Text style={{fontWeight:"600"}}>0₺</Text></View>
                    <Text style={{fontWeight:"600", color:"crimson", fontSize:11, marginTop:5, width:Dimensions.get("screen").width*.8}}>{global.getText("serviceUsage")}</Text>
                    <View style={{backgroundColor:"#F4F3F6", height:1.5, width:"100%", marginTop:15}}></View>
                    
                    {this.getExtras().map((x,i) => 
                        <View key={i} style={{display:"flex", justifyContent:"space-between", alignContent:"center", flexDirection:"row", marginTop:15}}><Text>{x.t}</Text><Text style={{fontWeight:"600"}}>{x.p}₺</Text></View>
                    )}

                    {this.getExtras().length > 0 && <View style={{backgroundColor:"#F4F3F6", height:1.5, width:"100%", marginTop:15}}></View>}
                
                    {this.state.installmentSelected > 0 && <View style={{display:"flex", justifyContent:"space-between", alignContent:"center", flexDirection:"row", marginTop:15}}><Text>{global.getText("installmentFee")}</Text><View style={{alignItems:"center",flexDirection:"row"}}><Text style={{fontWeight:"600"}}>{(this.calculateEstimate(true)*(this.state.installmentRates[this.state.installmentSelected]/100)).toFixed(2)}₺</Text></View></View>}
                    {this.state.installmentSelected > 0 && <View style={{backgroundColor:"#F4F3F6", height:1.5, width:"100%", marginTop:15}}></View>}
                    
                    <View style={{display:"flex", justifyContent:"space-between", alignContent:"center", flexDirection:"row", marginTop:15}}><Text style={{fontWeight:"600"}}>{global.getText("estimatedTotal")}</Text><View style={{alignItems:"center",flexDirection:"row"}}>{this.order.calculation.traffic && <DemandIcon size={4} />}<Text style={{fontWeight:"600"}}>{this.calculateEstimate().toFixed(2)}₺</Text></View></View>
                    
                    
                    
                    {this.order.calculation.traffic && <Text style={{textAlign:"right",fontSize:11, color:"#ff0080"}}>{global.getText("highTraffic")}</Text>}
                    {/* <View style={{display:"flex", justifyContent:"space-between", alignContent:"center", flexDirection:"row", marginTop:15}}><Text style={{fontWeight:"500", color:"gray", fontSize:12}}>{global.getText("feeInfo")}</Text></View> */}
                    
                
                </View>



                {/* <Text style={{paddingLeft:"3%", paddingRight:"3%", marginTop:15, fontSize:11}}>{global.getText("reviewOrderTOS")}</Text> */}

                {/* <Text style={{paddingLeft:"3%", paddingRight:"3%", marginTop:15, fontSize:16, color:Colors.Blue}}>{global.getText("pricingGuide")}</Text> */}

                
        <TouchableOpacity onPress={async () => {

if(this.state.selectedPaymentMethod == 0) {
    this.setState({onlinePaymentCardOwner: "", onlinePaymentSaveCard: false});
    setTimeout(() => {
        this.lastCheckedNumber = "";
        this.setState({installmentRates: [0], installmentSelected:0})
        this.onlinePaymentModal.open();
        
    }, 500);
    this.reviewOrderModal.close();
    return;
}

            
          this.reviewOrderModal.close();

          if(this.state.selectedPaymentMethod == 1 || this.state.selectedPaymentMethod == 3 || this.state.selectedPaymentMethod == 4) {
            this.reviewOrderModal.close();
            this.createOrder();

            return;
          }
        //   var order ={...this.order};
        //   order.date = order.date.getTime();
        //   order.price = this.calculateEstimate();
        //   order.note = this.state.note;

          setTimeout(() => {
            this.savedCardCVCModal.open();
            setTimeout(() => {
                this.savedCardCVCInput.focusField(0);
            }, 500);
          }, 500);
          
          

        //   for(var i =0; i < order.waypoints.length; i++) {
        //       var waypoint = {...order.waypoints[i]};
        //       //waypoint.region = {latitude: waypoint.region.latitude, longitude: waypoint.region.longitude};
              
        //         waypoint.region = [waypoint.region.longitude,waypoint.region.latitude];
        //       order.waypoints[i] = waypoint;
        //   }

        //   order.additionals = this.state.additionalSelections;
        
         

          
          //this.createOrder();

        

        }} style={{backgroundColor:Colors.Blue, position:"absolute", bottom:30, left:"5%", right:"5%", paddingBottom:12, paddingTop:12, display:"flex", justifyContent:"center", alignItems:"center", borderRadius:30}}><Text style={{color:"white", fontWeight:"700", fontSize:17}}>{global.getText("placeOrder")}</Text></TouchableOpacity>

            </View>

            
        </BottomSheet>

        {/* {this.state.orderInProcess && <View style={{backgroundColor:"rgba(0,0,0,.4)", position:"absolute", top:0, bottom:0, left:0, right:0, display:"flex", justifyContent:"center", alignItems:"center"}}>
            <ActivityIndicator />
        </View>} */}

        <PromotionsModal onCouponUsed={(coupon) => {

            if(this.state.selectedPaymentMethod == 1) {
                return global.showError(92);
            }
            var valid = API.checkCoupon(coupon, this.order);

            if(!valid) {
                global.showError(91)
            } else {
                this.promotionsModal.close();
                this.reviewOrderModal.show();
                this.setState({coupon});
            }
            

            
            
        }} ref={a => this.promotionsModal = a} />

        <Modal animationType='slide' visible={this.state.paymentMethodOpened}>
            <View style={{backgroundColor:"#F2F4F3", flex:1}}>
            <View style={{height:90, backgroundColor:"white", width:"100%", justifyContent:"flex-end", display:"flex" ,alignItems:"center"}}>
                <Text style={{fontWeight:"700", bottom:14, fontSize:17, position:"absolute"}}>{global.getText("paymentMethod")}</Text>
                <TouchableOpacity onPress={() => this.setState({paymentMethodOpened: false})} style={{position:"absolute", bottom:10, left:"5%"}}>
                <IonIcon name='close' size={30} />
                </TouchableOpacity>
            </View>

            <ScrollView style={{flex:1}}>
                <View style={{backgroundColor:"white", paddingLeft:"5%", paddingRight:"5%", marginTop:10}}>

                    
                    {/* <PaymentMethod icon={<IonIcon name='cash-outline' size={25}/>} /> */}
                    <PaymentMethod onPress={() => {
                        this.state.selectedPaymentMethod = 0;
                        AsyncStorage.setItem("paymentMethod", "0");
                        

                        this.setState({paymentMethodOpened: false,installmentSelected: 0});
                    }} title={global.getText("paymentMethods")[0]} selected={this.state.selectedPaymentMethod == 0} icon={<IonIcon name='card-outline' size={25}/>} />
                    {API.user.business != undefined && <PaymentMethod onPress={() => {
                        this.state.selectedPaymentMethod = 3;
                        AsyncStorage.setItem("paymentMethod", "3");
                        

                        this.setState({paymentMethodOpened: false,installmentSelected: 0});
                    }} title={global.getText("paymentMethods")[3]} selected={this.state.selectedPaymentMethod == 3} icon={<IonIcon name='wallet-outline' size={25}/>} />}
                    <PaymentMethod onPress={() => {
                        this.state.selectedPaymentMethod = 1;
                        AsyncStorage.setItem("paymentMethod", "1");
                        this.setState({paymentMethodOpened: false,installmentSelected: 0, coupon: undefined});
                    }}  title={global.getText("paymentMethods")[1]} selected={this.state.selectedPaymentMethod == 1} icon={<IonIcon name='cash-outline' size={25}/>} />
                    {API.user.cards.map((x, i) => <SavedCard onPress={() => {
                        
                        this.state.selectedPaymentMethod = x._id;
                        AsyncStorage.setItem("paymentMethod", x._id);
                        this.setState({paymentMethodOpened: false,installmentSelected: 0});
                        
                        global.showLoadingPanel();
                        API.fetch('installments', {number: x.number,price: this.calculateEstimate(true)}).then((val) => {
                            
                            if(!val.error) {
                                this.setState({installmentRates: val});
                                global.hideLoadingPanel();
                            }
                        })
                    }} margin={8} card={x} key={i} />)}
                    {/* {this.state.savedCards.map((x, i) => <PaymentMethod key={i} title={x.type +" " + x.number} icon={<IonIcon name='card-outline' size={25}/>} selected />)} */}
                    <View  style={{flexDirection:"row", paddingTop:10, paddingBottom:10, alignItems:"center", justifyContent:"space-between"}}>
                        <TouchableOpacity onPress={() => {
                            this.addCreditCardModal.open();
                        }} style={{flexDirection:"row", alignItems:"center"}}>
                            <IonIcon color={Colors.Blue}  name='add' size={25}/>
                            <View style={{marginLeft:15}}>
                            <Text style={{fontSize:17, color:Colors.Blue, fontWeight:"600"}}>{global.getText("addCreditCard")}</Text>
                            <Text style={{fontSize:12}}>{global.getText("recommendedPaymentMethod")}</Text>
                            </View>
                        </TouchableOpacity>

                        
                        
                    </View>

                    
                </View>
                <View pointerEvents='none' style={{marginLeft:"5%", marginTop:"5%", }}><ParamLogo /></View>
            </ScrollView>
            </View>
            <AddCardModal onAdd={() => {
                this.state.selectedPaymentMethod = API.user.cards[API.user.cards.length-1]._id;
                setTimeout(() => {
                    this.setState({paymentMethodOpened: false});
                }, 100);
               
            }} ref={(a) => this.addCreditCardModal = a} />
            {/* <Modal animationType='slide' visible={this.state.creditCardModalVisible}>

                <View style={{backgroundColor:"#F2F4F3", flex:1}}>
                    <View style={{height:90, marginBottom:20, backgroundColor:"white", width:"100%", justifyContent:"flex-end", display:"flex" ,alignItems:"center"}}>
                        <Text style={{fontWeight:"700", bottom:14, fontSize:17, position:"absolute"}}>{global.getText("addCreditCard")}</Text>
                        <TouchableOpacity onPress={() => this.setState({creditCardModalVisible: false})} style={{position:"absolute", bottom:10, left:"5%"}}>
                        <IonIcon name='close' size={30} />
                        </TouchableOpacity>

                        <TouchableOpacity onPress={() => {
                            
                            if(!this.newCardDetails.valid) return;

                            
                            this.state.savedCards.push({type: capitalizeFirstLetter(this.newCardDetails.values.type), number: this.newCardDetails.values.number.split(" ")[this.newCardDetails.values.number.split(" ").length-1]});

                            this.setState({creditCardModalVisible: false})

                            setTimeout(() => {
                                this.setState({paymentMethodOpened: false});
                            }, 20);
                            
                            }} style={{position:"absolute", bottom:15, right:"5%"}}>
                            <Text style={{fontSize:16, fontWeight:"600"}}>Kaydet</Text>

                        </TouchableOpacity>
                    </View>
                    <View style={{paddingLeft:"3%", paddingRight:"3%"}}>

                        <FontistoIcon  name='credit-card' size={Dimensions.get('window').width/3} style={{textAlign:"center", marginBottom:20}} color={Colors.LightBlue}/>
                    <View style={{borderWidth:1,borderRadius:5, borderColor:"#BCBBBF"}}>
                    <LiteCreditCardInput autoFocus={true} onChange={(a) => this.newCardDetails = a} cardFontFamily={''} />
                    </View>
                    </View>
                </View>
                
            </Modal> */}
        </Modal>


        {this.state.popupText != "" && <Animated.View style={{position:"absolute", left:0, right:0, top:0, bottom:0, backgroundColor:"rgba(0,0,0,.4)",opacity: this.popupAnimation, justifyContent:"center", alignItems:"center",zIndex:1000}}>
                <View style={{width:"80%", backgroundColor:"white", shadowColor: "#000",
shadowOffset: {
	width: 0,
	height: 2,
},
shadowOpacity: 0.25,
shadowRadius: 3.84,

elevation: 5, padding:20, alignItems:"center",borderRadius:10}}>

        <TouchableOpacity onPress={() => {
            Animated.timing(this.popupAnimation, {useNativeDriver:true, toValue:0, duration:100}).start();
            setTimeout(() => {
                this.setState({popupText:""});
            }, 100);
        }} style={{alignSelf:"flex-end",marginTop:-10, marginRight:-10}}><IonIcon name='close' size={23} /></TouchableOpacity>
    
    <Text style={{textAlign:"center",fontWeight:"600", marginTop:5}}>{this.state.popupText}</Text>

    <TouchableOpacity onPress={() => {
        this.popupSuccessCallback();
        Animated.timing(this.popupAnimation, {useNativeDriver:true, toValue:0, duration:100}).start();
        setTimeout(() => {
            this.setState({popupText:""});
        }, 100);
    }} style={{backgroundColor:Colors.Blue, paddingTop:10, paddingBottom:10, marginTop:20,width:"100%", justifyContent:"center", alignItems:"center",borderRadius:50}}><Text style={{color:"white", fontSize:18, fontWeight:"600"}}>{global.getText("gotIt")}</Text></TouchableOpacity>
</View>
        </Animated.View>}


        <CustomModal headerShown={false} ref={(a) => this.onlinePaymentModal = a} scrollView={false} animationType='slide'>
        <TouchableWithoutFeedback onPress={() => Keyboard.dismiss()}>
        <View style={{backgroundColor:"#F2F4F3", flex:1}}>
            <View style={{height:90, marginBottom:20, backgroundColor:"white", width:"100%", justifyContent:"flex-end", display:"flex" ,alignItems:"center"}}>
                <Text style={{fontWeight:"700", bottom:14, fontSize:17, position:"absolute"}}>{global.getText("onlinePayment")}</Text>
                <TouchableOpacity onPress={() => {
                    this.setState({installmentRates: [0], installmentSelected: 0});
                    this.onlinePaymentModal.close()
                    
                }} style={{position:"absolute", bottom:10, left:"5%"}}>
                <IonIcon name='close' size={30} />
                </TouchableOpacity>

                <TouchableOpacity onPress={() => {
                    
                    if(!this.onlinePaymentCardDetails.valid || this.state.onlinePaymentCardOwner.trim().length <= 1) return;

                    
                    this.createOrder({number: this.onlinePaymentCardDetails.values.number, expiry: this.onlinePaymentCardDetails.values.expiry, cvc: this.onlinePaymentCardDetails.values.cvc, owner: this.state.onlinePaymentCardOwner});
                    this.onlinePaymentModal.close();
                    this.setState({installmentRates: [0], installmentSelected: 0})
                    // this.state.savedCards.push({type: capitalizeFirstLetter(this.newCardDetails.values.type), number: this.newCardDetails.values.number.split(" ")[this.newCardDetails.values.number.split(" ").length-1]});

                    // this.setState({creditCardModalVisible: false})

                    // setTimeout(() => {
                    //     this.setState({paymentMethodOpened: false});
                    // }, 20);
                    
                    }} style={{position:"absolute", bottom:15, right:"5%"}}>
                    <Text style={{fontSize:16, fontWeight:"600"}}>{global.getText("pay")}</Text>

                </TouchableOpacity>
            </View>
            <ScrollView>
            <View style={{paddingLeft:"3%", paddingRight:"3%"}}>

                <FontistoIcon  name='credit-card' size={Dimensions.get('window').width/3} style={{textAlign:"center", marginBottom:20}} color={Colors.LightBlue}/>
                

                
                <View style={{borderWidth:1,borderRadius:5, borderColor:"#BCBBBF",backgroundColor:"white", marginTop:10}}>
            <TextInput autoCapitalize='words' ref={(s) => this.creditCardOwnerTextInput = s} value={this.state.onlinePaymentCardOwner} autoFocus={true} onChangeText={(e) => this.setState({onlinePaymentCardOwner: e})} returnKeyType='next' onSubmitEditing={() => {this.liteCreditCardInput.focus()}} placeholder={global.getText("cardOwner")} placeholderTextColor='gray' style={{paddingLeft:10,paddingRight:10, justifyContent:"center",height:40}} />
            </View>
            <View style={{borderWidth:1,borderRadius:5, borderColor:"#BCBBBF",backgroundColor:"white",marginTop:10}}>
            <LiteCreditCardInput ref={(a) => this.liteCreditCardInput = a} onChange={(a) => {
              this.onlinePaymentCardDetails = a

              var cardNumber = a.values.number.replace(/ /g, "");
              if(cardNumber.length >= 6) {
                var testNumber = cardNumber.substring(0,6);
                if(testNumber != this.lastCheckedNumber) {
                    this.lastCheckedNumber = testNumber;
                    API.fetch("installments", {number: this.lastCheckedNumber, price: this.calculateEstimate(true)}).then((rates) => {
                        if(this.lastCheckedNumber != testNumber) return;

                        this.setState({installmentRates: rates});
                    })
                }
              }
              
            }} cardFontFamily={''} />
            </View>
            
            <CheckBox onPress={() => this.setState({onlinePaymentSaveCard: !this.state.onlinePaymentSaveCard})} checked={this.state.onlinePaymentSaveCard} checkedColor={Colors.Blue} title={global.getText("saveCardForLater")} containerStyle={{margin:0, backgroundColor:"transparent", padding:0, borderWidth:0, marginTop:20}} />
            {this.state.onlinePaymentSaveCard && <View style={{borderWidth:1,borderRadius:5,marginTop:10, borderColor:"#BCBBBF",backgroundColor:"white"}}>
            <TextInput autoCapitalize='words' value={this.state.creditCardName} onChangeText={(e) => this.setState({creditCardName: e})} returnKeyType='next' onSubmitEditing={() => {this.creditCardOwnerTextInput.focus()}}  placeholder={global.getText("saveCardName")} placeholderTextColor='gray' style={{paddingLeft:10,paddingRight:10, justifyContent:"center",height:40}} />
            </View>}
            
            </View>

            {this.state.installmentRates.length > 1 && <View style={{marginTop:20}}>
                    <Text style={{fontWeight:"600",fontSize:15, marginLeft:"5%",marginBottom:10}}>{global.getText("installmentPlans")}</Text>
                    {this.state.installmentRates.map((x, i) => <TouchableOpacity onPress={() => {
                        this.setState({installmentSelected: i});
                    }} style={{backgroundColor:"white",paddingTop:15, paddingBottom:15,paddingLeft:"5%", paddingRight:"5%", flexDirection:"row", justifyContent:"space-between"}} key={i}>
                        <Text style={{fontSize:16}}>{(i == 0?"":i+1)+global.getText("installments")[i == 0?0:1]}</Text>
                        <View style={{alignItems:"center", flexDirection:"row"}}>
                            <Text style={{fontWeight:"600",fontSize:16}}>{(this.calculateEstimate(true)*(1+(x/100))).toFixed(2)}₺</Text>
                            <Text style={{fontWeight:"600",fontSize:11,color:"gray",marginLeft:5}}>{i+1}x{((this.calculateEstimate(true)*(1+(x/100)))/(i+1)).toFixed(2)}₺</Text>
                            
                            {this.state.installmentSelected == i && <AntIcon name='checkcircle' color={Colors.Blue} size={14} style={{marginLeft:10}} />}
                        </View>
                    </TouchableOpacity>)}
                </View>}
            </ScrollView>
        </View>
        </TouchableWithoutFeedback>
    </CustomModal>


    <CustomModal closeShown={false} scrollView={false} title={"3D Secure"} ref={(a) => {
              
              this.secureCustomModal = a;
            }}>

              <WebView javaScriptEnabled={true} onMessage={(a) => {
                var json = JSON.parse(a.nativeEvent.data);
                
                if(!json || !json._id) {
                    global.showError(global.getText("errors")["2000"]+", "+json.str);
                } else {
                    this.openOrder(json._id);
                    
                }

                this.secureCustomModal.close();
                
              }} automaticallyAdjustContentInsets={true}  source={{uri: this.state.webViewSource}} />
            </CustomModal>





            <RBSheet ref={(a) => {
                this.savedCardCVCModal = a
                }} height={200} customStyles={{container: {borderTopLeftRadius: 5, borderTopRightRadius:5}}} closeOnDragDown={false} closeOnPressMask={true} snapPoints={["40%"]}>
                    <View style={{display:"flex", justifyContent:"space-between",  flex:1, paddingBottom:40, paddingTop: 0, paddingLeft:"5%", paddingRight:"5%", paddingTop:20, borderTopLeftRadius: 5, borderTopRightRadius:5}}>
                        <View>
                        <Text style={{fontWeight:"600", fontSize:20}}>{global.getText("3dSecureTitle")}</Text>

                        <Text style={{color: "gray", fontSize:12, marginTop:5}}>{global.getText("3dSecureModalDescription")}</Text>
                        
                        </View>

                        <OTPInputView ref={(a) => this.savedCardCVCInput = a} onCodeFilled={(code) => {
                            
                            
                            this.savedCardCVCModal.close();
                            this.createOrder({cvc: code});



                            


                        }} pinCount={3} style={{width:"45%", height:100,justifyContent:"flex-start"}} autoFocusOnLoad={false}  codeInputHighlightStyle={{borderWidth:0, borderBottomWidth:1, borderColor:Colors.Blue}} codeInputFieldStyle={{borderWidth:0, borderBottomWidth:0,fontSize:16,width:50,height:50, color:"black", backgroundColor:Colors.Gray, borderColor:Colors.Blue}}/>
                        <TouchableOpacity onPress={() => this.savedCardCVCModal.close()} style={{position:"absolute",right:10, top:20}}>
                            <AntIcon name='close' size={20} />
                        </TouchableOpacity>
                    </View>
                    
                </RBSheet>


            {/* {API.user.business != undefined && <PaymentModal ref={a => this.paymentModal = a} />} */}
                
        </View>
    }
}

const HelperOption = (props) => <TouchableOpacity onPress={() => props.onPress()}>

        <View style={{backgroundColor:"white", paddingLeft:"2%", paddingRight:"4%", paddingTop:7, paddingBottom:7, display:"flex",flexDirection:"row", justifyContent:"space-between"}}>
            <View style={{display:"flex", flexDirection:"row", alignItems:"center",flex:1}}><Text style={{fontSize:16, marginLeft:10}}>{props.title}</Text></View>
            <View style={{display:"flex", flexDirection:"row", alignItems:"center"}}><CheckBox uncheckedColor={props.error?'crimson':undefined} checkedColor={Colors.Blue} textStyle={{color:(props.selected)?"black":props.error?"crimson":"gray"}} onPress={() => props.onPress()} checked={props.selected} containerStyle={{borderWidth:0, backgroundColor:"white", paddingBottom:0, paddingTop:0, paddingRight:0}} iconRight title={props.price+"₺"} /></View>
        </View>
    
</TouchableOpacity>

function capitalizeFirstLetter(string) {
    return string.charAt(0).toUpperCase() + string.slice(1);
  }

function digit(num) {
    return `${num}`.padStart(2, '0');
}