import React from 'react';
import { View } from 'react-native';
import { FloatingLabelInput } from 'react-native-floating-label-input';


export default class AdditionalInformationInput extends React.Component {

    focus() {
        this.a.focus();
    }
    render() {
        return <FloatingLabelInput ref={(a) => this.a = a} returnKeyType={this.props.last?'done':'next'} onSubmit={() => 
            {
            
                this.props.onSubmit()

            }
        } autoCorrect={false} isPassword={this.props.isPassword} keyboardType={this.props.keyboard} autoCapitalize={(this.props.keyboard.startsWith('email'))?'none':'words'}   customLabelStyles={{colorBlurred:"gray",fontSizeBlurred:16, fontSizeFocused:14,colorFocused:"gray"}} onChangeText={(t) => this.props.onChange(t)}containerStyles={{flexDirection: 'row',
        borderColor: '#F3F3F3',
        borderBottomWidth:1.5,
        backgroundColor: (this.props.disabled)?'#e8e8e8':'#00000000',
        paddingTop: 5,
        paddingBottom: 5,
        alignContent: 'center',
        justifyContent: 'center',
        marginTop:20}}  editable={!this.props.disabled} label={this.props.label} value={this.props.value} />
    }
}