import React, { useEffect } from 'react';

import API from './API';
import {View, Text, TouchableOpacity, Button, ScrollView, Vibration, Dimensions, Image, AppState,Animated, Share, Platform} from 'react-native';
// import MapView, { Circle, Marker } from 'react-native-maps';
const MapView = Platform.select({
    web: () => require('./components/Map.web').default,
    default: () =>  require('./components/Map').default,
  })();

  const Marker = Platform.select({
    web: () => <View></View>,
    default: () => <View></View>
  })
import AdditionalChargeRequest from './components/AdditionalChargeRequest';

import Colors from './Colors';

import MaterialCommunityIcon from '@expo/vector-icons/MaterialCommunityIcons';
import OrderReviewDestination from './components/OrderReviewDestination';
import { CommonActions } from '@react-navigation/native'
import RBSheet from "react-native-raw-bottom-sheet";
import AntIcon from '@expo/vector-icons/AntDesign';
import IonIcon from '@expo/vector-icons/Ionicons';
import EntypoIcon from '@expo/vector-icons/Entypo';
import Utils from './Utils';
import { Avatar } from 'react-native-elements';
import { addScreenshotListener } from 'expo-screen-capture';
import * as Linking from 'expo-linking';
import Constants from 'expo-constants';
import { TouchableWithoutFeedback } from 'react-native';
import LoadingGif from './assets/loading-gif.gif';

const bonusPrices = [0,5,10,20,30,50];
export default class OrderCompleteScreen extends React.Component{

    constructor({route, navigation}) {
        super();

        this.state = {cancelOrderPopup: false, completeOrderPopup: false}

        this.order = route.params.order;
        this.pop = route.params.pop;
        

        this.navigation = navigation;


         
       

        var found = false;

        if(this.order.messages) this.order.messages.forEach((msg) => {
            if(msg.d > this.order.lastMC && msg.p) found = true;
        })

        if(!found) this.order.notification = false;
        if(!this.order.charges) this.order.charges = {};
        this.driverFoundAnim = new Animated.Value(0);

        this.scanAnimation = new Animated.Value(0);
        Animated.loop(Animated.timing(this.scanAnimation, {toValue: 1, useNativeDriver: true, duration: 3000, delay:500})).start();
        

        


        
    }

    appStateChange(nextAppState) {
       
        if (nextAppState === 'active') {
            
            //global.showLoadingPanel();

            API.fetch('getorder', {_id: this.order._id}).then((orderData) => {
               
                this.order = orderData;
                
                if(orderData.driverPopup) {
                this.openDriverModal();
                    delete this.order.driverPopup;
                } else {
                    this.forceUpdate();
                }
                //global.hideLoadingPanel();
                //this.navigation.navigate('OrderPending', {order:orderData,pop:true})
            })
        }
    }

    

    componentDidMount() {
        
        var waypoints = [];
        waypoints.push({longitude: this.order.from.coordinates[0],latitude: this.order.from.coordinates[1]})
        this.order.waypoints.forEach((waypoint) => waypoints.push({longitude: waypoint.coordinates[0], latitude: waypoint.coordinates[1]}));
        waypoints.push({longitude: this.order.to.coordinates[0],latitude: this.order.to.coordinates[1]})
        if(this.order.status == 2 && this.order.location) waypoints.push({longitude: this.order.location.l[0], latitude:this.order.location.l[1]});
        
        setTimeout(() => {
            this.map.fitToCoordinates(waypoints, {edgePadding: {top:50, right:50, left:50, bottom:50}, animated:false});

        }, 100);
        
        this.focusListener = this.navigation.addListener('focus', () => {
            this.forceUpdate();
        })

        this.socketListeners = [];

        //this.socketListeners.push(global.socket.on('orderCharge',(a) => this.orderUpdate(a)));
        //this.socketListeners.push(global.socket.on('orderUpdate',(a) => this.orderUpdate(a)));
        this.socketListeners.push(global.socket.bind('orderUpdate', (a) => this.orderUpdate(a)));
        this.socketListeners.push(global.socket.bind('locationUpdate', (a) => this.locationUpdate(a)));
        this.socketListeners.push(global.socket.bind('orderCharge', (a) => {
            

            if(a._id == this.order._id) {
                if(!this.order.charges) this.order.charges = {};
                this.order.charges[a.chargeId] = a.charge;
                this.forceUpdate();
            }
        }))
        this.socketListeners.push(global.socket.bind('chat', (a) => this.chat(a)));
        

        
        this.appStateChangeListener = AppState.addEventListener("change",  (e) => this.appStateChange(e));
       

        this.screenshotListener = addScreenshotListener(() => {
            setTimeout(() => {
                this.screenshotModal.open();
            }, 1000);
            
        })
        if(this.order.driverPopup) {

            setTimeout(() => {
                this.openDriverModal();
            }, 100);
            
            delete this.order.driverPopup;
        }
    }

    chat({_id, message}) {
        if(this.order._id == _id) {
            Vibration.vibrate();
            this.order.notification = true;
            this.forceUpdate();
        }
    }
    
    locationUpdate(a) {
        
        if(this.order._id == a._id) {
            this.order.location = a.location;
            this.forceUpdate();
        }

    }
    openDriverModal() {
        Animated.timing(this.driverFoundAnim, {useNativeDriver:true, toValue:1,duration: 100}).start();
            
        setTimeout(() => {
            if(this.state.driverFoundModalOpen) {
            setTimeout(() => {
                this.setState({driverFoundModalOpen:false});
            }, 105);
            Animated.timing(this.driverFoundAnim, {useNativeDriver:true, toValue:0,duration:100}).start();
            }
        }, 6000);

        this.setState({driverFoundModalOpen:true});
        
    }

    orderUpdate({_id,params,unset}) {
        
        if(this.order._id == _id) {
            // Vibration.vibrate();
            
            
            if(params) Object.keys(params).forEach((key) => {
                
                this.order[key] = params[key];
            })

            if(unset) Object.keys(unset).forEach((key) => {
                if(this.order[key] != undefined) delete this.order[key];
            })
            
            
            if(params && params.status && params.status == 1) {
                this.openDriverModal();

                API.fetch("getorder", {_id: this.order._id});
            }
            

            this.forceUpdate();

           
            
            //this.order.status = status;
            //this.forceUpdate();
        }
    }

    componentWillUnmount() {
        if(this.focusListener) this.focusListener();

        if(this.socketListeners) this.socketListeners.forEach(s => s());
        // global.socket.off('orderUpdate', this.orderUpdate);
        // global.socket.off('locationUpdate', this.locationUpdate);
        // global.socket.off('chat', this.chat);

        this.appStateChangeListener.remove();
        //AppState.removeEventListener("change", this.appStateListener);
        //if(this.appSubscription) this.appSubscription.remove();
        if(this.screenshotListener) this.screenshotListener.remove();
    }

    getExtras() {
        
        var a = [];
        
        for(var i = 0; i < this.order.additionals.length; i++) {
            if(i == 4) {
                if(this.order.additionals[i] > 0) a.push({t: global.getText("additionalPrices")[4]+" x" + this.order.additionals[i], p: this.order.additionalPrices[i]});
                continue;
            }
            if(this.order.additionals[i] && this.order.additionalPrices[i] > 0) a.push({t: global.getText("additionalPrices")[i], p: this.order.additionalPrices[i]});
        }

        

        return a;
    }

    share() {
        Share.share({message: "https://web.tasimacim.com/siparis/" + this.order._id, title: "Sipariş Detaylarını Paylaş"})
    }


    render() {
        
        var plate = (this.order.assignedVehicle)?((this.order.assignedVehicle.plate.split("·").length > 1? this.order.assignedVehicle.plate:(Utils.getVehicleName(this.order.vehicle) + " · " +this.order.assignedVehicle.plate))):"";
        var time = "";
        if(this.order.location) {
            var date = new Date(this.order.location.time);
            time = digit(date.getHours()) + ":" + digit(date.getMinutes());
        }
        var extras = this.getExtras();
        var basePrice = this.order.pricing.price;
        extras.forEach((s) => basePrice -= s.p);
        basePrice -= this.order.pricing.waypointFee;

        
        var charges = this.order.charges != undefined?Object.entries(this.order.charges).map(([key,value]) => ({_id: key, ...value})):[];
        var date = new Date(this.order.date);
        var str = global.getText("days")[date.getDay()] + " " + date.getDate() + " " + global.getText("months")[date.getMonth()] + ", " + digit(date.getHours()) + ":" + digit(date.getMinutes());

        
        return <View style={{display:"flex", flex:1}}>
            <View style={{flex:1}}>
            <MapView flex userInterfaceStyle='light'  ref={(a) => this.map = a} style={{display:"flex", flex:1}}>
                {this.order.status == 0 && <Marker anchor={{x:.5, y:.5}}  coordinate={{longitude: this.order.from.coordinates[0], latitude: this.order.from.coordinates[1]}}>
                    <Animated.View pointerEvents={'none'} style={{width:150,height:150, borderRadius:75, backgroundColor:Colors.Blue,opacity: this.scanAnimation.interpolate({inputRange: [0,1], outputRange:[.7,0]}), transform: [{scale: this.scanAnimation.interpolate({inputRange: [0,1], outputRange: [0,1]})}]}}>

                    </Animated.View>
                </Marker>}
                <Marker zIndex={0} coordinate={{longitude: this.order.from.coordinates[0], latitude: this.order.from.coordinates[1]}}  />
                {this.order.waypoints.map((waypoint, i) => <Marker key={i} coordinate={{longitude: waypoint.coordinates[0], latitude: waypoint.coordinates[1]}}  />)}
                <Marker zIndex={0} coordinate={{longitude: this.order.to.coordinates[0], latitude: this.order.to.coordinates[1]}}  />
                
                {this.order.status > 0 && this.order.status < 4 && this.order.location && <Marker zIndex={10040}  centerOffset={{x:0,y:-25}}  style={{alignItems:"center"}} coordinate={{longitude: this.order.location.l[0], latitude:this.order.location.l[1]}}>
                    <View style={{maxWidth:Dimensions.get('screen').width*.4, backgroundColor:"white", borderRadius:50, padding:5,paddingLeft:10, paddingRight:10,flexWrap:"wrap",alignItems:"center",shadowColor: "#000",
shadowOffset: {
	width: 0,
	height: 2,
},
shadowOpacity: 0.25,
shadowRadius: 3.84,

elevation: 5,}}>
                        <Text style={{fontSize:12,color:"#9FA3A4",maxWidth:Dimensions.get('screen').width*.4,textAlign:"center"}}>{global.getText("lastLocationUpdate").replace("{time}",time)}</Text>
                        
                    </View>   
                    <Avatar  imageProps={{resizeMode:"cover",borderRadius:50}} source={{uri: 'https://tasimacim-driver-pictures.s3.eu-central-1.amazonaws.com/'+this.order.driverDetails._id+'.jpeg'}} containerStyle={{backgroundColor:"white", borderRadius:50,shadowColor: "#000",
shadowOffset: {
	width: 0,
	height: 2,
},
shadowOpacity: 0.25,
shadowRadius: 3.84,

elevation: 5,marginTop:10}} size={35} /> 
                </Marker>}

                
            </MapView>

            {this.order.status == 0 && <View style={{position:"absolute", left:0,right:0,top:0,bottom:0, backgroundColor:"#F6F6F6", justifyContent:"center", alignItems:"center"}}>
                <Image style={{width:"50%"}} resizeMode='contain' source={LoadingGif} />
                
            </View>}

            {this.order.status == 2 && <TouchableOpacity onPress={() => this.map.animateCamera({center: {longitude: this.order.location.l[0], latitude:this.order.location.l[1]}})} style={{position:"absolute",backgroundColor:"white", right:10, bottom:20, borderRadius:50, padding:5,zIndex:100,shadowColor: "#000",
shadowOffset: {
	width: 0,
	height: 2,
},
shadowOpacity: 0.25,
shadowRadius: 3.84,

elevation: 5}}>
                    <MaterialCommunityIcon name='target' size={25} color={Colors.Blue} />
                </TouchableOpacity>}


                {this.order.status > 0 && this.order.status != 10 && <TouchableOpacity onPress={() => this.share()} style={{position:"absolute", bottom:20, left:10,backgroundColor:Colors.Blue,padding:5,paddingLeft:8,paddingRight:4,borderRadius:5,flexDirection:"row", alignItems:"center"}}>
                    <Text style={{color:"white", fontWeight:"500",fontSize:12}}>{global.getText("shareThisRide")}</Text>
                    <EntypoIcon style={{marginLeft:2}} size={15} color={'white'} name='chevron-thin-right' />
                </TouchableOpacity>}
                
                {this.order.status == 2 && this.order.currentWaypoint > 0 && <TouchableOpacity onPress={() => this.helpCenterModal.open()} style={{position:"absolute", top:Constants.statusBarHeight+10, right:10,backgroundColor:"white",padding:8,paddingLeft:10,paddingRight:10,borderRadius:5,flexDirection:"row", alignItems:"center",shadowColor: "#000",
shadowOffset: {
	width: 0,
	height: 1,
},
shadowOpacity: 0.20,
shadowRadius: 1.41,

elevation: 2,}}>
                    <IonIcon size={15} color={'red'} name='help-buoy' />
                    <Text style={{fontWeight:"500",color:"red",fontSize:13,marginLeft:4}}>{global.getText("helpCenter")}</Text>
                    
                </TouchableOpacity>}
            </View>

                <View style={{display:"flex", backgroundColor:"#F3F2F5", borderRadius:10, marginTop:-10, maxHeight:(Platform.OS=='ios')?"75%":"70%", overflow:"hidden"}}>
                    <ScrollView contentContainerStyle={{paddingBottom:20}} >
                    {this.order.status == 0 && <View style={{display:"flex", alignItems:"center", backgroundColor:"white", paddingLeft:"5%", paddingRight:"5%",paddingTop:15, paddingBottom:15, borderTopLeftRadius:10, borderTopRightRadius:10}}>
                    <Text style={{fontWeight:"600"}}>{global.getText("orderNotify")}</Text>

                    <View style={{paddingLeft:5, marginTop:10, paddingRight:5, paddingTop:5, paddingBottom:5, borderWidth:1, borderRadius:10, borderColor:"#F3F2F5", display:"flex", flexDirection:"row"}}>
                        <MaterialCommunityIcon color={"#87939E"} name='clock-alert-outline' size={25} />
                        <Text style={{marginLeft:10, color:"#87939E"}}>{global.getText("scheduledOrderNotify")}</Text>
                    </View>

                    <View>
                        <Text style={{marginTop:15}}>{global.getText("addingBonusNotify")}</Text>
                        <TouchableOpacity onPress={() => {
                            this.setState({selectedBonus: (this.order.pricing.bonus)?this.order.pricing.bonus:0});
                            this.addBonusPanel.open()
                    }} style={{marginTop:15, paddingLeft:15, paddingRight:15, paddingTop:7, paddingBottom:7, borderWidth:2, borderColor:Colors.Blue, borderRadius:100, display:"flex", justifyContent:"center", alignItems:"center", alignSelf:"center"}}>
                            <Text style={{color:Colors.Blue, fontWeight:"600"}}>{global.getText("addBonus")}</Text>
                        </TouchableOpacity>
                    </View>
                    </View>}

                    {this.order.status == -1 && <View style={{display:"flex", alignItems:"center", backgroundColor:"white", paddingLeft:"5%", paddingRight:"5%",paddingTop:10, paddingBottom:20, borderTopLeftRadius:10, borderTopRightRadius:10}}>
                    <Text style={{fontWeight:"600", color:"crimson"}}>{global.getText("orderCanceled")}</Text>

                    <View style={{paddingLeft:5, marginTop:10, paddingRight:5, paddingTop:5, paddingBottom:5, borderWidth:1, borderRadius:10, borderColor:"#F3F2F5", display:"flex", flexDirection:"row"}}>
                        <Text style={{marginLeft:10, color:"#87939E", textAlign:"center"}}>{global.getText("frequentOrderCancel")}</Text>
                    </View>
                    </View>}


                    {this.order.status > 0 && this.order.status < 4 && <View style={{backgroundColor:"white", marginBottom:2, alignItems:"center", paddingTop:15, paddingBottom:15}}>
                        {this.order.status == 1 && <Text style={{fontWeight:"500"}}>{global.getText("scheduledFor", {str})}</Text>}
                        {this.order.status == 2 && this.order.currentWaypoint == 0 && <>
                        <Text style={{fontWeight:"600"}}>{global.getText("onRoutePickup")}</Text>
                        <Text style={{fontWeight:"700", fontSize:18, marginTop:5}}>{plate}</Text>
                        </>}
                        
                        {this.order.status == 2 && this.order.currentWaypoint > 0 && <>
                        <Text style={{fontWeight:"600"}}>{global.getText("onRoute")}</Text>
                        <Text style={{fontWeight:"700", fontSize:18, marginTop:5}}>{plate}</Text>
                        </>}
                    </View>}

                    {this.order.status == 2 && <View style={{backgroundColor:"white",alignItems:"center", marginBottom:2, paddingTop:15, paddingBottom:15}}>
                        
                        <Text style={{fontWeight:"800", color:Colors.Blue, fontSize:23}}>{this.order.code}</Text>
                        <Text style={{color:"gray"}}>{global.getText("pickupCode")}</Text>
                        </View>}

                    {this.order.status > 0 &&  <View style={{display:"flex",flexDirection:"row", justifyContent:"space-between", backgroundColor:"white", paddingLeft:"5%", paddingRight:"5%",paddingTop:15, paddingBottom:15}}>
                    
                    

                    <View style={{flexDirection:"row", alignItems:"center"}}>
                    <Avatar imageProps={{resizeMode:"cover"}} source={{uri: 'https://tasimacim-driver-pictures.s3.eu-central-1.amazonaws.com/'+this.order.driverDetails._id+'.jpeg'}}  size={45} rounded title={"D"} containerStyle={{backgroundColor:"#FEB600"}} />
                    <Text style={{marginTop:5, marginLeft:15, fontSize:16}}>{this.order.driverDetails.name}</Text>

                    </View>
                    
                    {this.order.status != 10 && <View style={{justifyContent:"center", alignItems:"center", flexDirection:"row"}}>

                        <TouchableOpacity onPress={() => {
                           

                            Linking.openURL("tel:+"+this.order.phone);
                    }} style={{width:40, height:40, borderRadius:100, borderColor:Colors.Gray, borderWidth:1, justifyContent:"center", alignItems:"center"}}>
                            <AntIcon name='phone' size={20} color={'black'} />
                        </TouchableOpacity>

                        <TouchableOpacity onPress={() => this.navigation.navigate('Chat', this.order)} style={{width:40, marginLeft:10, height:40, borderRadius:100, borderColor:Colors.Gray, borderWidth:1, justifyContent:"center", alignItems:"center"}}>
                            <AntIcon name='message1' size={20} color={'black'} />
                        </TouchableOpacity>
                        
                    
                    {this.order.notification && <View pointerEvents='none' style={{width:10,height:10, backgroundColor:"red", borderRadius:100, position:"absolute", right:"10%", top:10}}></View>}
                    </View>}

                    {this.order.status == 10 && this.order.review == null && <View style={{justifyContent:"center", alignItems:"center", flexDirection:"row"}}>

                        <TouchableOpacity onPress={() => {global.showReview(this.order, () => this.forceUpdate())}} style={{paddingTop:5,paddingBottom:5,paddingLeft:10, paddingRight:10, borderRadius:10, borderColor:Colors.Blue, borderWidth:1.2, justifyContent:"center", alignItems:"center"}}>
                            <Text style={{color:Colors.Blue, fontSize:16,fontWeight:"500"}}>{global.getText("rateOrder")}</Text>
                        </TouchableOpacity>

                        
                        
                    
                    {this.order.notification && <View pointerEvents='none' style={{width:10,height:10, backgroundColor:"red", borderRadius:100, position:"absolute", right:"10%", top:10}}></View>}
                    </View>}
                    </View>}

                    <View style={{marginTop:10, paddingLeft:"5%", paddingRight:"5%",paddingTop:10, paddingBottom:10, backgroundColor:"white", display:"flex"}}>
                        {this.order.status == 0 &&<><Text style={{fontSize:16}}>{str}</Text>
                        <Text style={{fontSize:14, color:"#87939E"}}>{Utils.getVehicleName(this.order.vehicle)} · {global.getText("orderID")} {this.order._id.substring(18).toUpperCase()}</Text></>}

                        <OrderReviewDestination selected={this.order.from} first />
                        {this.order.waypoints.map((waypoint, i) => <OrderReviewDestination key={i} selected={waypoint} />)}
                        <OrderReviewDestination selected={this.order.to} />

                        {this.order.note != undefined && this.order.note != "" && <Text style={{marginTop:10, color: "gray"}}>
                            {this.order.note}
                            </Text>}

                        <View style={{display:"flex" ,flexDirection:"row", justifyContent:"space-between", marginTop:20}}>
                            <Text>{global.getText("baseFee")}</Text><Text>{basePrice}₺</Text>
                        </View>

                        {charges && charges.filter((s) => s.v).map((charge, i) => <View keyt={i} style={{display:"flex" ,flexDirection:"row", justifyContent:"space-between", marginTop:10}}>
                            <Text>{charge.d}</Text><Text>{charge.p}₺</Text>
                        </View>)}
                        <View style={{height:1, backgroundColor:"#F3F2F5", marginTop:10}}></View>

                        {extras.map((extra, i) => <View key={i} style={{display:"flex" ,flexDirection:"row", justifyContent:"space-between", marginTop:10}}>
                            <Text>{extra.t}</Text><Text>{extra.p}₺</Text>
                        </View>)}

                        {this.order.pricing.bonus != undefined && this.order.pricing.bonus > 0 && <View style={{display:"flex" ,flexDirection:"row", justifyContent:"space-between", marginTop:10}}>
                            <Text>{global.getText("bonus")}</Text><Text>{this.order.pricing.bonus}₺</Text>
                        </View>}

                        {this.order.waypoints != undefined && this.order.waypoints.length > 0 && <View style={{display:"flex" ,flexDirection:"row", justifyContent:"space-between", marginTop:10}}>
                            <Text>{global.getText("waypointFee")} x{this.order.waypoints.length}</Text><Text>{this.order.pricing.waypointFee}₺</Text>
                        </View>}

                        {(extras.length > 0 || (this.order.pricing.bonus != undefined && this.order.pricing.bonus > 0)) && <View style={{height:1, backgroundColor:"#F3F2F5", marginTop:10}}></View>}
                        
                        <View style={{display:"flex", justifyContent:"space-between", alignContent:"center", flexDirection:"row", marginTop:15}}><Text>{global.getText("serviceTime")} ({this.getTimeDisplay()})</Text><Text style={{fontWeight:"600"}}>0₺</Text></View>
                    <Text style={{fontWeight:"600", color:"crimson", fontSize:11, marginTop:5, width:Dimensions.get("screen").width*.8}}>{global.getText("serviceUsage")}</Text>

                        <View style={{display:"flex" ,flexDirection:"row", justifyContent:"space-between", marginTop:10}}>
                            <Text style={{fontWeight:"600"}}>{global.getText("total")}</Text><Text style={{fontWeight:"600"}}>{API.getOrderTotal(this.order)}₺</Text>
                        </View>
                    </View>

                    {charges != undefined && charges.filter((s) => s.v == undefined).map((charge, i) => <AdditionalChargeRequest onPress={async (answer) => {

                        global.showLoadingPanel();
                        
                        var val = await API.fetch('chargeanswer', {answer, _id: this.order._id,charge: charge._id, p:charge.p});

                        if(!val.error) {
                            this.order.charges[charge._id].v = answer;
                            // charge.v = answer;

                            // if(!answer) this.order.charges.splice(this.order.charges.indexOf(charge),1);

                            global.hideLoadingPanel();
                        }
                       

                        this.forceUpdate();

                    }} charge={charge} key={i} />)}

                    <View style={{marginTop:2, paddingLeft:"5%", paddingRight:"5%",paddingTop:10, paddingBottom:10, backgroundColor:"white", display:"flex"}}>
                        <Text style={{fontWeight:"600", fontSize:16}}>{global.getText("payment")}</Text>
                        <Text>{global.getText("paymentMethods")[this.order.paymentMethod]}</Text>
                    </View>

                    <TouchableOpacity onPress={() => {
                        this.props.navigation.navigate('Chat', {_id: 0,from: this.order._id})
                    }} style={{marginTop:10, paddingLeft:"5%", paddingRight:"5%",paddingTop:10, paddingBottom:10, backgroundColor:"white", display:"flex"}}>
                        <Text style={{fontWeight:"600", fontSize:16, color:Colors.Blue, textAlign:"center"}}>{global.getText("liveSupport")}</Text>
                    </TouchableOpacity>

                    {(this.order.status == -1 || this.order.status == 10) && <TouchableOpacity onPress={() => {
                        var waypoints =  [this.order.from, ...this.order.waypoints,this.order.to];
                        
                        for(var i = 0; i < waypoints.length; i++) {
                            const w = waypoints[i];
                            waypoints[i] = {Label: w.Label,building: w.building, elevator: w.elevator, floor: w.floor,door: w.door, Point: [w.coordinates[0],w.coordinates[1]]}
                        }
                        this.navigation.dispatch(CommonActions.reset({
                            index:0,
                            routes: [{name: "Home", params: {waypoints}}]
                        }));
                    }} style={{marginTop:10, paddingLeft:"5%", paddingRight:"5%",paddingTop:10, paddingBottom:10, backgroundColor:"white", display:"flex"}}>
                        <Text style={{fontWeight:"600", fontSize:16, color:"gray", textAlign:"center"}}>{global.getText("reuseDetails")}</Text>
                    </TouchableOpacity>}

                   

                    {this.order.status != -1 && this.order.status < 4 && (this.order.currentWaypoint == undefined || this.order.currentWaypoint <= 0) && <TouchableOpacity onPress={() => this.setState({cancelOrderPopup: true})} style={{marginTop:10, paddingLeft:"5%", paddingRight:"5%",paddingTop:10, paddingBottom:10, backgroundColor:"white", display:"flex"}}>
                        <Text style={{fontWeight:"600", fontSize:16, color:"crimson", textAlign:"center"}}>{global.getText("cancelOrder")}</Text>
                    </TouchableOpacity>}

                    
                    </ScrollView>
                </View>

                <TouchableOpacity onPress={() =>{ 
                    this.navigation.pop();
                // if(this.pop)
                //     this.navigation.pop()
                // else
                //     this.navigation.replace("Home")

                }
               // this.navigation.pop()
            
            } style={{position:"absolute", backgroundColor:"white", top:Constants.statusBarHeight+10, left:10, paddingLeft:5, paddingRight:5, paddingTop:5, paddingBottom:5, borderRadius:100,shadowColor: "#000",
shadowOffset: {
	width: 0,
	height: 1,
},
shadowOpacity: 0.20,
shadowRadius: 1.41,

elevation: 2,}}>
                <MaterialCommunityIcon name='chevron-left' size={30} />
                </TouchableOpacity>

                {this.state.cancelOrderPopup && <View style={{backgroundColor:"rgba(0,0,0,.4)", position:"absolute", top:0, bottom:0, left:0, right:0, display:"flex", justifyContent:"center", alignItems:"center"}}>
                    <View style={{backgroundColor:"white", display:"flex", borderRadius:10,width:"80%", paddingLeft:25, paddingRight:25, paddingTop:20, paddingBottom:20, alignItems:"center",shadowColor: "#000",
shadowOffset: {
	width: 0,
	height: 2,
},
shadowOpacity: 0.23,
shadowRadius: 2.62,

elevation: 4,}}>
                        <Text style={{fontWeight:"600", fontSize:18}}>{global.getText("cancelOrder")}?</Text>
                        <Text style={{marginTop:5, fontSize:16}}>{global.getText("cancelOrderPrompt")}</Text>

                        {this.order.status == 2 && this.order.driverAssignDate && Date.now()-this.order.driverAssignDate >= 300000 && <Text style={{textAlign:"center", width:"80%", marginTop:20, color:"red"}}>
                            {global.getText("cancelPrompts")[(this.order.nearLocation)?2:1].replace("*price*",(this.order.pricing.price*(this.order.scheduled?2:1)*(this.order.nearLocation?.1:.05)).toFixed(2))}
                        </Text>}

                        <TouchableOpacity onPress={() => {
                            global.showLoadingPanel();
                            API.fetch('cancelorder', {_id: this.order._id, penalty: (this.order.status == 2 && this.order.driverAssignDate && Date.now()-this.order.driverAssignDate >= 300000)?(this.order.pricing.price*(this.order.scheduled?2:1)*(this.order.nearLocation?.1:.05)).toFixed(2):(0).toFixed(2)}).then((res) => {
                                if(!res.error) {
                                    this.order.status = -1;
                                    this.navigation.replace('Home');

                                    global.checkSocketConnection();
                                    global.hideLoadingPanel();
                                }
                            })
                            
                            
                        }} style={{borderRadius:100, backgroundColor:Colors.Blue, alignSelf:"stretch", display:"flex", alignItems:"center",marginTop:20}}><Text style={{fontWeight:"600", color:"white", paddingTop:10, paddingBottom:10}}>{global.getText((this.order.status == 2 && this.order.driverAssignDate && Date.now()-this.order.driverAssignDate >= 300000)?"stillCancel":"cancelOrder")}</Text></TouchableOpacity>
                        <TouchableOpacity onPress={() => {
                            this.setState({cancelOrderPopup: false});
                        }} style={{borderRadius:100, backgroundColor:"white", borderWidth:2,borderColor:Colors.Blue, alignSelf:"stretch", display:"flex", alignItems:"center", marginTop:10}}><Text style={{fontWeight:"600", color:Colors.Blue, paddingTop:10, paddingBottom:10}}>{global.getText(this.order.state==0?"keepSearching":"nevermind")}</Text></TouchableOpacity>
                    </View>
                </View>}



                {this.state.completeOrderPopup && <View style={{backgroundColor:"rgba(0,0,0,.4)", position:"absolute", top:0, bottom:0, left:0, right:0, display:"flex", justifyContent:"center", alignItems:"center"}}>
                    <View style={{backgroundColor:"white", maxWidth:"80%", display:"flex", borderRadius:10, paddingLeft:25, paddingRight:25, paddingTop:20, paddingBottom:20, alignItems:"center",shadowColor: "#000",
shadowOffset: {
	width: 0,
	height: 2,
},
shadowOpacity: 0.23,
shadowRadius: 2.62,

elevation: 4,}}>
                        <Text style={{fontWeight:"600", fontSize:18}}>Complete order?</Text>
                        <Text style={{marginTop:5, fontSize:16, textAlign:"center"}}>Only complete your order once the delivery and the payment is done.</Text>

                        <TouchableOpacity onPress={() => {
                            API.fetch('completeorder', {_id: this.order._id}).then((res) => {
                                this.order.status = 10;
                                this.navigation.replace('Home');

                                global.checkSocketConnection();
                            })
                            
                            
                        }} style={{borderRadius:100, backgroundColor:"green", alignSelf:"stretch", display:"flex", alignItems:"center",marginTop:50}}><Text style={{fontWeight:"600", color:"white", paddingTop:10, paddingBottom:10}}>Complete order</Text></TouchableOpacity>
                        <TouchableOpacity onPress={() => {
                            this.setState({completeOrderPopup: false});
                        }} style={{borderRadius:100, backgroundColor:"white", borderWidth:2,borderColor:Colors.Blue, alignSelf:"stretch", display:"flex", alignItems:"center", marginTop:10}}><Text style={{fontWeight:"600", color:Colors.Blue, paddingTop:10, paddingBottom:10}}>Nevermind</Text></TouchableOpacity>
                    </View>
                </View>}



                <RBSheet ref={(a) => this.addBonusPanel = a} height={250} customStyles={{container: {borderTopLeftRadius: 5, borderTopRightRadius:5}}} closeOnDragDown={false} closeOnPressMask={true} snapPoints={["40%"]}>
                    <View style={{display:"flex", justifyContent:"space-between",  flex:1, paddingBottom:40, paddingTop: 0, paddingLeft:"5%", paddingRight:"5%", paddingTop:20, borderTopLeftRadius: 5, borderTopRightRadius:5}}>
                        <View>
                        <Text style={{fontWeight:"600", fontSize:20}}>{global.getText("customAmount")}</Text>

                        <Text style={{color: "gray", fontSize:10, marginTop:5}}>{global.getText("addingBonusNotify")}</Text>
                        <View style={{display:"flex", flexDirection:"row", marginTop:20}}>

                            {bonusPrices.map((price, i) => <TouchableOpacity onPress={() => this.setState({selectedBonus: price})} style={{backgroundColor:(price == this.state.selectedBonus)?Colors.Blue:"#F5F4F8", borderRadius:100, marginLeft:(i == 0)?0:5, paddingLeft:12, paddingRight:12, paddingTop:7, paddingBottom:7}} key={i}>
                                <Text style={{fontSize:16,color:(this.state.selectedBonus == price)?"white":"black"}}>{i == 0?global.getText("noBonus"):price+"₺"}</Text>
                            </TouchableOpacity>)}
                        </View>
                        </View>

                        <TouchableOpacity onPress={async () => {
            
                        global.showLoadingPanel();
                        this.addBonusPanel.close();
                        var bonus = this.state.selectedBonus;

                        var res = await API.fetch('setbonus', {_id: this.order._id, bonus});

                        if(!res.error) {
                            this.order.pricing.bonus = bonus;
                            global.hideLoadingPanel();
                        }

                        

                        
                        this.forceUpdate();
            

            }} style={{backgroundColor:Colors.Blue, paddingBottom:8, paddingTop:8, display:"flex", justifyContent:"center", alignItems:"center", borderRadius:30}}><Text style={{color:"white", fontWeight:"700", fontSize:17}}>Save</Text></TouchableOpacity>
                    
                        <TouchableOpacity onPress={() => this.addBonusPanel.close()} style={{position:"absolute",right:10, top:20}}>
                            <AntIcon name='close' size={20} />
                        </TouchableOpacity>
                    </View>
                </RBSheet>


                <RBSheet ref={(a) => this.screenshotModal = a} height={175} customStyles={{container: {borderTopLeftRadius: 5, borderTopRightRadius:5}}} closeOnDragDown={false} closeOnPressMask={true} snapPoints={["40%"]}>
                    <View style={{display:"flex",   flex:1, paddingBottom:40, paddingTop: 0, paddingLeft:"5%", paddingRight:"5%", paddingTop:20, borderTopLeftRadius: 5, borderTopRightRadius:5}}>
                        <View>
                        <Text style={{fontWeight:"600", fontSize:20}}>{global.getText("tookScreenshot")}</Text>

                        <Text style={{color: "gray", fontSize:10, marginTop:5}}>{global.getText("youMightWantTo")}</Text>
                        
                        </View>
                        <BottomSheetAction onPress={() => {
                            this.screenshotModal.close();

                            setTimeout(() => {
                                this.share();
                            }, 500);
                            
                        }} icon={<AntIcon name='sharealt' size={25} />} title={global.getText("shareThisRide")} desc={global.getText("shareLive")} />
                        
                    </View>
                </RBSheet>

                <RBSheet ref={(a) => this.helpCenterModal = a} height={275} customStyles={{container: {borderTopLeftRadius: 5, borderTopRightRadius:5}}} closeOnDragDown={false} closeOnPressMask={true} snapPoints={["40%"]}>
                    <View style={{display:"flex",   flex:1, paddingBottom:40, paddingTop: 0, paddingLeft:"5%", paddingRight:"5%", paddingTop:20, borderTopLeftRadius: 5, borderTopRightRadius:5}}>
                        <View>
                        <Text style={{fontWeight:"600", fontSize:20}}>{global.getText("helpCenter")}</Text>
                        
                        </View>

                        <BottomSheetAction onPress={() => {
                            this.screenshotModal.close();

                            setTimeout(() => {
                                this.share();
                            }, 500);
                            
                        }} icon={<AntIcon name='sharealt' size={25} />} title={global.getText("shareThisRide")} desc={global.getText("shareLive")} />


                        <BottomSheetAction onPress={() => {
                            this.screenshotModal.close();

                            API.fetch("callorder", {_id: this.order._id, decline: true});
                            Linking.openURL("tel:+908505328505");
                            
                        }} icon={<AntIcon name='customerservice' size={25} />} title={global.getText("customerService")} desc={global.getText("customerServiceDesc")} />

                        <BottomSheetAction onPress={() => {
                            this.screenshotModal.close();

                            Linking.openURL("tel:112")
                            
                        }} icon={<MaterialCommunityIcon name='alarm-light' size={25} color={'red'} />} emergency title={global.getText("emergencyHotline")} desc={global.getText("emergencyHotlineDesc")} />

                        
                    </View>
                </RBSheet>

                {this.order.driverDetails && this.state.driverFoundModalOpen && <TouchableWithoutFeedback onPress={() => {
                    setTimeout(() => {
                        this.setState({driverFoundModalOpen:false});
                    }, 105);
                    Animated.timing(this.driverFoundAnim, {useNativeDriver:true, toValue:0,duration:100}).start();
                }}><Animated.View style={{opacity: this.driverFoundAnim,position:"absolute", left:0,right:0,top:0,bottom:0,backgroundColor:"rgba(0,0,0,.4)",justifyContent:"center", alignItems:"center"}}>
                <View style={{width:"90%", backgroundColor:"white", alignItems:"center",borderRadius:10,paddingTop:20, paddingBottom:20}}>
                  <Text style={{fontWeight:"600",fontSize:20,marginBottom:20}}>{global.getText("driverFound")}</Text>

                  <Avatar imageProps={{resizeMode:"cover"}} source={{uri: 'https://tasimacim-driver-pictures.s3.eu-central-1.amazonaws.com/'+this.order.driverDetails._id+'.jpeg'}}  size={90} rounded title={"S"} containerStyle={{backgroundColor:Colors.Blue}} />
                  <Text style={{fontWeight:"600",fontSize:20,marginTop:20}}>{this.order.driverDetails.name}</Text>

                  <Text style={{marginTop:10,fontWeight:"600"}}>{plate}</Text>
                </View>
                </Animated.View></TouchableWithoutFeedback>}
            </View>
    }

    getTimeDisplay() {
        
        var minutes = this.order.time;
        var hours = Math.floor(minutes/60);
        minutes -= hours*60;

        return Utils.getHoursAndMinutes(hours, minutes);
        //return ((hours > 0)?hours + "hr"+(hours > 1?"s":""):"") + ((minutes> 0)?((hours > 0)?" ":"")+minutes+"m":"");
    }
    
}

const BottomSheetAction = (props) => {
    return <TouchableOpacity onPress={() => props.onPress()} style={{flexDirection:"row",alignItems:"center", marginTop:20, width:"100%"}}>
                            {props.icon}
    <View style={{marginLeft:10}}>
<Text style={{fontWeight:"600", fontSize:18,color: (props.emergency)?"red":"black"}}>{props.title}</Text>

<Text style={{color: (props.emergency)?"red":"gray", fontSize:10, marginTop:2}}>{props.desc}</Text>

</View>
</TouchableOpacity>
}

function digit(num) {
    return `${num}`.padStart(2, '0');
}