import React from "react";
import { View, Text } from "react-native";
// import MapView from '@alamoweb/react-native-web-mapview';
import MapView from '../components/map/index';
import Marker from "../components/map/Marker";
export default class Map extends React.Component {

    constructor(props) {
      
    
      super(props);

        
       
    }


    animateToRegion(a,b, zoom) {

        console.log(a,b);
        this.t.animateCamera({center:a, zoom },b)
    }

    fitToCoordinates(a,b) {

        // console.log(a,b);
        // var val = this.calculateMidpointAndRectangularArea(a);

        // console.log(val.longestDistance, val.midpoint);
        // this.t.animateCamera({center: val.midpoint, zoom: Math.round(val.longestDistance*0.28)},b)

        this.t.flyToBounds(a.map(x => [x.latitude, x.longitude]));

    
    }

    calculateMidpointAndRectangularArea(coordinates) {
        if (coordinates.length === 0) {
          return null; // Handle empty array case
        }
      
        let minLatitude = Number.MAX_VALUE;
        let maxLatitude = -Number.MAX_VALUE;
        let minLongitude = Number.MAX_VALUE;
        let maxLongitude = -Number.MAX_VALUE;
      
        let totalLatitude = 0;
        let totalLongitude = 0;
      
        for (const coordinate of coordinates) {
          totalLatitude += coordinate.latitude;
          totalLongitude += coordinate.longitude;
      
          minLatitude = Math.min(minLatitude, coordinate.latitude);
          maxLatitude = Math.max(maxLatitude, coordinate.latitude);
          minLongitude = Math.min(minLongitude, coordinate.longitude);
          maxLongitude = Math.max(maxLongitude, coordinate.longitude);
        }
      
        const averageLatitude = totalLatitude / coordinates.length;
        const averageLongitude = totalLongitude / coordinates.length;
      
        const rectangularArea = {
          minLatitude,
          maxLatitude,
          minLongitude,
          maxLongitude,
        };
      
        // Calculate the longest distance (diagonal)
        const diagonal = this.calculateDistance(
          minLatitude,
          minLongitude,
          maxLatitude,
          maxLongitude
        );
      
        return {
          midpoint: {
            latitude: averageLatitude,
            longitude: averageLongitude,
          },
          rectangularArea,
          longestDistance: diagonal,
        };
      }
      
       calculateDistance(lat1, lon1, lat2, lon2) {
        // Using the Haversine formula to calculate the distance between two coordinates
        const toRad = (degrees) => (degrees * Math.PI) / 180;
        const R = 6371; // Earth's radius in km
        const dLat = toRad(lat2 - lat1);
        const dLon = toRad(lon2 - lon1);
      
        const a =
          Math.sin(dLat / 2) * Math.sin(dLat / 2) +
          Math.cos(toRad(lat1)) * Math.cos(toRad(lat2)) * Math.sin(dLon / 2) * Math.sin(dLon / 2);
      
        const c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));
        const distance = R * c;
      
        return distance;
      }

    componentWillUnmount() {
        
        
    }

    render() {

      
      
        return <View style={{height:(this.props.height || 200),flex: 1, width:"100%"}}><MapView defaultZoomLevel={this.props.zoomLevel} onMapReady={(map) => console.log(map)} initialRegion={this.props.initialRegion || {latitude: 41.015, longitude: 28.979530, latitudeDelta: 2, longitudeDelta: 2}}  ref={(e) => {
          
          this.t = e; 

          
        }} mapOptions={{
                        zoomControl: false
                    }} zoom={this.props.zoom}  onMessage={() => {}} onRegionChangeComplete={(a) => {if(this.props.onRegionChangeComplete) this.props.onRegionChangeComplete(a);}} mapCenterPosition={[ 41.015137,28.979530]} mapLayers={[
                        {
                        
            baseLayerName: 'Mapbox',  // This will be seen in the layer selection control
            baseLayerIsChecked: true,  // If the layer is selected in the layer selection control
            layerType: 'TileLayer', 
            baseLayer: true,
            url: `https://api.mapbox.com/styles/v1/mapbox/streets-v11/tiles/{z}/{x}/{y}?access_token=pk.eyJ1IjoiYmVoYXVsYXMiLCJhIjoiY2w5aDdxazRsMHpmdzN3bzBncWY0cmdrYyJ9.yf8dmfnACJpkIGSd_NVeFw`,
            attribution:
              '&amp;copy <a href=&quot;http://osm.org/copyright&quot;>OpenStreetMap</a> contributors',
                        }]
            } >


{this.props.waypoints.filter(s => s != undefined).map((x, i) => {
  console.log([x.Point[1],x.Point[0]]);
return <Marker key={i} coordinate={{latitude: x.Point[1], longitude: x.Point[0]}} />

    })}

            </MapView>
            
           
            
            </View>
//         return <ExpoLeaflet ref={(e) => this.t = e} mapOptions={{
//             zoomControl: false
//         }} zoom={this.props.zoom}  onMessage={() => {}} mapCenterPosition={[ 41.015137,28.979530]} mapLayers={[
//             {
            
// baseLayerName: 'OpenStreetMap',  // This will be seen in the layer selection control
// baseLayerIsChecked: true,  // If the layer is selected in the layer selection control
// layerType: 'TileLayer', 
// baseLayer: true,
// url: `https://api.mapbox.com/styles/v1/mapbox/streets-v11/tiles/{z}/{x}/{y}?access_token=pk.eyJ1IjoiYmVoYXVsYXMiLCJhIjoiY2w5aDdxazRsMHpmdzN3bzBncWY0cmdrYyJ9.yf8dmfnACJpkIGSd_NVeFw`,
// attribution:
//   '&amp;copy <a href=&quot;http://osm.org/copyright&quot;>OpenStreetMap</a> contributors',
//             }]
// } />
    }
}