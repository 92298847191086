import React from 'react';
import {View, Text, TouchableOpacity, ActivityIndicator} from 'react-native';
import Colors from './Colors';

export default class LoadingPanel extends React.Component {


    render() {
        return <View style={{backgroundColor:"rgba(0,0,0,.4)", position:"absolute", left:0 ,right:0, top:0,zIndex:9999999, bottom:0, justifyContent:"center", alignItems:"center"}}>
          
        {this.props.error == undefined && <ActivityIndicator color={"black"} />}

        {this.props.error != undefined && <View style={{alignItems:"center", backgroundColor:"white", borderRadius:5, paddingTop:25, width:"90%"}}>
          
          <View style={{paddingLeft:30, paddingRight:30, alignItems:"center"}}>
            <Text style={{fontWeight:"600", color:"#3E3F47", fontSize:22}}>{global.getText("oops")}</Text>
              <Text style={{textAlign:"center", color:"#908F92", fontSize: 17, marginTop:15}}>{this.props.error}</Text>

              </View>
              <TouchableOpacity onPress={() => this.props.onPress()} style={{borderColor:"#F0F0F0", borderTopWidth:1, width:"100%", alignItems:"center",marginTop:20,paddingTop:15, paddingBottom:15}}>
                  <Text style={{color:"#908F92", fontWeight:"700", fontSize:17}}>{global.getText("errorOK")}</Text>
              </TouchableOpacity>
          </View>}
      </View>
    }
}