import React from 'react';
import Colors from '../Colors';
import {View, Text, TouchableOpacity, Image, FlatList, Dimensions, Animated} from 'react-native';
import Icon from '@expo/vector-icons/AntDesign';
import AsyncStorage from '@react-native-async-storage/async-storage';

const pagesData = [
    require('../assets/onboarding/onboarding0.png'),
    require('../assets/onboarding/onboarding1.png'),
    require('../assets/onboarding/onboarding2.png')
]

export default class OnboardingModal extends React.Component {

    constructor(props) {
        super(props);   

        this.scrollX = new Animated.Value(0);
        this.carouselOpacity = new Animated.Value(1);
        this.state = {getStarted: this.props.active,currentIndex:0}

        this.viewableItemsChanged = this.viewableItemsChanged.bind(this)
        
    }

    viewableItemsChanged({viewableItems, changed}) {
        this.setState({currentIndex: viewableItems[0].index})

        if(viewableItems[0].index >= pagesData.length) this.hideCarousel();
        
    }

    hideCarousel() {
        AsyncStorage.setItem("onboardingModal", "1");
        
        Animated.timing(this.carouselOpacity, {useNativeDriver: false, duration:250, toValue:0}).start();

        setTimeout(() => {
            this.setState({getStarted:false});
        }, 1000);
    }

    render() {
        if(!this.state.getStarted) return <></>
        const width = Dimensions.get("screen").width;

        return <Animated.View style={{display:"flex",position:"absolute",left:0,right:0,top:0,bottom:0, opacity:this.carouselOpacity, justifyContent:"center", alignItems:"center", flex:1, backgroundColor:"white"}}>
        <FlatList scrollEventThrottle={32} ref={(a) => this.getStartedList = a} viewabilityConfig={{viewAreaCoveragePercentThreshold:50}} onViewableItemsChanged={this.viewableItemsChanged} onScroll={Animated.event([{nativeEvent: {contentOffset:{x: this.scrollX}}}],{ useNativeDriver:false})} horizontal showsHorizontalScrollIndicator={false} pagingEnabled bounces={false}  data={[...pagesData,{}]} keyExtractor={(i,s) => s} renderItem={({item,index}) => 
        
        <View style={{paddingTop:"10%",alignItems:"center",width:Dimensions.get("screen").width}}>
            <View style={{width:"90%",aspectRatio:1,  justifyContent:"center", alignItems:"center"}}>
                <Image source={item} style={{position:"absolute", width:"100%", resizeMode:"contain"}} />
            </View>

            {index < pagesData.length && <Text style={{textAlign:"center", fontWeight:"600",fontSize:25,marginTop:20}}>{global.getText("onboardings")[index].title}</Text>}
            {index < pagesData.length && <Text style={{textAlign:"center", fontWeight:"400", fontSize:20, marginTop:20,marginLeft:"5%", marginRight:"5%"}}>{global.getText("onboardings")[index].desc}</Text>}
            
            
            </View>}/>


            <View style={{justifyContent:"space-between", flexDirection:"row",width:"100%", paddingLeft:"10%", paddingRight:"10%",alignItems:"center", marginBottom:"10%"}}>


                <TouchableOpacity hitSlop={{left: 15, right:15, top:15, bottom:15}}  onPress={() => {
                    this.hideCarousel();
                }}><Animated.Text style={{fontWeight:"600",fontSize:14,opacity:this.scrollX.interpolate({
                    inputRange: [(pagesData.length-2)*width,(pagesData.length-1)*width],
                    outputRange: [1,0],
                    extrapolate:"clamp"
                })
                    
            }}>{global.getText("skip")}</Animated.Text></TouchableOpacity>
            <View style={{flexDirection:"row"}}>
                {pagesData.map((_,i) => {
               
                const inputRange = [(i-1)*width,i*width,(i+1)*width];

                const dotWidth = this.scrollX.interpolate({
                    inputRange,
                    outputRange: [10,20,10],
                    extrapolate: 'clamp'
                })
                return <Animated.View style={{height:10,borderRadius:5,backgroundColor:Colors.Blue,opacity:this.scrollX.interpolate({inputRange, outputRange:[0.3,1,0.3],extrapolate:"clamp"}), marginHorizontal:8,width:dotWidth}} key={i} />

})}
            </View>

            <Animated.View style={{opacity: this.scrollX.interpolate({
                    inputRange: [(pagesData.length-2)*width,(pagesData.length-1)*width],
                    outputRange: [1,0],
                    extrapolate:"clamp"
                })}}>
            <TouchableOpacity hitSlop={{left: 15, right:15, top:15, bottom:15}}  onPress={() => {
                if(this.state.currentIndex < pagesData.length - 1) {
                    this.getStartedList.scrollToIndex({index: this.state.currentIndex+1});
                } else {
                    this.hideCarousel();
                }
            }}><Icon name='arrowright' size={30}/></TouchableOpacity></Animated.View>

            
            </View>

            <Animated.View style={{position:"absolute", bottom:"15%",opacity: this.scrollX.interpolate({
                    inputRange: [(pagesData.length-2)*width,(pagesData.length-1)*width],
                    outputRange: [0,1],
                    extrapolate:"clamp"
                })}}>
            <TouchableOpacity style={{backgroundColor:Colors.Blue, paddingTop:10,paddingBottom:10,paddingLeft:30,paddingRight:30,borderRadius:15}} hitSlop={{left: 15, right:15, top:15, bottom:15}}  onPress={() => {
                this.hideCarousel();
            }}><Text style={{color:"white", fontWeight:"700",fontSize:18}}>Get Started!</Text></TouchableOpacity></Animated.View>
    </Animated.View>
    }
}