import React from 'react';
import { StyleSheet, Text, View, TouchableOpacity } from 'react-native';

import Icon from '@expo/vector-icons/FontAwesome';
import IonIcon from '@expo/vector-icons/Ionicons';
import Colors from '../Colors';
import API from '../API';

export default class Destination extends React.Component {


    constructor() {
        super();

    }
    render() {
        return <View style={{display:"flex", flexDirection:"row", alignItems:"center", justifyContent:"center", marginTop:20}}>
            
            <View style={{marginLeft:20, marginRight:20, width:20,alignItems:"center", justifyContent:"center", display:"flex", overflow:"visible"}}>
            {this.props.first && <Icon  name='circle-o' color={Colors.Blue} size={24} style={{position:"absolute"}} />}
            {!this.props.first && <View style={{position:"absolute", backgroundColor:"#D6D6DA",width:1,left:9,height:39, bottom:10}} />}
            {!this.props.first && <Icon name='map-marker' color={Colors.Blue} size={30} style={{position:"absolute"}} />}
            

            </View>
            <TouchableOpacity onPress={() => this.props.onPress()} style={{backgroundColor:(this.props.selected)?"#F3F2F5":Colors.LightBlue, borderRadius:15, paddingLeft:10, paddingTop:10, paddingRight:10, paddingBottom:10, flex:1, marginRight:(this.props.onRemove)?10:20}}><Text style={{fontSize:(this.props.selected?15:20), fontWeight:(this.props.selected)?"normal":"600"}}>{this.props.selected?
            API.getAddressLabel(this.props.selected)
            
            :global.getText(this.props.first?"whereFrom":"whereTo")}</Text></TouchableOpacity>




            {this.props.onRemove && <TouchableOpacity onPress={() =>this.props.onRemove()} style={{marginRight:"2%"}}>
                <IonIcon size={30} name='close' />
                </TouchableOpacity>}
        </View>
    }
}