import React from 'react';
import {View, Text, TouchableOpacity} from 'react-native';
import CustomModal from '../CustomModal';
import API from '../API';
import { SwipeListView } from 'react-native-swipe-list-view';
import SavedCard from './SavedCard';
import ParamLogo from './ParamLogo';
import IonIcon from '@expo/vector-icons/Ionicons';
import Colors from '../Colors';
import AddCardModal from './AddCardModal';
import AntIcon from '@expo/vector-icons/AntDesign';
import { Platform } from 'expo-modules-core';

const TOPUP_AMOUNTS = [500,1000,1500];

export default class PaymentModal extends React.Component {

    constructor() {
        super();
        this.state = {};
    }

    open() {
        this.paymentModal.open();
    }


    render() {

        const canTopup = this.state.selectedCard != undefined && this.state.selectedTopup != undefined;
        return <CustomModal scrollView={false} title={global.getText("payment")} ref={(a) => {this.paymentModal = a; }}> 
           {/*if(a && !a.isOpened()) a.open()*/}
        {/* <ScrollView style={{flex:1}}>
            <View style={{backgroundColor:"white", paddingLeft:"5%", paddingRight:"5%", marginTop:10}}>
                <View  style={{paddingTop:20, paddingBottom:10,}}>
                    <Text style={{fontWeight:"600", fontSize:18}}>Kayıtlı Kartlar</Text>
                    {API.user.cards.map((x, i) => <SavedCard  card={x} key={i} />)}
                   

                    <TouchableOpacity onPress={() => {
                      this.addCreditCardModal.open();
                        
                    }} style={{flexDirection:"row", alignItems:"center", marginTop:20}}>
                        <IonIcon color={Colors.Blue}  name='add' size={25}/>
                        <View style={{marginLeft:15}}>
                        <Text style={{fontSize:17, color:Colors.Blue, fontWeight:"600"}}>{global.getText("addCreditCard")}</Text>
                        <Text style={{fontSize:12}}>{global.getText("recommendedPaymentMethod")}</Text>
                        </View>
                    </TouchableOpacity>

                    
                    
                </View>
            </View>
        </ScrollView> */}
        
        {API.user != undefined && API.user.business != undefined && <View  style={{backgroundColor:"white", paddingTop:20, paddingBottom:15, paddingLeft:"5%",paddingRight:"5%"}}>
          <Text style={{fontWeight:"600", fontSize:20}}>{global.getText("wallet")}</Text>

          <Text style={{textAlign:"center", fontWeight:"600",fontSize:30,marginTop:10}}>{API.user.business.credits.toFixed(2)}₺</Text>
          <Text style={{color:"gray", textAlign:"center",fontWeight:"500"}}>{global.getText("balance")}</Text>


          <TouchableOpacity onPress={() => {
            this.setState({selectedCard: undefined, selectedTopup: undefined});
            this.walletTopupModal.open();
              
          }} style={{flexDirection:"row", alignItems:"center", marginTop:25}}>
              <IonIcon color={Colors.Blue}  name='add' size={25}/>
              <View style={{marginLeft:15}}>
              <Text style={{fontSize:17, color:Colors.Blue, fontWeight:"600"}}>{global.getText("topupWallet")}</Text>
              </View>
          </TouchableOpacity>
        </View>}

        {API.user != undefined && <SwipeListView rightOpenValue={-120} disableRightSwipe renderHiddenItem={ ({item,index}) => {
            if(index < 1 || index > API.user.cards.length) return <></>
            return <View style={{backgroundColor:"red",height:"100%",alignItems:"flex-end",marginTop:0}}>
                <TouchableOpacity  onPress={() => {
                    global.showLoadingPanel();
                    
                    API.fetch("deletecard", {_id: item._id}).then((v) => {
                        if(!v.error) {
                            API.user.cards.splice(index-1, 1);
                            this.forceUpdate();
                            global.hideLoadingPanel();
                            this.forceUpdate();
                        }
                    })
                }} style={{backgroundColor:"red", paddingLeft:10, paddingRight:10,width:120,marginTop:0,height:"100%",alignItems:"center",justifyContent:"center"}}><Text style={{color:"white",textAlign:"center",fontSize:16, fontWeight:"600"}}>{global.getText("remove")}</Text></TouchableOpacity>
            </View>
            }
        } keyExtractor={(item, i) => i} data={[{disableLeftSwipe: true},...API.user.cards,{disableLeftSwipe:true},{disableLeftSwipe: true}]} renderItem={({item,index}) => {
          
          if(index == 0)
          return <View  style={{backgroundColor:"white", paddingTop:20,marginTop:10, paddingBottom:20, paddingLeft:"5%"}}>
          <Text style={{fontWeight:"600", fontSize:20}}>{global.getText("savedCards")}</Text>
          </View>

          if(index >= API.user.cards.length+2) {
            return <View pointerEvents='none' style={{marginLeft:"5%", marginTop:"5%", }}><ParamLogo /></View>
          }

          if(index > API.user.cards.length)
          return <View  style={{backgroundColor:"white", paddingLeft:"5%", marginTop:0, paddingTop:10, paddingBottom:10}}>
          <TouchableOpacity onPress={() => {
            this.addCreditCardModal.open();
              
          }} style={{flexDirection:"row", alignItems:"center"}}>
              <IonIcon color={Colors.Blue}  name='add' size={25}/>
              <View style={{marginLeft:15}}>
              <Text style={{fontSize:17, color:Colors.Blue, fontWeight:"600"}}>{global.getText("addCreditCard")}</Text>
              {/* <Text style={{fontSize:12}}>{global.getText("recommendedPaymentMethod")}</Text> */}
              </View>
          </TouchableOpacity>
          </View> 
          
          return <SavedCard margin={0} style={{backgroundColor:"white",alignItems:"center",height:50, paddingLeft:"5%", paddingRight:"5%"}} card={item} />
      
      
        }} />}


        

        <CustomModal scrollView={false} title={global.getText("topupWallet")} ref={(a) => {this.walletTopupModal = a;}}>
            <View style={{display:"flex", justifyContent:"space-between",flex:1}}>
            <View>
            <View  style={{backgroundColor:"white", paddingTop:20, paddingLeft:"5%",paddingRight:"5%"}}>
                <Text style={{fontWeight:"600", fontSize:17,marginBottom:20}}>{global.getText("topupAmount")}</Text>

                {TOPUP_AMOUNTS.map((amount, i) => <TopupWalletPackages onPress={() => this.setState({selectedTopup: i})} selected={this.state.selectedTopup == i} amount={amount} key={i} />)}
                
            </View>


            {API.user != undefined && <SwipeListView rightOpenValue={-120} disableRightSwipe renderHiddenItem={ ({item,index}) => {
                
            if(index < 1 || index > API.user.cards.length) return <></>
            return <View style={{backgroundColor:"red",height:"100%",alignItems:"flex-end",marginTop:0}}>
                <TouchableOpacity  onPress={() => {
                    
                    global.showLoadingPanel();
                    
                    API.fetch("deletecard", {_id: item._id}).then((v) => {
                        if(!v.error) {
                            API.user.cards.splice(index-1, 1);
                            this.forceUpdate();
                            global.hideLoadingPanel();
                            this.forceUpdate();
                        }
                    })
                }} style={{backgroundColor:"red", paddingLeft:10, paddingRight:10,width:120,marginTop:0,height:"100%",alignItems:"center",justifyContent:"center"}}><Text style={{color:"white",textAlign:"center",fontSize:16, fontWeight:"600"}}>{global.getText("remove")}</Text></TouchableOpacity>
            </View>
            }
        } keyExtractor={(item, i) => i} data={[{disableLeftSwipe: true},...API.user.cards,{disableLeftSwipe:true}]} renderItem={({item,index}) => {
          
          if(index == 0)
          return <View  style={{backgroundColor:"white", paddingTop:20,marginTop:10, paddingBottom:20, paddingLeft:"5%"}}>
          <Text style={{fontWeight:"600", fontSize:17}}>{global.getText("paymentOption")}</Text>
          </View>

          

          if(index > API.user.cards.length)
          return <View  style={{backgroundColor:"white", paddingLeft:"5%", marginTop:0, paddingTop:10, paddingBottom:10}}>
          <TouchableOpacity onPress={() => {
            this.addCreditCardModal.open();
              
          }} style={{flexDirection:"row", alignItems:"center"}}>
              <IonIcon color={Colors.Blue}  name='add' size={25}/>
              <View style={{marginLeft:15}}>
              <Text style={{fontSize:17, color:Colors.Blue, fontWeight:"600"}}>{global.getText("addCreditCard")}</Text>
              {/* <Text style={{fontSize:12}}>{global.getText("recommendedPaymentMethod")}</Text> */}
              </View>
          </TouchableOpacity>
          </View> 
          
          return <SavedCard onPress={() => {
            this.setState({selectedCard: item._id})
          }} selected={this.state.selectedCard == item._id} margin={0} style={{backgroundColor:"white",alignItems:"center",height:50, paddingLeft:"5%", paddingRight:"5%"}} card={item} />
      
      
        }} />}
        </View>


        <View style={{backgroundColor:"white",paddingBottom:Platform.OS == 'ios'?40:30,padding:"5%"}}>
      

               
                <TouchableOpacity disabled={!canTopup} onPress={() => {
                    // global.showCVCModal((code) => {
                    //     global.showLoadingPanel();
                        
                        
                    // })

                    global.startPayment("topup", {paymentDetails: {}, paymentMethod: this.state.selectedCard,package: this.state.selectedTopup}, (result) => {
                        console.log(result);
                        if(!result.error) {
                            this.walletTopupModal.close();
                            global.hideLoadingPanel();

                            API.user.business.credits = result.credits;
                            this.forceUpdate();

                        }
                    })
                }} style={{borderRadius:100,paddingTop:12, paddingBottom:12, backgroundColor:canTopup?Colors.Blue:"#9EA5A7"}}>
                <Text style={{fontWeight:"600", textAlign:"center",  color:canTopup?"white":"#D9DBDC",fontSize:16}}>{global.getText("topup")}</Text>
                </TouchableOpacity>



        </View>
        </View>
        </CustomModal>


        <AddCardModal onAdd={() => {
          this.forceUpdate()
        }} ref={(a) => this.addCreditCardModal = a} />
        
    </CustomModal>
    }
}

const TopupWalletPackages = (props) => <TouchableOpacity onPress={() => props.onPress()} style={{padding:15,paddingLeft:0,paddingRight:0, backgroundColor:"white",flexDirection:"row", justifyContent:"space-between",alignItems:"center"}}>
    <Text style={{fontWeight:"500", fontSize:16}}>{props.amount.toFixed(2)}₺</Text>
    {props.selected && <AntIcon name='checkcircle' color={Colors.Blue} size={20} style={{marginLeft:10}} />}
</TouchableOpacity>