import React from 'react';

import {View,  Text, TouchableOpacity} from 'react-native';
import IonIcon from '@expo/vector-icons/Ionicons';
import MaterialCommunityIcon from '@expo/vector-icons/MaterialCommunityIcons';

export default class SidebarButton extends React.Component {
    render() {
        return <TouchableOpacity onPress={() => this.props.onPress()} style={{display:"flex", flexDirection:"row", alignItems:"center", marginTop:20, paddingTop:3, paddingBottom:3}}>
            {this.props.icon != '' && <IonIcon name={this.props.icon} size={30} />}
            {this.props.icon == '' && <MaterialCommunityIcon  name={'face-agent'} size={30} />}
            <Text style={{fontSize:17, marginLeft:20}}>{this.props.title}</Text>
        </TouchableOpacity>
    }
}