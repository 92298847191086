import React from 'react';
import {View, Text, TouchableOpacity} from 'react-native';
import OrderReviewDestination from './OrderReviewDestination';
import API from '../API';
import Utils from '../Utils';

var colors = {};
colors[-1] = "#F45060";
colors[0] = "#FFF66D";
colors[1] = "#73E58C";
colors[2] = "#73E58C";
colors[10] = "#7A7C7E";

export default class OrderListElement extends React.Component {
    render() {
        return <TouchableOpacity onPress={() => this.props.onPress()} style={{paddingLeft:15, marginLeft:"5%", marginRight:"5%", paddingRight:15, paddingTop:10, marginTop:(this.props.first)?15:0, paddingBottom:10, marginBottom:15, backgroundColor:"white", borderRadius:10,shadowColor: "#000",
        shadowOffset: {
            width: 0,
            height: 1,
        },
        shadowOpacity: 0.20,
        shadowRadius: 1.41,
        
        elevation: 2}}>

{this.props.order.notification && <View style={{width:15,height:15, backgroundColor:"red", borderRadius:100, position:"absolute", right:-2, top:-2}}></View>}

            <View style={{display:"flex", flexDirection:"row", justifyContent:"space-between"}}>
                <View>
                    <Text style={{fontWeight:"600", fontSize:16}}>{Utils.getDateString(new Date(this.props.order.date))}</Text>
                    <View style={{flexDirection:"row", alignItems:"center"}}>
                        <Text style={{color:"#7A7C7E"}}>{global.getText("orderStatusTexts")[this.props.order.status+""]}</Text>
                        <View style={{width:12,height:12, marginLeft:5, borderRadius:50, backgroundColor:colors[this.props.order.status]}}></View>    
                    </View>
                </View>


                <View>
                    <Text style={{textAlign:"right", color:"#7A7C7E"}}>{Utils.getVehicleName(this.props.order.vehicle)}</Text>
                <Text style={{textAlign:"right", color:"#7A7C7E"}}>{API.getOrderTotal(this.props.order)}₺</Text>
                </View>
            </View>

            <View>
                <OrderReviewDestination selected={this.props.order.from} first/>
                {this.props.order.waypoints.map((waypoint, i) => <OrderReviewDestination selected={waypoint} key={i} />)}
                <OrderReviewDestination selected={this.props.order.to} />
            </View>
            
        </TouchableOpacity>
    }
}