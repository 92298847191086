import React, {
  Children,
  cloneElement,
  forwardRef,
  memo,
  useEffect,
  useImperativeHandle,
  useLayoutEffect,
  useRef,
  useState
} from "react"
import * as Leaflet from "leaflet"
import { View } from "react-native"
import {
  isValidZoom,
  latLngToLeafletLatLng,
  leafletCoordToLatLng
} from "./util"

const MapView = forwardRef(
  (
    {
      style,
      region,
      initialRegion,
      maxZoomLevel,
      minZoomLevel,
      children,
      onMapReady,
      onRegionChange,
      onRegionChangeComplete,
      onPanDrag,
      defaultZoomLevel
    },
    ref
  ) => {
    const mapContainerRef = useRef(null)

    const [map, setMap] = useState()

    const [center, setCenter] = useState({
      latitude: region?.latitude ?? initialRegion.latitude,
      longitude: region?.longitude ?? initialRegion.longitude
    })

    useImperativeHandle(
      ref,
      () => ({
        getMap: () => {
          return map
        },

        getMapBoundaries: async () => {
          if (!map) {
            return null
          }

          const bounds = map.getBounds()

          return {
            northEast: leafletCoordToLatLng(bounds.getNorthEast()),
            southWest: leafletCoordToLatLng(bounds.getSouthWest())
          }
        },

        getCamera: async () => {
          if (!map) {
            return null
          }

          return {
            center: leafletCoordToLatLng(map.getCenter()),
            heading: 0,
            pitch: 0,
            altitude: 0,
            zoom: map.getZoom()
          }
        },

        setCamera: async camera => {
          if (map) {
            if (camera.center) {
              const mapCenter = map.getCenter()

              if (
                camera.center.latitude !== mapCenter.lat ||
                camera.center.longitude !== mapCenter.lng
              ) {
                map.setView(
                  latLngToLeafletLatLng(camera.center),
                  camera.zoom >= 0
                    ? isValidZoom(maxZoomLevel) && camera.zoom > maxZoomLevel
                      ? maxZoomLevel
                      : isValidZoom(minZoomLevel) && camera.zoom < minZoomLevel
                      ? minZoomLevel
                      : camera.zoom
                    : map.getZoom(),
                  {
                    duration: 0
                  }
                )
              }
            } else if (camera.zoom !== map.getZoom()) {
              map.setZoom(
                isValidZoom(maxZoomLevel) && camera.zoom > maxZoomLevel
                  ? maxZoomLevel
                  : isValidZoom(minZoomLevel) && camera.zoom < minZoomLevel
                  ? minZoomLevel
                  : camera.zoom
              )
            }
          }
        },

        fitBounds: async (a,b) => {map.fitBounds(a,b)},
        flyToBounds: async(a,b) => {map.flyToBounds(a,b)},

        animateCamera: async camera => {
          if (map) {
            if (camera.center) {
              const mapCenter = map.getCenter()
              console.log(maxZoomLevel, minZoomLevel)

              if (
                camera.center.latitude !== mapCenter.lat ||
                camera.center.longitude !== mapCenter.lng
              ) {
                map.flyTo(
                  latLngToLeafletLatLng(camera.center),
                  camera.zoom >= 0
                    ? isValidZoom(maxZoomLevel) && camera.zoom > maxZoomLevel
                      ? maxZoomLevel
                      : isValidZoom(minZoomLevel) && camera.zoom < minZoomLevel
                      ? minZoomLevel
                      : camera.zoom
                    : map.getZoom(),
                  {
                    duration: 1
                  }
                )
              }
            } else if (camera.zoom !== map.getZoom()) {
              map.setZoom(
                isValidZoom(maxZoomLevel) && camera.zoom > maxZoomLevel
                  ? maxZoomLevel
                  : isValidZoom(minZoomLevel) && camera.zoom < minZoomLevel
                  ? minZoomLevel
                  : camera.zoom
              )
            }
          }
        }
      }),
      [map]
    )

    useEffect(() => {
      if (
        region &&
        (region.latitude !== center.latitude ||
          region.longitude !== center.longitude)
      ) {
        setCenter({
          latitude: region.latitude,
          longitude: region.longitude
        })

        if (map) {
          map.panTo(latLngToLeafletLatLng(region))
        }
      }
    }, [region])

    useLayoutEffect(() => {
      if (mapContainerRef.current && !map) {
        //const defaultZoom = 16

        //const defaultZoom = 10
        const defaultZoom = defaultZoomLevel || 16;

       

        setMap(
          new Leaflet.Map(mapContainerRef.current, {
            layers: [
              Leaflet.tileLayer("https://api.mapbox.com/styles/v1/mapbox/streets-v11/tiles/{z}/{x}/{y}?access_token=pk.eyJ1IjoiYmVoYXVsYXMiLCJhIjoiY2w5aDdxazRsMHpmdzN3bzBncWY0cmdrYyJ9.yf8dmfnACJpkIGSd_NVeFw", {
                
              })
            ],
            center: latLngToLeafletLatLng(center),
            zoom:
              maxZoomLevel && defaultZoom > maxZoomLevel
                ? maxZoomLevel
                : minZoomLevel && defaultZoom < minZoomLevel
                ? minZoomLevel
                : defaultZoom,
            minZoom: minZoomLevel,
            maxZoom: maxZoomLevel,
            zoomControl: false
          })
        )

        
      }
    }, [mapContainerRef.current, map, center, maxZoomLevel, minZoomLevel])

    useEffect(() => {
      if (map) {
        const handleOnLoad = () => {
          if (onMapReady) {
            onMapReady()
          }
        }

        const handleOnDragOrMove = event => {
          const center = leafletCoordToLatLng(map.getCenter())

          if (onPanDrag) {
            onPanDrag({
              ...event,
              coordinate: center
            })
          }

          if (onRegionChange) {
            onRegionChange({
              ...center,
              latitudeDelta: 0,
              longitudeDelta: 0
            })
          }
        }

        const handleOnDragOrMoveEnd = () => {
          const center = leafletCoordToLatLng(map.getCenter())

          if (onRegionChangeComplete) {
            onRegionChangeComplete({
              ...center,
              latitudeDelta: 0,
              longitudeDelta: 0
            })
          }
        }

        map.addEventListener("load", handleOnLoad)

        map.addEventListener("drag", handleOnDragOrMove)
        map.addEventListener("move", handleOnDragOrMove)
        map.addEventListener("dragend", handleOnDragOrMoveEnd)
        map.addEventListener("moveend", handleOnDragOrMoveEnd)

        return () => {
          map.removeEventListener("load", handleOnLoad)
          map.removeEventListener("drag", handleOnDragOrMove)
          map.removeEventListener("move", handleOnDragOrMove)
          map.removeEventListener("dragend", handleOnDragOrMoveEnd)
          map.removeEventListener("moveend", handleOnDragOrMoveEnd)
        }
      }

      return () => {}
    }, [map, onMapReady, onPanDrag, onRegionChange, onRegionChangeComplete])

    useEffect(() => {
      return () => {
        if (map) {
          map.remove()
          setMap(undefined)
        }
      }
    }, [map])

    return (
      <View
        style={
          style ?? {
            height: "100%"
          }
        }
      >
        <div ref={mapContainerRef} style={{ flex: 1 }}>
          {Children.map(children, child =>
            cloneElement(child, {
              map
            })
          )}
        </div>
      </View>
    )
  }
)

export default memo(MapView)

export { default as Marker } from "./Marker"
