import { LatLng as LeafletLatLng } from 'leaflet';

export const isValidZoom = (zoom) => {
  return zoom !== undefined && zoom !== null && !isNaN(zoom)
}

export const leafletCoordToLatLng = (coord) => ({
  latitude: coord.lat,
  longitude: coord.lng
})

export const latLngToLeafletLatLng = (coord) => new LeafletLatLng(coord.latitude, coord.longitude)
