import React from 'react';
import {View, Text, Image, TouchableOpacity, Dimensions, TextInput, Platform} from 'react-native';
import CustomModal from '../CustomModal';
import IonIcon from '@expo/vector-icons/Ionicons';
import FontistoIcon from '@expo/vector-icons/Fontisto';
import Colors from '../Colors';
const LiteCreditCardInput = Platform.select({
    web: () => <View></View>,
    default: () => require('react-native-credit-card-input').LiteCreditCardInput,
  })();

import API from '../API';

export default class AddCardModal extends React.Component {

    constructor() {
        super();
        this.state = {creditCardOwner:"", creditCardName: ""}
    }

    open() {
        this.addCreditCardModal.open();
        this.setState({creditCardOwner: "", creditCardName: ""})
    }

    close() {
        this.addCreditCardModal.close();
    }

    render() {
        return <CustomModal headerShown={false} ref={(a) => this.addCreditCardModal = a} scrollView={false} animationType='slide'>

        <View style={{backgroundColor:"#F2F4F3", flex:1}}>
            <View style={{height:90, marginBottom:20, backgroundColor:"white", width:"100%", justifyContent:"flex-end", display:"flex" ,alignItems:"center"}}>
                <Text style={{fontWeight:"700", bottom:14, fontSize:17, position:"absolute"}}>{global.getText("addCreditCard")}</Text>
                <TouchableOpacity onPress={() => this.addCreditCardModal.close()} style={{position:"absolute", bottom:10, left:"5%"}}>
                <IonIcon name='close' size={30} />
                </TouchableOpacity>

                <TouchableOpacity onPress={() => {
                    
                    if(!this.newCardDetails.valid || this.state.creditCardOwner.trim().length <= 1) return;

                    global.showLoadingPanel();
                    
                    API.fetch("savecard", {cvc: this.newCardDetails.values.cvc,expiry: this.newCardDetails.values.expiry,number: this.newCardDetails.values.number,owner:this.state.creditCardOwner, name: this.state.creditCardName}).then((e) => {
                      
                      
                      if(!e.error) {
                        API.user.cards.push(e);
                        
                        global.hideLoadingPanel();
                        this.addCreditCardModal.close()

                        this.props.onAdd();
                      }
                    })
                    // this.state.savedCards.push({type: capitalizeFirstLetter(this.newCardDetails.values.type), number: this.newCardDetails.values.number.split(" ")[this.newCardDetails.values.number.split(" ").length-1]});

                    // this.setState({creditCardModalVisible: false})

                    // setTimeout(() => {
                    //     this.setState({paymentMethodOpened: false});
                    // }, 20);
                    
                    }} style={{position:"absolute", bottom:15, right:"5%"}}>
                    <Text style={{fontSize:16, fontWeight:"600"}}>{global.getText("save")}</Text>

                </TouchableOpacity>
            </View>
            <View style={{paddingLeft:"3%", paddingRight:"3%"}}>

                <FontistoIcon  name='credit-card' size={Dimensions.get('window').width/3} style={{textAlign:"center", marginBottom:20}} color={Colors.LightBlue}/>
                

                <View style={{borderWidth:1,borderRadius:5, borderColor:"#BCBBBF",backgroundColor:"white"}}>
            <TextInput autoCapitalize='words' value={this.state.creditCardName} onChangeText={(e) => this.setState({creditCardName: e})} returnKeyType='next' onSubmitEditing={() => {this.creditCardOwnerTextInput.focus()}} autoFocus={true} placeholder={global.getText("saveCardName")} placeholderTextColor='gray' style={{paddingLeft:10,paddingRight:10, justifyContent:"center",height:40}} />
            </View>
                <View style={{borderWidth:1,borderRadius:5, borderColor:"#BCBBBF",backgroundColor:"white", marginTop:20}}>
            <TextInput autoCapitalize='words' ref={(s) => this.creditCardOwnerTextInput = s} value={this.state.creditCardOwner} onChangeText={(e) => this.setState({creditCardOwner: e})} returnKeyType='next' onSubmitEditing={() => {this.liteCreditCardInput.focus()}} placeholder={global.getText("cardOwner")} placeholderTextColor='gray' style={{paddingLeft:10,paddingRight:10, justifyContent:"center",height:40}} />
            </View>
            <View style={{borderWidth:1,borderRadius:5, borderColor:"#BCBBBF",backgroundColor:"white",marginTop:10}}>
            <LiteCreditCardInput ref={(a) => this.liteCreditCardInput = a} onChange={(a) => {
              this.newCardDetails = a
              
            }} cardFontFamily={''} />
            </View>
            
            </View>
        </View>
        
    </CustomModal>
    }
}