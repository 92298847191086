import React from 'react';
import { FlatList, ScrollView, Text, TouchableOpacity, View } from 'react-native';
import CustomModal from '../CustomModal';
import API from '../API';
import { Avatar } from 'react-native-elements';
import Stars from 'react-native-stars';
import Icon from '@expo/vector-icons/FontAwesome';
import Colors from '../Colors';
import { SwipeListView } from 'react-native-swipe-list-view';

export default class MyDriversModal extends React.Component {


    constructor() {
        super();

        this.state = {drivers: []}
    }

    open() {
        this.setState({drivers: []});
        this.mydriverModal.open();
        global.showLoadingPanel();

        API.fetch("getmydrivers").then((drivers) => {
            if(!drivers.error) {
                this.setState({drivers});
            }
            global.hideLoadingPanel();
        })
        
    }
    close() {
        this.mydriverModal.close();
    } 
    render() {
        return <CustomModal scrollView={false} title={global.getText("myDrivers")} ref={(a) => this.mydriverModal = a}>
            
        

        

        <SwipeListView
        disableRightSwipe
            data={this.state.drivers}
            renderItem={ ({item}) => (
                <View style={{backgroundColor:"white",paddingTop:10, paddingBottom:10, paddingLeft:"5%", paddingRight:"5%",flexDirection:"row", marginTop:10, justifyContent:"space-between"}}>
                    <View style={{flexDirection:"row"}}>
                    <Avatar imageProps={{resizeMode:"cover"}} source={{uri: 'https://tasimacim-driver-pictures.s3.eu-central-1.amazonaws.com/'+item._id+'.jpeg'}}  size={45} rounded title={"D"} containerStyle={{backgroundColor:"#FEB600"}} />
            <View style={{marginLeft:10}}> 
            <Text style={{fontSize:16}}>{item.name}</Text>
            <Stars
    display={(item.review)?item.review:0}
    spacing={8}
    count={5}
    starSize={40}
    fullStar= {<Icon name='star' size={18} color={Colors.Blue} />}
    emptyStar= {<Icon name='star'  size={18} color={Colors.Gray} />}/>
            </View> 
                </View>

                <View style={{alignItems:"center", flexDirection:"row"}}>
                    <View style={{width:6,height:6,borderRadius:100, backgroundColor: (item.online)?"green":"gray"}}></View>
                    <Text style={{color: (item.online)?"green":"gray",fontSize:16, marginLeft:5}}>{global.getText("driverStatus")[item.online?1:0]}</Text>
                </View>
                </View>
            )}
            renderHiddenItem={ ({item,index}) => (
                <View style={{backgroundColor:"red",height:"100%",alignItems:"flex-end",marginTop:10}}>
                    <TouchableOpacity onPress={() => {
                        global.showLoadingPanel();
                        API.fetch("deletedriver", {_id: item._id}).then((v) => {
                            if(!v.error) {
                                this.state.drivers.splice(index,1);
                                this.forceUpdate();
                                global.hideLoadingPanel();
                                
                            }
                        })
                    }} style={{backgroundColor:"red", paddingLeft:10, paddingRight:10,width:120,marginTop:0,paddingBottom:10,height:"100%",alignItems:"center",justifyContent:"center"}}><Text style={{color:"white",textAlign:"center",fontSize:16, fontWeight:"600"}}>{global.getText("remove")}</Text></TouchableOpacity>
                </View>
            )}
            keyExtractor={(item, i) => i}
            rightOpenValue={-120}
        />
        
    </CustomModal>
    }


}