import * as React from "react"
import Svg, { Defs, Path, ClipPath, Use } from "react-native-svg"

function SvgComponent(props) {
  return (
    <Svg
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
      x="0px"
      y="0px"
      width="104.908px"
      height="20.333px"
      viewBox="0 0 504.908 85.333"
      xmlSpace="preserve"
      {...props}
    >
      <Defs>
        <Path
          id="a"
          d="M-38.194 -366.557H557.082V475.33299999999997H-38.194z"
        />
      </Defs>
      <ClipPath id="b">
        <Use xlinkHref="#a" overflow="visible" />
      </ClipPath>
      <Path
        clipPath="url(#b)"
        fill="gray"
        d="M358.827 56.131h-19.14l9.452-22.01 9.688 22.01zm11.841 28.831h20.936v-3.11L353.8.144h-9.207l-37.685 81.708v3.11h20.938l4.905-10.527h33.017l4.9 10.527zM36.724 44.289H21.411V20.358c4.904 0 10.29-.113 15.313 0 12.444.127 11.728 23.931 0 23.931M10.213 84.962l11.198-21.415h15.436c40.069 0 40.069-62.206 0-62.324H0v83.739h10.213zM140.16 56.131h-19.14l9.452-22.01 9.688 22.01zm11.845 28.831h20.931v-3.11L135.133.144h-9.208l-37.68 81.708v3.11h20.935l4.9-10.527h33.021l4.904 10.527zm89.252-43.665h-15.309V20.358c5.028 0 10.29-.113 15.309 0 12.203.127 11.591 20.939 0 20.939m17.707 13.999c21.171-14.357 15.191-53.955-17.707-54.073h-36.72v83.739h10.213l11.198-24.643h11.125l14.357 24.643h23.922v-3.11l-16.388-26.556zm204.909-17.947L431.097.983H423.2v83.979h21.892V47.64l17.706 18.659h2.395l17.82-18.659v37.322h21.896V.983h-7.657l-33.379 36.366z"
      />
    </Svg>
  )
}

export default SvgComponent
