import React from 'react';
import { View } from 'react-native';
import { FloatingLabelInput } from 'react-native-floating-label-input';

export default class AddressInput extends React.Component {
    focus() {
        this.text.focus();
    }
    render() {
        return <View  style={(this.props.ff)?{display:"flex", flex:1, marginLeft: (this.props.margin)?10:0, marginTop:(!this.props.ff)?10:0}:{}}><FloatingLabelInput ref={(a) => this.text = a} returnKeyType={this.props.last?'done':'next'} onSubmit={() => 
            {
               
                this.props.onSubmit()

            }
        } autoCorrect={false} onFocus={() => this.props.onFocus()} keyboardType={this.props.keyboardType != undefined?'number-pad':"default"} maxLength={this.props.keyboardType != undefined?10:100}  customLabelStyles={{colorBlurred:"gray",fontSizeBlurred:16, fontSizeFocused:14,colorFocused:"gray"}} inputStyles={{outline: "none", borderWidth: 0}} onChangeText={(t) => this.props.onChange(t)}containerStyles={{flexDirection: 'row',
        borderColor: (this.props.error)?'red':'#F3F3F3',
        borderBottomWidth:1.5,
        backgroundColor: 'white',
        paddingTop: 5,
        paddingBottom: 5,
        alignContent: 'center',
        justifyContent: 'center',
        
        marginTop:20}}  editable={!this.props.disabled} label={this.props.label} value={this.props.value} /></View>
    }
}