import React from 'react';
import {View, Animated, TouchableOpacity, Text, ScrollView, Keyboard, Dimensions} from 'react-native';
import IonIcon from '@expo/vector-icons/Ionicons';

export default class CustomHorizontalModal extends React.Component {


    constructor() {
        super();


        this.state = {pos: new Animated.Value(1), opened: false};
    }

    open() {
        
        Keyboard.dismiss();

        Animated.timing(this.state.pos, {toValue: 0, duration:250, useNativeDriver: true}).start();
        
        this.setState({opened: true});
    }   

    close() {
        Keyboard.dismiss();

        Animated.timing(this.state.pos, {toValue: 1, duration:250, useNativeDriver: true}).start();
        //this.setState({opened: true});
        this.forceUpdate();
        setTimeout(() => {
            this.setState({opened: false});
        }, 250);
    }


    render() {
        return <View style={{position:"absolute", left: 0, right: 0, top: 0, bottom: 0, zIndex: 1000}} pointerEvents={(this.state.opened)?'auto':'none'}>
            {this.state.opened && <Animated.View style={{position:"absolute", width: "100%", height:"100%", backgroundColor:"black", transform: [{translateX: this.state.pos.interpolate({inputRange: [0,1], outputRange: [0,Dimensions.get('screen').width]})}], zIndex:1001}}>
                
               <View style={{backgroundColor:"#F2F4F3", flex:1}}>
                    <View style={{height:90, backgroundColor:"white", width:"100%", justifyContent:"flex-end", display:"flex" ,alignItems:"center"}}>
                        <Text style={{fontWeight:"700", bottom:14, fontSize:17, position:"absolute"}}>{this.props.title}</Text>
                        <TouchableOpacity onPress={() => /*this.setState({ordersOpened: false}) this.yourOrdersModal.close()*/ this.close()} style={{position:"absolute", bottom:10, left:"5%"}}>
                            <IonIcon name='chevron-back' size={30} />
                        </TouchableOpacity>
                    </View>
        
                   

                    {this.props.children}
                </View>

                
                
            </Animated.View>}
        </View>
    }
}
