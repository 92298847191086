import React from 'react';
import {View, Text, Image,TouchableOpacity} from 'react-native';
import Colors from '../Colors';
import IonIcon from '@expo/vector-icons/Ionicons';
import DemandIcon from './DemandIcon';

export default class VehicleSelection extends React.Component {


    render() {
        // return <TouchableOpacity disabled={this.props.disabled} onPress={() => this.props.onClick()} style={{backgroundColor:"white", borderRadius:15, shadowColor: "#000",
        // shadowOffset: {
        //     width: 0,
        //     height: 1,
        // },
        // shadowOpacity: 0.18,
        // shadowRadius: 1.00,
        // elevation: 1, display:"flex", flexDirection:"row",  justifyContent:"space-between", paddingTop:10, paddingBottom:10, marginTop:10, borderWidth:(this.props.selected?3:0), borderColor:Colors.Blue}}>

        //     <View style={{display:"flex", flexDirection:"row", alignItems:"center",marginTop:5,marginBottom:5}}>
        //     <Image style={{width:100, marginLeft:"5%", marginRight:10,resizeMode:"contain",height:"100%",marginTop:10, marginBottom:10}} source={this.props.img} />

        //     <View style={{display:"flex"}}>
        //         <Text style={{fontWeight:"700", fontSize:20}}>{this.props.name}</Text>
        //         <Text style={{color:"#86888B", fontSize:13, marginTop:5}}>{global.getText("vehicleInfoStorage")}: {this.props.storage}</Text>
        //         <Text style={{color:"#86888B", fontSize:13, marginTop:5}}>{global.getText("vehicleInfoMaxLoad")}: {this.props.load}</Text>
        //     </View>
        //     </View>


        //     <View style={{display:"flex", justifyContent:"space-between", alignItems:"flex-end"}}>
        //     <View style={{flexDirection:"row",alignItems:"center"}}>{this.props.traffic && <DemandIcon />}

        //     {(this.props.discount == undefined || this.props.discount <= 0) && <Text style={{marginRight:10, marginTop:0, fontWeight:"700", fontSize:20}}>{this.props.price}₺</Text>}
        //     {this.props.discount > 0 && <View style={{alignItems:"flex-end"}}>
        //     <Text style={{marginRight:10, marginTop:0,textDecorationColor:"red",textDecorationLine:"line-through", textDecorationStyle:"solid",color:"crimson", fontWeight:"700", fontSize:16}}>{this.props.price}₺</Text>
        //     <Text style={{marginRight:10, marginTop:0, fontWeight:"700", fontSize:20}}>{Math.max(parseInt(this.props.price-this.props.discount),0)}₺</Text>
        //     </View>}
            
        //     </View>

        //     {/* {this.props.selected && <IonIcon name='checkmark-circle' color={"#FFD905"} size={40} style={{marginBottom:-10}}/>} */}
        //     </View>
            
        //     {this.props.disabled && <View style={{position:"absolute", left:0,right:0,top:0,bottom:0, backgroundColor:"rgba(200,200,200,.6)",borderRadius:15, justifyContent:"center"}}><Text style={{textAlign:"center",color:"crimson",fontWeight:"bold",fontSize:16}}>{global.getText("motorcycleDisabled")}</Text></View>}
        // </TouchableOpacity>
        return <TouchableOpacity onPress={() => this.props.onClick()} style={{backgroundColor:"white",flex:1, borderRadius:15, shadowColor: "#000",
        shadowOffset: {
            width: 0,
            height: 1,
        },
        shadowOpacity: 0.18,
        shadowRadius: 1.00,
        elevation: 1, display:"flex", flexDirection:"row",  justifyContent:"center",marginTop:(this.props.indx > 2)?10:0, marginLeft:(this.props.indx==2 || this.props.indx == 5)?10:0, paddingTop:10, paddingBottom:10}}>
             {this.props.traffic && <View style={{position:"absolute", top:10, right:10}}><DemandIcon /></View>}
            <View style={{display:"flex", flexDirection:"column", alignItems:"center"}}>
                <View style={{width:"100%", aspectRatio:2, paddingLeft:25,paddingRight:25, justifyContent:"center", alignItems:"center",marginTop:0}}>
            <Image style={{height:"100%", width:"90%",resizeMode:"contain", position:"absolute"}} source={this.props.img} />
            </View>
            <View style={{display:"flex", alignItems:"center"}}>
                <Text style={{fontWeight:"700", fontSize:20}}>{this.props.name}</Text>
                {/* <Text style={{color:"#86888B", fontSize:13, marginTop:5}}>{global.getText("vehicleInfoStorage")}: {this.props.storage}</Text>
                <Text style={{color:"#86888B", fontSize:13, marginTop:5}}>{global.getText("vehicleInfoMaxLoad")}: {this.props.load}</Text> */}
                <Text style={{color:"black",fontWeight:"bold", fontSize:20, marginTop:5}}>{this.props.price}₺</Text>
            </View>
            </View>

            
            {/* <View style={{display:"flex", justifyContent:"space-between", alignItems:"flex-end"}}>
            <Text style={{fontWeight:"700", fontSize:20,position:"absolute", right:10,top:0}}>{(Math.random()*150).toFixed()}₺</Text>
            </View> */}

            <View style={{position:"absolute", left:0,right:0,top:0,bottom:0,borderRadius:15, borderWidth:(this.props.selected?3:0), borderColor:Colors.Blue}}>

            </View>
        </TouchableOpacity>
    }
}