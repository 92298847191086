import React from 'react';
import { TouchableOpacity, View, Animated, Text } from 'react-native';
import IonIcon from '@expo/vector-icons/Ionicons';
import Colors from '../Colors';

export default class Popup extends React.Component {

    constructor() {
        super();
        this.state = {};
    }

    showPopup(text) {
        this.setState({popupText: text});
    }

    render() {
        return <>{this.state.popupText != undefined && <Animated.View style={{position:"absolute", left:0, right:0, top:0, bottom:0, backgroundColor:"rgba(0,0,0,.4)",opacity: this.popupAnimation, justifyContent:"center", alignItems:"center",zIndex:1000}}>
        <View style={{width:"80%", backgroundColor:"white", shadowColor: "#000",
shadowOffset: {
width: 0,
height: 2,
},
shadowOpacity: 0.25,
shadowRadius: 3.84,

elevation: 5, padding:20, alignItems:"center",borderRadius:10}}>

<TouchableOpacity onPress={() => {
    Animated.timing(this.popupAnimation, {useNativeDriver:true, toValue:0, duration:100}).start();
    setTimeout(() => {
        this.setState({popupText:undefined});
    }, 100);
}} style={{alignSelf:"flex-end",marginTop:-10, marginRight:-10}}><IonIcon name='close' size={23} /></TouchableOpacity>

<Text style={{textAlign:"center",fontWeight:"600", marginTop:5}}>{this.state.popupText}</Text>

<TouchableOpacity onPress={() => {
this.popupSuccessCallback();
Animated.timing(this.popupAnimation, {useNativeDriver:true, toValue:0, duration:100}).start();
setTimeout(() => {
    this.setState({popupText:undefined});
}, 100);
}} style={{backgroundColor:Colors.Blue, paddingTop:10, paddingBottom:10, marginTop:20,width:"100%", justifyContent:"center", alignItems:"center",borderRadius:50}}><Text style={{color:"white", fontSize:18, fontWeight:"600"}}>{global.getText("gotIt")}</Text></TouchableOpacity>
</View>
</Animated.View>}</>
    }
}