import React from 'react';
import {View, Animated, TouchableOpacity, Text, ScrollView, Keyboard} from 'react-native';
import IonIcon from '@expo/vector-icons/Ionicons';
import Constants from 'expo-constants';
export default class CustomModal extends React.Component {


    constructor() {
        super();


        this.state = {pos: new Animated.Value(300), opacity: new Animated.Value(0),opened: false};
    }

    isOpened() {
        return this.state.opened;
    }
    open() {
        
        Keyboard.dismiss();

        Animated.timing(this.state.pos, {toValue: 0, duration:250, useNativeDriver: true}).start();
        Animated.timing(this.state.opacity, {toValue: 1, duration:250, useNativeDriver: true}).start();
        this.setState({opened: true});
    }   

    close(instant) {
        Keyboard.dismiss();
        if(instant) {
            this.state.pos.setValue(300);
            this.state.opacity.setValue(0);
            this.setState({opened: false});
            return;
        }

        Animated.timing(this.state.pos, {toValue: 300, duration:250, useNativeDriver: true}).start();
        Animated.timing(this.state.opacity, {toValue: 0, duration:250, useNativeDriver: true}).start();
        //this.setState({opened: true});
        this.forceUpdate();
        setTimeout(() => {
            this.setState({opened: false});
        }, 250);
    }


    render() {
        return <View style={{position:"absolute", left: 0, right: 0, top: 0, bottom: 0, zIndex: 10000}} pointerEvents={(this.state.opened)?'auto':'none'}>
            
            {this.state.opened && <Animated.View style={{position:"absolute", width: "100%", height:"100%", backgroundColor:(this.props.containerBackground || "black"), opacity: this.state.opacity,transform: [{translateY: this.state.pos}], zIndex:1001}}>
                
                {(this.props.headerShown == undefined || this.props.headerShown == true) && <View style={{backgroundColor:(this.props.backgroundColor || "#F2F4F3"), flex:1}}>
                    <View style={{height:this.props.topBar != undefined?this.props.topBar:Constants.statusBarHeight+50, backgroundColor:"white", width:"100%", justifyContent:"flex-end", display:"flex" ,alignItems:"center"}}>
                        <Text style={{fontWeight:"700", bottom:14, fontSize:17, position:"absolute"}}>{this.props.title}</Text>
                        {(this.props.closeShown == undefined || this.props.closeShown == true ) && <TouchableOpacity onPress={() => {
                            
                            if(this.props.onClose) this.props.onClose();
                            /*this.setState({ordersOpened: false}) this.yourOrdersModal.close()*/ this.close()
                                       
                }} style={{position:"absolute", bottom:10, left:"5%"}}>
                            <IonIcon name='close' size={30} />
                        </TouchableOpacity>}
                    </View>
                    
                    {(this.props.scrollView == undefined || this.props.scrollView == true) && <ScrollView contentContainerStyle={(this.props.contentContainerStyle || {})} style={{flex:1}}>
                        {this.props.children}
                    </ScrollView>}

                    {this.props.scrollView == false && this.props.children}
                </View>}
                
                {(this.props.headerShown == false) && this.props.children}
                
            </Animated.View>}
        </View>
    }
}
