import React from 'react';
import {View, Text, TouchableOpacity, KeyboardAvoidingView, FlatList, TextInput,Animated, ScrollView, SafeAreaView, Platform, Share} from 'react-native';
import Colors from './Colors';
import EntypoIcon from '@expo/vector-icons/Entypo';
import MaterialCommunityIcon from '@expo/vector-icons/MaterialCommunityIcons';
import HandshakeSVG from './assets/deal.js';
import API from './API';
  
export default class ReferralScreen extends React.Component {

    constructor(props) {
        super(props);
        this.navigation = props.navigation;
        
        this.state = {coupon: "0000"};
    }


   componentDidMount() {
    API.fetch("getreferral", {}).then(coupon => {
        console.log(coupon);
        this.setState({coupon: coupon.coupon, loaded: true});
    })
   }

    componentWillUnmount() {
        //global.onFinializeAddressDone();
    }
    render() {
        
        return <View style={{flex:1,position:"relative", backgroundColor:"white"}}>

            
            <SafeAreaView  style={{flex:1}} behavior='padding' keyboardVerticalOffset={0}>
                        
                        
                <View style={{alignItems:"center", display:"flex", justifyContent:"center", paddingRight:13, paddingTop:13,paddingBottom:13,flexDirection:"row",borderRadius:6, width:"100%",backgroundColor:"white", borderBottomWidth:0, borderBottomColor:Colors.Blue}}>
                    <TouchableOpacity onPress={() => this.navigation.pop()} style={{marginRight:5, position:"absolute", left:10}} pressRetentionOffset={{top:10,bottom:10, left:10, right:10}}><EntypoIcon name='chevron-left' size={30} /></TouchableOpacity>
                    
                    <Text style={{fontWeight:"500", flex:1,fontSize:16,fontWeight:"600", textAlign:"center"}}>{global.getText("referallsTitle")}</Text>
                </View>

                <ScrollView style={{flex:1}}>

                <View style={{width:"100%", height:"50%", alignItems:"center"}}>
                    {/* <Image source={HandshakeSVG} /> */}
                    <HandshakeSVG />
                    </View>
                    <Text style={{fontSize:35, padding:"5%", fontWeight:"500", textAlign:"center"}}>{global.getText("referralsH1")}</Text>

                    

                    <Text style={{textAlign:"center", fontWeight:"600", fontSize:20}}>{global.getText("howItWorks")}</Text>

                    <Step first icon={"email-send-outline"} title={"sendInvite"} />
                    <Step icon={"package-variant-closed"} title={"needsOrder"}  />
                    <Step icon={"gift-outline"} title={"rewarded"}  />

                </ScrollView>

            </SafeAreaView>

            <View style={{position:"absolute", left:0, right:0,bottom:0}}>


            </View>

            {this.state.loaded && <View style={{paddingTop:20, paddingBottom:Platform.OS=='ios'?30:20, backgroundColor:"white", paddingLeft:"5%", paddingRight:"5%"}}><TouchableOpacity disabled={this.state.coupon == undefined} onPress={() => {
                        // this.state.errorValue[0] = (!this.address.building)
                        // this.state.errorValue[1] = (!this.address.floor)
                        // this.state.errorValue[2] = (!this.address.door);
                        // this.state.errorValue[3] = (this.address.elevator == undefined);

                        // if(this.state.errorValue[0] || this.state.errorValue[1] || this.state.errorValue[2] || this.state.errorValue[3]) {
                        //     this.forceUpdate();
                        //     return;
                        // }

                        Share.share({message: global.getText("inviteText").replace("0000", this.state.coupon)})
                    }} style={{paddingTop:15, paddingBottom:15, backgroundColor:Colors.Blue, justifyContent:"center", alignItems:"center", borderRadius:10}}><Text style={{fontWeight:"600", color:"white",fontSize:18}}>{global.getText(this.state.coupon != undefined?"inviteButton":"onlyOnce")}</Text></TouchableOpacity></View>}

                 


                
            

            {/* <KeyboardAccessoryNavigation previousDisabled={this.state.focusedInput <= 0} onNext={() => {
                this.state.focusedInput++;
                this.addressInputs[this.state.focusedInput].focus();
            }} 
            onPrevious={() => {
                this.state.focusedInput--;
                this.addressInputs[this.state.focusedInput].focus();
            }}
            nextDisabled={this.state.focusedInput >= 5} /> */}
        </View>
    }

    
}

const Step = (props) => <View style={{display:"flex", marginTop:props.first?20:30, flexDirection:"row", alignItems:"center", paddingLeft:"5%", paddingRight:"5%"}}>
    <View style={{width:30}}>
    <MaterialCommunityIcon color={Colors.Blue} size={25} name={props.icon} />
    </View>

    <View style={{display:"flex", marginLeft:"5%", flex:1}}>
    <Text style={{fontWeight:"600", fontSize:17}}>{global.getText(props.title)}</Text>
    <Text style={{color:"gray", fontWeight:"500", fontSize:16, marginTop:5}} >{global.getText(props.title + "Desc")}</Text>
    </View>
</View>