


/**
* @description
* Wants to select customer email address from native mobile application.
*/
export function selectEmail(){
    receiveMessage("openEmailSelection");
}

/**
* @description
* Wants to select customer address from native mobile application.
*/
export function selectAddress(){
    receiveMessage("openAddressSelection");
}

/**
* @description
* Wants to select customer phone number from native mobile application.
*/
export function selectPhoneNo(){
    receiveMessage("openPhoneNumberSelection");
}

/**
* @description
 * Wants to read qr code from native mobile application.
 */
export function readQRCode(){
    receiveMessage("openQRCodeReader");
}

/**
 * @description
 * Wants to start mobile payment flow from mobile native application.
 * @param {string} paymentToken merchant passes this token to start payment operation.
 */
export function startPayment(paymentToken){
    receiveMessageWithParameter("openPaymentModal", paymentToken);
}

/**
* @description
* Wants to start mobile payment flow from mobile native application
 * @param {string} orderNo merchant passes this token after transaction is completed.
*/
export function orderCompleted(orderNo){
    receiveMessageWithParameter("openOrderCompletedModal", orderNo);
}

/**
* @description
 * Wants to end to process from  native mobile application.
 */
export function processCompleted(){
    receiveMessage("completeProcess");
}

/**
* @description
* Opens IOS/Android JavaScript Interface in native mobile app.
*This method calls only from PassportYapiKrediMobile.js
* @param {string} operationName Specify which method calls from merchant web app.
*/
export function receiveMessage(operationName){
    if(window.webkit != undefined){
        if (window.webkit.messageHandlers.receiveMessageFromJS != undefined){
                webkit.messageHandlers.receiveMessageFromJS.postMessage(operationName);
             }
                }
        else if (myJSHandler.receiveMessageFromJS != undefined) {
                    myJSHandler.receiveMessageFromJS(operationName);
           }
}

/**
* @description
* Opens IOS/Android JavaScript Interface in native mobile app with extra parameter.
*This method calls only from PassportYapiKrediMobile.js
* @param {string} operationName Specify which method calls from merchant web app.
* @param {string} parameter Passes the parameter which taken from merchant web app to native mobile app.
*/
export function receiveMessageWithParameter(operationName, parameter){

if(parameter != undefined){
    if(window.webkit != undefined){
        if (window.webkit.messageHandlers.receiveMessageFromJS != undefined){
                webkit.messageHandlers.receiveMessageFromJS.postMessage(operationName,parameter);
             }
                }
        else if (myJSHandler.receiveMessageFromJS != undefined) {
                    myJSHandler.receiveMessageFromJS(operationName, parameter);
           }
    }
}