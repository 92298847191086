import React from 'react';
import { View, Text,ScrollView, TouchableOpacity, Image, KeyboardAvoidingView, Animated, TextInput } from 'react-native';
import CustomModal from '../CustomModal';
import EntypoIcon from '@expo/vector-icons/Entypo'
import Colors from '../Colors';
import API from '../API';
import { ActivityIndicator } from 'react-native';

export default class PromotionsModal extends React.Component {

    constructor() {
        super();
        this.promotionInvalidAnimation = new Animated.Value(0);
        this.state = {promotionCodeTextInput: "", promotionCodeInvalid: false, loading: true}
    }
    componentDidMount() {
        
    }
    open() {
        this.promotionsModal.open();
        this.setState({loading: true});
        API.fetch("getcoupons").then((coupons) => {
            
            this.setState({loading: false, coupons});
        })
    }

    close() {
        this.promotionsModal.close();
    }
    render() {
        return <>
        
        <CustomModal title={global.getText("promotions")} ref={(a) => {
          this.promotionsModal = a
         
          }}>
            
            <ScrollView contentContainerStyle={{flex:1, paddingLeft:"5%", paddingRight:"5%",paddingTop:"5%"}} style={{flex:1}}>
                {this.state.loading && <ActivityIndicator color={Colors.Blue} />}
                {!this.state.loading && <><TouchableOpacity onPress={() => {
                  this.setState({promotionCodeTextInput:"", promotionCodeInvalid: false})
                  this.addCouponModal.open();
                }} style={{marginBottom:"5%", alignItems:"center",flexDirection:"row", backgroundColor:"white", borderRadius:10,padding:8, paddingLeft:10, paddingRight:10, shadowColor: "#000",
shadowOffset: {
	width: 0,
	height: 0,
},
shadowOpacity: 0.18,
shadowRadius: 2.00,

elevation: 1,}}>

                  <View style={{padding:7, paddingLeft:10, paddingRight:10, borderRadius:10,backgroundColor:"white", shadowColor: "#000",
shadowOffset: {
	width: 0,
	height: 0,
},
shadowOpacity: 0.18,
shadowRadius: 2.00,

elevation: 1}}><EntypoIcon name='plus' size={20} color={Colors.Blue} />



</View>

  <Text style={{fontWeight:"bold", color:Colors.Blue, marginLeft:15}}>{global.getText("addCouponCode")}</Text>

                </TouchableOpacity>
                {this.state.coupons.map((x, i) => <View key={i} style={{flexDirection:"row",shadowColor: "#000",marginTop:i==0?0:20,shadowOffset: {width: 0,height: 0,},shadowOpacity: 0.10,shadowRadius: 3.62,elevation: 4}}>
                      <Image source={require('../assets/ticketedge.png')} resizeMode='contain' style={{height:90,aspectRatio:0.0745501285347044, width:"auto"}} />
                      <View style={{flex:1, height:"100%",marginLeft:-1}}>
                        <View style={{flex:1, backgroundColor:"white",height:"100%",borderTopRightRadius: 2,flexDirection:"row",paddingRight:15,paddingBottom:15,paddingTop:15, alignItems:"flex-end", justifyContent:"space-between", borderBottomRightRadius:2}}>
                            
                            <View style={{flexDirection:"row", alignItems:"center",flex:1}}>
                            <Image source={require('../assets/icon.png')} style={{width:50, height:50, marginLeft:8, marginRight:8, borderRadius:5}} />
                            
                            <View style={{height:"100%",justifyContent:"space-evenly",flex:1}}>
                              {x.expiry != undefined && <View style={{backgroundColor:"#FFF5EE",borderRadius:50, padding:4,alignSelf: 'flex-start', marginBottom:5}}>
                                <Text style={{color:"#EB7060", fontWeight:"700",fontSize:10}}>Limited Offer</Text>
                              </View>}
                              <Text style={{fontWeight:"700"}}>{(x.title)?x.title[global.locale]:(x.type == 0?typeof x.discounts == 'number'?global.getText("coupons")[0].title.replace("*val*", x.discounts):global.getText("coupons").titlePercent.replace("*val*", x.discounts):"")}</Text>
                              {x.desc != undefined && <Text style={{color:"#BBBBBB", fontSize:12}}>{x.desc[global.locale]}</Text>}
                              
                              {x.expiry != undefined && <Text style={{color:"#BBB7BB",marginTop:5, fontSize:10}}>{global.getText("couponExpiry").replace("*val*", new Date().toLocaleDateString(x.expiry))}</Text>}
                              
                            </View>
                            </View>

                            {this.props.onCouponUsed != undefined && <TouchableOpacity onPress={() => this.props.onCouponUsed(x)} style={{marginLeft:5}} hitSlop={{left:5,right:5,top:5,bottom:5}}><Text style={{fontWeight:"700", color:Colors.Blue}}>{global.getText("use")}</Text></TouchableOpacity>}
                        </View>
                      </View>
                  </View>)}</>}
                {/* <View style={{backgroundColor:"white", paddingLeft:"5%", paddingRight:"5%", marginTop:10}}>

                    
                    
                </View> */}
            </ScrollView>


            
            
        </CustomModal>

        <CustomModal headerShown={false} ref={(a) => {
              this.addCouponModal = a;
            }} containerBackground="rgba(0,0,0,.2)">
              <KeyboardAvoidingView style={{flex:1}} behavior='padding'>
              <View style={{flex:1, justifyContent:"center", alignItems:"center"}}>
              <View style={{backgroundColor:"white",width:"90%", padding:10,borderRadius:10}}>
                <Animated.View style={{transform: [{translateX: this.promotionInvalidAnimation}]}}>
                  <TextInput value={this.state.promotionCodeTextInput} onChangeText={(e) => {
                    
                    this.setState({promotionCodeTextInput:e, promotionCodeInvalid: false})
                    
                    }} autoCapitalize='characters' style={{fontSize:14, padding:15,paddingTop:18,paddingBottom:18, fontWeight:"500", borderRadius:10, borderWidth:1,color: this.state.promotionCodeInvalid?"crimson":"black", borderColor:this.state.promotionCodeInvalid?"crimson":"lightgray"}} placeholder={global.getText("enterCouponCode")} placeholderTextColor={this.state.promotionCodeInvalid?"crimson":"#697388"} autoFocus={true} />
                  
                  </Animated.View>
                  <View style={{flexDirection:"row"}}>

                    <TouchableOpacity onPress={() => {
                      this.addCouponModal.close();
                    }} style={{flex:1, backgroundColor:"#A2A2A2", justifyContent:"center",marginTop:10, alignItems:"center", paddingTop:18, paddingBottom:18,borderRadius:10}}>
                      <Text style={{fontSize:14, color:"white",fontWeight:"bold"}}>{global.getText("cancel")}</Text>
                    </TouchableOpacity>

                    <TouchableOpacity onPress={() => {

                      this.setState({promotionCodeInvalid: false});
                      global.showLoadingPanel();
                      

                      API.fetch("redeemcoupon", {code: this.state.promotionCodeTextInput}, true).then((e) => {

                        global.hideLoadingPanel();
                        if(e.error) {
                          Animated.sequence([
                            Animated.timing(this.promotionInvalidAnimation, { toValue: 5, duration: 25, useNativeDriver: true }),
                            Animated.timing(this.promotionInvalidAnimation, { toValue: -5, duration: 25, useNativeDriver: true }),
                            Animated.timing(this.promotionInvalidAnimation, { toValue: 5, duration: 25, useNativeDriver: true }),
                            Animated.timing(this.promotionInvalidAnimation, { toValue: 0, duration: 25, useNativeDriver: true })
                          ]).start();
    
                          this.setState({promotionCodeInvalid: true});
                        } else {
                          
                          this.state.coupons.push(e);

                          
                          this.addCouponModal.close();
                          this.forceUpdate();
                        }
                      })
                    }} style={{flex:1, backgroundColor:Colors.Blue, marginLeft:10, justifyContent:"center",marginTop:10, alignItems:"center", paddingTop:18, paddingBottom:18,borderRadius:10}}>
                      <Text style={{fontSize:14, color:"white",fontWeight:"bold"}}>{global.getText("apply")}</Text>
                    </TouchableOpacity>

                  </View>
              
              </View>
              </View>
              </KeyboardAvoidingView>
            </CustomModal></>
    }
}
