import * as Localization from 'expo-localization';
export default {

    getHoursAndMinutes(hours, minutes) {

        if(global.locale == 'tr') return ((hours > 0)?hours + "sa":"") + (minutes > 0?((hours > 0)?" ":"")+minutes + "dk":"");

        return ((hours > 0)?hours + "hr"+(hours > 1?"s":""):"") + ((minutes> 0)?((hours > 0)?" ":"")+minutes+"m":"");
    },
    getVehicleName(vehicleId) {
        return global.getText("vehicles")[vehicleId];
    },
    getDateString(date) { 
        return global.getText("days")[date.getDay()] + " " + date.getDate() + " " + global.getText("months")[date.getMonth()] + ", " + digit(date.getHours()) + ":" + digit(date.getMinutes());
    }
}

function digit(num) {
    return `${num}`.padStart(2, '0');
}