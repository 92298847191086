import * as React from "react"
import Svg, { Circle, Path } from "react-native-svg"

function SvgComponent(props) {
  return (
    <Svg
      height="100%"
      width="100%"
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 508 508"
      xmlSpace="preserve"
      {...props}
    >
      <Circle cx={254} cy={254} r={254} fill={"#3c9cd7"} />
      <Path
        d="M179.6 138.8c8.4-2.4 17.2 2.4 20 10.4l16.4 50c.4.8 1.2 1.6 2.4 1.2.8-.4 1.6-1.2 1.2-2.4l-26.8-80c-2.4-8 .4-16.8 8-20.8 9.6-4.8 20.4 0 23.6 9.6l24 72.8c.4.8 1.2 1.6 2.4 1.2.8-.4 1.6-1.2 1.2-2.4l-31.2-95.2c-2.8-9.2 2-18.8 10.8-22h.4c.4 0 1.2-.4 1.6-.4 8.4-1.6 16.4 4 19.2 12.4l32.4 99.6c.4.8 1.2 1.6 2.4 1.2.8-.4 1.6-1.2 1.2-2.4L260 81.2c-3.2-9.6 2.4-19.6 12-22.4 8.8-2.4 18.4 2.4 21.2 11.2L328 172.8c2-20 7.6-38.4 18.4-50 5.6-6 16.4-2.8 20.8 4 2 2.8 3.6 7.6 2.4 13.2 0 0-12.4 21.6 2 56.8 14.4 34.8-15.2 89.2-15.2 89.2-9.6 21.6 18.8 101.2 18.8 101.2l-86.4 24.4c-13.2-37.2-45.6-86.8-45.6-86.8l-13.6-21.6c-15.6-24.8-28.4-51.6-38-79.2l-22.8-65.2c-3.2-8 1.6-17.6 10.8-20z"
        fill="#f9b54c"
      />
      <Path
        d="M292 505.2c50.8-7.6 96.4-30 132.4-62.8L385.2 338l-134.8 56.4L292 505.2z"
        fill="#e6e9ee"
      />
      <Path
        d="M309.2 135.6c-8.4-2-17.2 2.8-19.6 11.2l-15.2 50.4c-.4.8-1.2 1.6-2.4 1.2-.8-.4-1.6-1.2-1.2-2.4l24.4-80.8c2.4-8-.8-16.8-8.4-20.4-9.6-4.4-20.4.4-23.2 10L241.2 178c-.4.8-1.2 1.6-2.4 1.2-.8-.4-1.2-1.2-.8-2l28.8-95.6c2.8-9.2-2.4-18.8-11.6-21.6h-.4c-.4 0-1.2-.4-1.6-.4-8.4-1.2-16.4 4.8-18.8 12.8l-30 100.4c-.4.8-1.2 1.6-2.4 1.2-.8-.4-1.6-1.2-1.2-2.4l26.8-91.2c2.8-9.6-2.8-19.6-12.4-22C206 56 196.8 61.2 194 70l-32 104c-2.4-20-8.4-38.4-19.6-49.6-5.6-6-16.4-2-20.8 4.8-2 3.2-3.2 8-2 13.2 0 0 12.8 21.2-.8 56.8-13.6 35.2 17.6 88.8 17.6 88.8 10 21.2-16 102-16 102l86.8 22.4c12.4-37.6 43.2-88 43.2-88l13.2-22c15.2-25.2 27.2-52 36-80l20.8-65.6c3.2-9.6-2-18.8-11.2-21.2z"
        fill="#ffd05b"
      />
      <Path
        d="M75.6 434.8c35.2 34.8 80.4 59.2 130.8 68.8l38.4-110.4L108.4 340l-32.8 94.8z"
        fill="#ff7058"
      />
    </Svg>
  )
}

export default SvgComponent
