import React from 'react';
import { Text, View } from 'react-native';
import { CheckBox } from 'react-native-elements';
import MaterialCommunityIcon from '@expo/vector-icons/MaterialCommunityIcons';
import Colors from '../Colors';

export default class OrderAdditional extends React.Component {


    render() {
        return <View style={{backgroundColor:"white", paddingLeft:"4%", paddingRight:"4%", paddingTop:10, paddingBottom:10, display:"flex",flexDirection:"row", justifyContent:"space-between"}}>
            <View style={{display:"flex", flexDirection:"row", alignItems:"center"}}>{this.props.icon}<Text style={{fontSize:16, marginLeft:10}}>{this.props.title}</Text></View>
            <View style={{display:"flex", flexDirection:"row", alignItems:"center"}}><CheckBox checkedColor={Colors.Blue} textStyle={{color:(this.props.selected)?"black":"gray"}} onPress={() => this.props.onPress(!this.props.selected)} checked={this.props.selected} containerStyle={{borderWidth:0, backgroundColor:"white", paddingBottom:0, paddingTop:0, paddingRight:0}} iconRight title={this.props.price+"₺"} /></View>
        </View>
    }
}