import React from 'react';
import Colors from '../Colors';
import API from '../API';
import * as Notifications from 'expo-notifications';
import {View, Text, KeyboardAvoidingView, TouchableOpacity, Image, ActivityIndicator,TextInput, FlatList, Dimensions, Animated, Platform} from 'react-native';
import Icon from '@expo/vector-icons/AntDesign';
import AdditionalInformationInput from '../components/AdditionalInformationInput';
import CustomModal from '../CustomModal';
import IonIcon from '@expo/vector-icons/Ionicons';

export default class BusinessLoginModal extends React.Component {

    constructor(props) {
        super(props);   
        this.state = {email: '', password: '', phone: "", codeSent: false, lastStep: false,currentIndex:0}
    }

    async getExpoToken() {
        const { status: existingStatus } = await Notifications.getPermissionsAsync();
        let finalStatus = existingStatus;
        if (existingStatus !== 'granted') {
        const { status } = await Notifications.requestPermissionsAsync();
        finalStatus = status;
        }


        if (finalStatus !== 'granted') {
        return undefined;
        }
        //token = (await Notifications.getExpoPushTokenAsync()).data;

        var push = (await Notifications.getExpoPushTokenAsync());
        return push.data;//(await Notifications.getDevicePushTokenAsync()).data;
        //return (await Notifications.getExpoPushTokenAsync()).data;
    }
    requestCode() {
        global.showLoadingPanel();
        if(this.countdownInterval) {
            clearInterval(this.countdownInterval);
            this.countdownInterval = undefined;
        }
        API.fetch('requestcode', {phone: this.state.phone.replace(/ /g, ""), lang: global.locale}).then((e) => {
            this.setState({codeSent:(!e.error), waitSeconds: 30});
            global.hideLoadingPanel();
            
            if(!e.error) {
                setTimeout(() => {
                    this.otpInput.focusField(0);
                   
                }, 700);

                if(this.countdownInterval) 
                    clearInterval(this.countdownInterval);

                this.countdownInterval = setInterval(() => {
                    this.state.waitSeconds--;
                    this.forceUpdate();

                    if(this.state.waitSeconds <= 0) {
                        clearInterval(this.countdownInterval);
                        this.countdownInterval = undefined;
                    }
                }, 1000);
                
            }
        })
    }
    open() {
        this.setState({email: '', password: '', phone: "", codeSent: false, lastStep: API.user && !API.user.name,currentIndex:0})
        this.modal.open();

    }

    close() {
        this.modal.close();
    }

    render() {
        return <CustomModal ref={(a) => this.modal = a} headerShown={false} scrollView={false}>

           

        
        
            
        
        
        
      


         <KeyboardAvoidingView keyboardVerticalOffset={0} behavior={Platform.OS=='ios'?'padding':'height'}  style={{display:"flex", flex:1, backgroundColor:"white", paddingRight:"5%", paddingLeft:"5%", paddingTop:75, justifyContent:"space-between", paddingBottom:30}}>
            <View>
            <Text style={{marginTop:20, fontWeight:"500", fontSize:20}}>{global.getText("businessLogin")}</Text>


            <AdditionalInformationInput keyboard={'email-address'} value={this.state.email} onChange={(a) => this.setState({email:a})} onSubmit={() => this.passwordPop.focus()} label={global.getText("businessEmail")} />
            
            <AdditionalInformationInput isPassword keyboard={'default' }value={this.state.password}  onChange={(a) => this.setState({password:a})} onSubmit={() => {}} ref={(a) => this.passwordPop = a}  last label={global.getText("businessPassword")} />
           

            <Text style={{marginTop:20, color:"gray"}}>{global.getText("loginPhoneNotify")}</Text>
            </View>

                <View>
                    <TouchableOpacity style={{padding:5, marginBottom:15}}><Text style={{color:Colors.Blue,textAlign:"center", fontWeight:"600"}}>{global.getText("businessRegister")}</Text></TouchableOpacity>
                <TouchableOpacity onPress={async () => {
                    global.showLoadingPanel();

                    const expo = await this.getExpoToken();
                    API.fetch("businesslogin", {email: this.state.email, password: this.state.password, lang: global.locale, expo}).then((e) => {
                        if(!e.error) {
                            this.close();

                            setTimeout(() => {
                                if(global.onLoginCallback) global.onLoginCallback();
                                global.hideLoadingPanel();
                            }, 500);
                            
                            global.refreshMain();

                        }
                    })
                    // API.fetch('requestcode', {phone: this.state.phone.replace(/ /g, ""), lang: global.locale}).then((e) => {
                    //     this.setState({loading: false, codeSent:(!e.error)});

                    //     if(!e.error) {
                    //         setTimeout(() => {
                    //             this.otpInput.focusField(0);
                    //         }, 500);
                            
                    //     } else {
                    //         global.showError("2");
                    //     }
                    // })
                   
                }} style={{paddingTop:12,paddingBottom:12,flexDirection:"row", backgroundColor:Colors.Blue, justifyContent:"center", alignItems:"center", paddingLeft:20, paddingRight:10, borderRadius:100,marginBottom:Platform.OS=='ios'?30:0}}>
                    <Text style={{fontWeight:"700", fontSize:17, color:"white"}}>{global.getText("businessLoginButton")}</Text>
                    
                    
                </TouchableOpacity>
                </View>
            
            {/* <TextInput style={{backgroundColor:"white", width:"70%", paddingTop:10, paddingBottom:10, paddingLeft:10, paddingRight:10, borderRadius:10}} value={this.state.email} onChangeText={(a) => this.setState({email: a})} keyboardType='email-address' placeholder='Email' />
            <TextInput style={{backgroundColor:"white", width:"70%", paddingTop:10, paddingBottom:10, paddingLeft:10, paddingRight:10, borderRadius:10, marginTop:10}} value={this.state.password} onChangeText={(a) => this.setState({password: a})} secureTextEntry placeholder='Password' />

            <TouchableOpacity onPress={async () => {
                const expo = await this.getExpoToken();
                API.fetch('login', {email: this.state.email, password: this.state.password, expo}).then((data) => {
                    
                    if(data.user)
                        this.navigation.replace("Home");
                });
            }} style={{width:"60%", backgroundColor: Colors.Blue, display:"flex", justifyContent:"center", flexDirection:"row", paddingTop:10, paddingBottom:10, borderRadius:10, marginTop:25}}><Text style={{color:"white", fontWeight:"700"}}>Login</Text></TouchableOpacity>
            <TouchableOpacity onPress={async () => {
                const expo = await this.getExpoToken();

                 API.fetch('register', {email: this.state.email, password: this.state.password, expo}).then((data) => {
                    if(data.user)
                        this.navigation.replace("Home");
                });
            }} style={{width:"60%", backgroundColor: "white", borderWidth:2, borderColor:Colors.Blue, display:"flex", justifyContent:"center", flexDirection:"row", paddingTop:10, paddingBottom:10, borderRadius:10, marginTop:10}}><Text style={{fontWeight:"700", color: Colors.Blue}}>Register</Text></TouchableOpacity>
             */}
             
        </KeyboardAvoidingView>
        
        <TouchableOpacity onPress={() => {
                            
                            
                    this.close()
                                       
                }} style={{position:"absolute", top:50, left:"5%"}}>
                            <IonIcon name='close' size={30} />
                        </TouchableOpacity>
        
        </CustomModal>
    }
    
}

function digit(num) {
    return `${num}`.padStart(2, '0');
}