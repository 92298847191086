import React from 'react';
import {View, Text, KeyboardAvoidingView, AppState, Vibration, Keyboard, TouchableOpacity, Platform} from 'react-native';
import { Bubble, GiftedChat, Actions, Send } from 'react-native-gifted-chat'
import API from './API';
import * as ImagePicker from 'expo-image-picker';
import { manipulateAsync, SaveFormat } from 'expo-image-manipulator';   
import Constants from 'expo-constants'; 
import EvilIcon from '@expo/vector-icons/EvilIcons';
import TypingIndicator from "react-native-gifted-chat/lib/TypingIndicator"
/*const systemMsg = {
    _id: -10,
    text: global.getText("historyNotify"),
    system: true,
    // Any additional custom parameters are passed through
  };*/
export default class ChatScreen extends React.Component {

    constructor({route, navigation}) {
        super();
        this.navigation = navigation;
        this.systemMsg = {
            _id: -10,
            text: global.getText("historyNotify"),
            system: true,
            // Any additional custom parameters are passed through
          };
        
        this.focusListener = AppState.addEventListener('change', (a) => {
            if(a == 'active')
                this.refreshMessages();
        });
        this.order = route.params;
        
        
        this.state = {messages: [this.systemMsg]};
        /*
, {
            _id: 101,
            createdAt: new Date(),
            user: {_id: 0, name: "me"},
            image: `https://play-lh.googleusercontent.com/1Aw97Nkoe3mFF4tnKNIloWnvSeMP8B1ZK8-A-QgoGP86kBuBOYJ-DY5dCBXdP3EeHw`,
            sent:true
        }
        */
        this.refreshMessages();

        this.socketOn = global.socket.bind('chat', ({_id, message}) => {
            console.log(_id, message);
            if(_id != this.order._id) return;

            
            var msg = {
                pending: false,
                received: true,
                sent: true,
                text: message.m,
                user: {_id: (message.p)?1:0},
                createdAt: new Date(message.d),
                d: message.d,
                _id: this.state.messages.length+1,
                image: (message.img)?"https://tasimacim-chat-attachments.s3.eu-central-1.amazonaws.com/"+message.img+".jpeg":undefined
            };
            
            this.order.lastMC = Date.now();
            this.order.notification = false;
            this.setState({messages: GiftedChat.append(this.state.messages, msg)});
            Vibration.vibrate();

            API.fetch("setseen", {_id: this.order._id, date: message.d});
        })

        this.onChatDelete = global.socket.on('chatdelete', ({_id, date}) => {
            if(_id != this.order._id) return;
            var indx = this.state.messages.findIndex(s => s.d == date);
            if(indx != -1) {
                this.state.messages.splice(indx,1);
                this.forceUpdate();
            }
        })

        global.socket.on("typing", ({_id, end}) => {
            if(_id != this.order._id) return;

            this.setState({isTyping: !end});
        })

        /*global.onChatMessage = (orderId, data) => {
            if(orderId != this.order._id) return;

            
            message = {
                pending: false,
                received: true,
                sent: true,
                text: data.m,
                user: {_id: (data.p)?1:0},
                createdAt: new Date(data.d),
                _id: this.state.messages.length+1,
                image: (data.img)?"https://storage.googleapis.com/chat_props/"+data.img+".jpeg":undefined
            };
            this.order.lastMC = Date.now();
            this.order.notification = false;
            this.setState({messages: GiftedChat.append(this.state.messages, message)});
        }*/

        this.orderUpdateEvent = global.socket.bind('orderUpdate',({_id, status}) => {
            if(_id == this.order._id && status == 0) {
                navigation.pop();
            }
        });
    }
    

    

    async refreshMessages() {
        API.fetch('chathistory', {_id: this.order._id}).then((messageData) => {

            var messages = [];

            for(var i = messageData.length-1; i >= 0; i--) {
                var data = messageData[i];
                messages.push({
                    pending: false,
                    received: true,
                    sent: true,
                    text: data.m,
                    user: {_id: (data.p)?1:0},
                    createdAt: new Date(data.d),
                    _id: i,
                    d: data.d,
                    image: (data.img)?"https://tasimacim-chat-attachments.s3.eu-central-1.amazonaws.com/"+data.img+".jpeg":undefined
                })

                
            }
            messages.push(this.systemMsg);
            this.order.lastMC = Date.now();
            this.order.notification = false;
            

            this.setState({messages});

            
            if(messages.length > 0 && messages[0].d)
                API.fetch("setseen", {_id: this.order._id, date: messages[0].d});
            //console.log(messages);
        })

       
    }

    componentWillUnmount() {
        
        if(this.focusListener)
            this.focusListener.remove();

        this.socketOn();


        this.orderUpdateEvent();

        global.onChatMessage = undefined;
    }
    
    
    render() {
        return <View style={{flex:1}}>

<View style={{height:Constants.statusBarHeight+50, paddingBottom:15, backgroundColor:"white", justifyContent:"center",flexDirection:"row",alignItems:'flex-end'}}>
                <Text style={{fontWeight:"500",fontSize:18}}>{(this.order.driverDetails)?this.order.driverDetails.name:global.getText("liveSupport")}</Text>
                <TouchableOpacity onPress={() => this.navigation.pop()} style={{position:"absolute", left:"5%",marginLeft:-10,bottom:5}}><EvilIcon name='chevron-left' size={50}  /></TouchableOpacity>
            </View>
            {/* TODO: FIND A WAY TO CENTER TEXT INSIDE TEXTBOX WITHOUT GIVING A PADDING! */}
            <GiftedChat isTyping={this.state.isTyping}  wrapInSafeArea={false} render renderSend={(props) => <Send {...props} label={global.getText("sendMessage")} />} alwaysShowSend={true}  placeholder='' renderActions={this.renderActions}  renderBubble={(props) => <Bubble wrapperStyle={{left: {backgroundColor:"#ebebeb"}}} {...props} />}  textInputStyle={{backgroundColor: "#F6F8FA", borderRadius:100, paddingTop:5,marginTop:5,marginBottom:5,paddingBottom:5, paddingLeft: 10}} user={{_id: 0}} renderAvatar={null} messages={this.state.messages} 
                onSend={(messages) => {
                    var message = messages[0];
                    if(message.text.trim() == ' ' || message.text.trim().length == 0) return;
                    message.pending = true;
                    
                    this.setState({messages: GiftedChat.append(this.state.messages, message)});

                    API.fetch('chat', {_id: this.order._id,from:this.order.from, msg: message.text}).then((e) => {
                        if(!e.error) {
                            message.pending = false;
                            message.received = true;
                            message.sent = true;
                            this.forceUpdate();
                        }
                    })
                }} 
            />
        
        <View style={{height:Platform.OS == 'ios'?30:5, backgroundColor:"white"}}></View>
        {Platform.OS === 'android' && <KeyboardAvoidingView behavior="padding" />}
        </View>
    }

    sendPhoto = async (base64) => {
        
        
        const picture = "data:image/jpeg;base64," + base64;
        var message = {_id: Math.random(), createdAt: new Date(), pending: true, user: {_id:0},image: picture}
        this.setState({messages: GiftedChat.append(this.state.messages, message)});

        API.fetch('chat', {_id: this.order._id, img: base64}).then((e) => {
            if(!e.error) {
                message.pending = false;
                message.received = true;
                message.sent = true;
                this.forceUpdate();
            }
        })
    }


    renderActions = (props) => {
        return (
            <Actions
                {...props}
                options={{
                    [global.getText("camera")]: async (props) => {
                        let t = await ImagePicker.requestCameraPermissionsAsync();
                        
                        
    
                        if(t.granted) {
                            let result = await ImagePicker.launchCameraAsync({
                                allowsEditing: true,
                                mediaTypes: ImagePicker.MediaTypeOptions.Images,
                                aspect: [4,3],
                                quality: 0,
                                base64:true,
                                allowsMultipleSelection: false,
                                
                            });
                           
                            
    
                            if(!result.cancelled) {
                                this.sendPhoto(result.base64);
                            }
                            
                        }
    
                    },
                    [global.getText("photoLibrary")]: async (props) => {
                        let t = await ImagePicker.requestMediaLibraryPermissionsAsync();
    
                        if(t.granted) {
                            let result = await ImagePicker.launchImageLibraryAsync({
                                mediaTypes: ImagePicker.MediaTypeOptions.Images,
                                allowsEditing: true,
                                aspect: [4, 3],
                                quality: 0,
                                base64: true,
                                allowsMultipleSelection: false,
                            });
    
                            if(result.base64) {
                                this.sendPhoto(result.base64);
                            }
                        }
                      
                        
                    },
                    [global.getText("cancel")]: (props) => {}
                }}
                onSend={args => {}}
            />
        )
    };
}


