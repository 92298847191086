import AsyncStorage from '@react-native-async-storage/async-storage';
import * as Localization from 'expo-localization';
import { Platform } from 'react-native';

global.locale = Localization.locale == 'tr-TR'?'tr':'en';
if(global.locale != 'tr' && global.locale != 'en') global.locale = 'en';


let token = "";


var exports = {};

exports.registerCampaigns = [];

exports.checkCoupon = (coupon, order) => {
  var valid = false;
  if(coupon.type == 0) {
      if(typeof coupon.discounts == 'number' || typeof coupon.discounts == 'string') valid = true;
      if(typeof coupon.discounts[order.vehicle] == 'number' || typeof coupon.discounts[order.vehicle] == 'string') valid = true;
  }

  if(coupon.requirements) {
    
      if(coupon.requirements.minDays < ((typeof order.date == 'bigint'?order.date:order.date.getDate()) - new Date().getDate())) {
          valid = false;
      }
  }

  return valid;
}

exports.calculateCouponDiscount = (coupon, order) =>{
  if(!coupon) return 0;


  var price = order.calculation.price;

  if(order.additionalSelections) {
    for(var i = 0; i < order.additionalSelections.length; i++) {
        if(i == 4) {
            if(order.additionalSelections[i] > 0) price += order.additionalSelections[4]*exports.additionalPrices[4];
            continue;
        }
        if(order.additionalSelections[i]) price += exports.additionalPrices[i];
    }
  }

  if(coupon.type == 0) {
      var discountType = (typeof coupon.discounts == 'number' || typeof coupon.discounts == 'string')?coupon.discounts:coupon.discounts[order.vehicle];

      if(typeof discountType == 'number') return discountType;
      return price * (1 - (parseInt(discountType)/100));
  }

  return price;
    
}

exports.setToken = (newToken) => {
  token = newToken;
}




  exports.dateFromObjectId = (objectId) => {
    return new Date(parseInt(objectId.substring(0, 8), 16) * 1000);
  };


  
  exports.getOrderTotal = (order) => {
    var charges = 0;
    if(order.charges) {
      
      Object.entries(order.charges).map(([key,value]) => ({_id: key, ...value})).forEach((s) => {
        if(s.v) charges += s.p
      })

    }
  
    return order.pricing.price + (order.pricing.bonus?order.pricing.bonus:0) + charges;
}

exports.getAddressLabel = (address) => {
  var extras = "";
  if(address) {
    // if(address.extra.buildingNO) extras += address.extra.buildingNO + " ";
    // if(address.extra.floorNO) extras += address.extra.floorNO + " ";
    if(address.building) extras += global.getText("buildingShort")+": "+address.building + " ";
    if(address.floor) extras += global.getText("floorShort")+": "+address.floor + " ";
    if(address.door) extras += global.getText("noShort")+": "+address.door + " ";
    if(address.contactName) extras += (extras != ''?" · ":"")+address.contactName + " ";
    if(address.contactNO) extras += (extras != '' && !address.contactName?" · ":"")+address.contactNO;
    extras = extras.replace(/\s{2,}/g, ' ').trim();
  }

  return address.Label + ((extras.length > 0)?" · "+extras:"");
}
const Amplitude = Platform.select({
  web: require('./FakeAmplitude').default,
  default: require('@amplitude/analytics-react-native')
})

exports.getAddressExtras = (address) => {
  var extras = "";
  if(address) {
    // if(address.extra.buildingNO) extras += address.extra.buildingNO + " ";
    // if(address.extra.floorNO) extras += address.extra.floorNO + " ";
    if(address.building) extras += global.getText("buildingShort")+": "+address.building + " ";
    if(address.floor) extras += global.getText("floorShort")+": "+address.floor + " ";
    if(address.door) extras += global.getText("noShort")+": "+address.door + " ";
    if(address.contactName) extras += (extras != ''?" · ":"")+address.contactName + " ";
    if(address.contactNO) extras += (extras != '' && !address.contactName?" · ":"")+address.contactNO;
    extras = extras.replace(/\s{2,}/g, ' ').trim();
  }

  return extras;
}

exports.additionalPrices = [75,150];
exports.setAdditionalPrices = (a) => exports.additionalPrices = a;

const DEV_SERVER = false;
var CACHE_POPUPS = [];
// if(!__DEV__) {
//   DEV_SERVER = false;
// }
const SERVER_ADDRESS = (DEV_SERVER)?"http://192.168.1.56:3002/":"https://server.tasimacim.com/";
exports.SOCKET_ADDRESS = (DEV_SERVER)?"http://192.168.1.101:4242":"https://server.tasimacim.com:4242/";
exports.SERVER_ADDRESS = SERVER_ADDRESS;
exports.fetch = (url, body,ignoreError) => {
  return new Promise((resolve,reject) => {
      //getToken().then(token => {
        
        //fetch('http://192.168.1.7:3000/' + url, {body: JSON.stringify(body), method:"POST",mode:"no-cors", headers: {'Content-Type': 'plication/json', Authorization: 'Bearer '+token}}).then((e) => e.json()).then((e) => {
          
        // if(!body) body = {}
        // body.endpoint = url;
        // console.log(body);
        

        //http://192.168.1.104:3002/
        //fetch(DEV_SERVER?("http://35.241.204.128/" + url):('https://europe-west1-windy-collector-346317.cloudfunctions.net/handler?q='+url), {body: JSON.stringify(body), method:"POST",mode:"no-cors", headers: {'Content-Type': 'application/json', Authorization: 'Bearer '+token}}).then((e) => e.json()).then((e) => {
        
        
        fetch(SERVER_ADDRESS+url, {body: JSON.stringify(body), method:"POST",headers: {'Content-Type': 'application/json', Authorization: 'Bearer '+token}}).then((e) => e.json()).then((e) => {
        if(e.token) {
          token = e.token;
          exports.token = token;
          AsyncStorage.setItem("bearerToken", e.token);
        }
        if(e.popup) {
          var cache = CACHE_POPUPS.find(s => s._id == e.popup._id);
          
          if(cache == undefined || Date.now()-cache.d >= e.popup.delay) {
            if(!cache) {
              cache = {_id: e.popup._id};
              CACHE_POPUPS.push(cache);
            } 
            cache.d = Date.now();

            global.showPopup(e.popup.header[global.locale], e.popup.body[global.locale]);
          }
        }
        
        if(e.termsAndConditions) {
          global.showTOS(e.termsAndConditions);
        }
        if(e.user) {
          if(global.onLoginCallback) {
            global.onLoginCallback();
            global.onLoginCallback = undefined;
          }
          if(!e.user.orders)
            e.user.orders = [];
          if(!e.user.cards)
            e.user.cards = [];

          if(e.user && !e.user.name) {
            global.promptLogin();
          }

          
          
          // e.user.orders.forEach((order) =>{
          //   if(!order.lastMC) order.lastMC = 0;
            
          //   var found = false;

          //   if(order.messages) order.messages.forEach((msg) => {
          //     if(msg.d > order.lastMC && msg.p) found = true;
          //   })

          //   order.notification = found;
          // })
          

          if(e.user.lang) {
            //i18n.locale = e.user.lang;
            global.locale = e.user.lang;
          }
          
          Amplitude.setUserId(e.user.id);
          exports.user = e.user;

          global.checkSocketConnection();

          
        }

        if(e.error && !ignoreError) {
          
          global.showError(e.error,e.str);
          
        }
        resolve(e)

        
        
  }).catch((e) => {
    console.log(e);
    
    //if(!ignoreError) global.showError(global.getText("connectionError"));
    global.showError(global.getText("connectionError"));

    reject(e);
  });
      //})
    
  })
}







export default exports;

