import React from 'react';
import {View, Text, TouchableOpacity} from 'react-native';
import Colors from '../Colors';

export default class AdditionalChargeRequest extends React.Component {

    render() {
        return <View style={{paddingLeft:"5%", paddingRight:"5%", marginTop:10, backgroundColor:"white", paddingTop:10, paddingBottom:10}}>
            <Text style={{fontWeight:"600"}}>{global.getText("pendingChargeRequest")}:</Text>

            <View style={{display:"flex" ,flexDirection:"row", justifyContent:"space-between", marginTop:5}}><Text style={{fontWeight:"500"}}>{this.props.charge.d}</Text><Text style={{fontWeight:"600"}}>{this.props.charge.p}₺</Text></View>
                    
            <View style={{flexDirection:"row", marginTop:10}}>
                <TouchableOpacity onPress={() => this.props.onPress(true)} style={{flex:1, justifyContent:"center", alignItems:"center", paddingTop:5, paddingBottom:5, borderRightWidth:2, borderColor:"#F4F4F4"}}><Text style={{fontWeight:"600", color:Colors.Blue, fontSize:16}}>{global.getText("accept")}</Text></TouchableOpacity>
                <TouchableOpacity onPress={() => this.props.onPress(false)} style={{flex:1, justifyContent:"center", alignItems:"center", paddingTop:5, paddingBottom:5}}><Text style={{fontWeight:"600", color:"crimson", fontSize:16}}>{global.getText("refuse")}</Text></TouchableOpacity>
            </View>
        </View>
    }
}