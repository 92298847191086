import React from 'react';
import { TouchableOpacity, StatusBar, AppState, View } from 'react-native';
import API from './API';
import AsyncStorage from '@react-native-async-storage/async-storage';
import * as Notifications from 'expo-notifications';
import { CommonActions } from '@react-navigation/native'

Notifications.setNotificationHandler({
    handleNotification: async () => ({
      shouldShowAlert: true,
      shouldPlaySound: false,
      shouldSetBadge: false,
    }),
  });

export default class InitialScreen extends React.Component {

    constructor({navigation}) {
        super();
        this.navigation = navigation;
        this.login();
    }

    async login() {
        const key = await AsyncStorage.getItem("bearerToken");
        if(key) {
            API.fetch('token', {token: key}).then((data) => {
                if(data.user)
                    this.redirectTo("Home");
                else
                    this.redirectTo("Login");
            })
        } else {
            this.redirectTo("Login");
        }
    }

    redirectTo(v) {
        this.navigation.dispatch(CommonActions.reset({
            index:0,
            routes: [{name: v}]
        }));
    }

    render() {
        return <View></View>
    }



}