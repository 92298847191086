import React from 'react';
import {View, Text, TouchableOpacity, KeyboardAvoidingView, FlatList, TextInput,Animated, Platform} from 'react-native';
import Icon from '@expo/vector-icons/FontAwesome';
import Colors from './Colors';
import AddressInput from './components/AddressInput';
import * as Contacts from 'expo-contacts';
import CustomModal from './CustomModal';
const MapView = Platform.select({
    web: () => require('./components/Map.web').default,
    default: () =>  require('./components/Map').default,
  })();
// import MapView from 'react-native-maps';
import EntypoIcon from '@expo/vector-icons/Entypo';
import MaterialCommunityIcon from '@expo/vector-icons/MaterialCommunityIcons';
import { CheckBox } from 'react-native-elements';
import { KeyboardAccessoryNavigation, KeyboardAccessoryView } from 'react-native-keyboard-accessory';
import AntIcon from '@expo/vector-icons/AntDesign';
import AsyncStorage from '@react-native-async-storage/async-storage';
const Amplitude = Platform.select({
    web: require('./FakeAmplitude').default,
    default: require('@amplitude/analytics-react-native')
  })

export default class FinalizeAddressScreen extends React.Component {

    constructor(props) {
        super(props);
        this.navigation = props.navigation;
        this.addressInputs = new Array(6);
        
        this.oldAddress = props.route.params.address;
        this.address = {...props.route.params.address};
        
        
        //this.address = {Label: "Zekeriyaköy Mahallesi, Biçer Sokak, Sarıyer/İstanbul", Point: [29.05487,41.19467]}
        
        this.state = {contactSearch: "", saveAddress: false,errorValue: [false,false,false,false], elevatorSelectionError: false};
        
        
    }

    warningPopup() {
        global.showPopup(global.getText("warning"), global.getText("elevatorInfo"))
    }


    async openContactsPanel() {
        const { status } = await Contacts.requestPermissionsAsync();
        if (status === 'granted') {
            this.state.contacts = [];

            const { data } = await Contacts.getContactsAsync({
                fields: [Contacts.Fields.Name, Contacts.Fields.PhoneNumbers],
              });

              this.setState({contacts: data,contactSearch :""});
          this.contactModal.open();
        }
        
    }

    componentWillUnmount() {
        //global.onFinializeAddressDone();
    }
    render() {
        console.log(global.getAddressInputIndex());
        return <View style={{flex:1,position:"relative"}}>

        
            <KeyboardAvoidingView  style={{flex:1}} behavior='padding' keyboardVerticalOffset={0}>
                    <View style={{flex:1}}>

                        <View style={{flex: 1}}>
                        <MapView  waypoints={[]} userInterfaceStyle='light' zoomEnabled={false} pitchEnabled={false} rotateEnabled={false} scrollEnabled={false} zoomTapEnabled={false} zoomControlEnabled={false} toolbarEnabled={false} userLocationCalloutEnabled={false} scrollDuringRotateOrZoomEnabled={false} legalLabelInsets={{top:10}} initialRegion={{latitude: this.address.Point[1], longitude: this.address.Point[0], latitudeDelta:0.01, longitudeDelta:0.01}}>
                            {/* <MaterialCommunityIcon name='map-marker-outline'  size={25} style={{marginLeft:-6}} /> */}
                        </MapView>
                        </View>
                        <View style={{marginLeft:"5%",paddingLeft:13, alignItems:"center", paddingRight:13, paddingTop:13,paddingBottom:13,flexDirection:"row",borderRadius:6, width:"90%",top:50,backgroundColor:"white",shadowColor: "#000",shadowOffset: {width: 0,height: 2},shadowOpacity: 0.25,shadowRadius: 3.84,elevation: 0, position:"absolute"}}>
                            <TouchableOpacity onPress={() => this.navigation.pop()} style={{marginRight:5}} pressRetentionOffset={{top:10,bottom:10, left:10, right:10}}><EntypoIcon name='chevron-left' size={30} /></TouchableOpacity>
                            <Icon color={Colors.Blue} name='circle-o' style={{marginRight:20}} />
                            <Text style={{fontWeight:"500", flex:1,fontSize:15}}>{this.address.Label}</Text>
                        </View>
                    </View>
                    <View style={{backgroundColor:"white", paddingLeft:"5%", paddingRight:"5%", paddingTop:10, paddingBottom:0,marginTop:-15, borderTopLeftRadius:15,borderTopRightRadius:15,shadowColor: "#000",
shadowOffset: {
	width: 0,
	height: 5,
},
shadowOpacity: 0.26,
shadowRadius: 15.68,

elevation: 0,}}>
                        <View style={{flexDirection:"row"}}><AddressInput error={this.state.errorValue[0]} onFocus={() => this.setState({focusedInput: 0})} ff value={this.address.building?this.address.building:""} onChange={(v) => {
                            this.address.building = v;
                            this.state.errorValue[0] = false;
                            this.forceUpdate();
                        }} ref={(a) => this.addressInputs[0] = a} onSubmit={() => this.addressInputs[1].focus()} label={global.getText("buildingOptional")} />
                        <AddressInput error={this.state.errorValue[1]} onFocus={() => this.setState({focusedInput: 1})} margin ff value={this.address.floor?this.address.floor:""} onChange={(v) => {
                            this.address.floor = v;
                            this.state.errorValue[1] = false;
                            this.forceUpdate();
                        }} ref={(a) => this.addressInputs[1] = a} onSubmit={() => this.addressInputs[2].focus()} label={global.getText("floorOptional")} />
                        <AddressInput error={this.state.errorValue[2]} onFocus={() => this.setState({focusedInput: 2})} margin ff value={this.address.door?this.address.door:""} onChange={(v) => {
                            this.address.door = v;
                            this.state.errorValue[2] = false;
                            this.forceUpdate();
                        }} ref={(a) => this.addressInputs[2] = a} onSubmit={() => this.addressInputs[3].focus()} label={global.getText("doorOptional")} />
                        </View>

                        <View style={{marginTop:0}}></View>
                        
                        <AddressInput onFocus={() => this.setState({focusedInput: 3})} value={this.address.details?this.address.details:""} onChange={(v) => {
                            this.address.details = v;
                            this.forceUpdate();
                        }} ref={(a) => this.addressInputs[3] = a} onSubmit={() => this.addressInputs[4].focus()} label={global.getText("addressExtraOptional")} />
                        
                        <View style={{marginTop:0}}></View>
                        
                        <AddressInput onFocus={() => this.setState({focusedInput: 3})} value={this.address.contactName?this.address.contactName:""} onChange={(v) => {
                            this.address.contactName = v;
                            this.forceUpdate();
                            
                        }} ref={(a) => this.addressInputs[4] = a} onSubmit={() => this.addressInputs[5].focus()} label={global.getText(global.getAddressInputIndex() == 0?"contactNameOptional":"contactNameWaypointOptional")} />

                        <View style={{flexDirection:"row", alignItems:"flex-end"}}>
                            <Text style={{marginBottom:10, marginRight:10, fontSize:16}}>+90</Text>
                        <AddressInput  onFocus={() => this.setState({focusedInput: 4})} ff value={this.address.contactNO?this.address.contactNO:""} onChange={(v) => {
                            this.address.contactNO = v;
                            
                            this.forceUpdate();
                        }}  keyboardType={'number-pad'} last ref={(a) => this.addressInputs[5] = a} onSubmit={() => {}} label={global.getText("contactNumberOptional")} />
                        
                        
                        <TouchableOpacity pressRetentionOffset={{left:5,right:5,top:5,bottom:5}} onPress={() => {
                            this.openContactsPanel();
                        }} style={{marginTop:20, marginLeft:"5%", position:"absolute", right:0,top:10}}>
                            <AntIcon name='contacts' size={20} />
                        </TouchableOpacity>
                        {/* <Text style={{color:Colors.Blue,fontWeight:"500"}}>{global.getText("selectFromContacts")}</Text> */}
                        </View>



                        
                        <View style={{flexDirection:"row", width:"100%",height:70,display:"flex", borderWidth:1,marginTop:15, borderColor:(this.state.elevatorSelectionError)?"crimson":"lightgray", borderRadius:10, overflow:"hidden"}}>

                        <SelectionButton selected={this.address.elevator == 0} onClick={() => {this.address.elevator = 0; this.setState({elevatorSelectionError: false})}} text={global.getText("elevatorOptions")[0]} />
                        <SelectionButton selected={this.address.elevator == 1} onClick={() => {this.address.elevator = 1; this.setState({elevatorSelectionError: false}); this.warningPopup();}} i={1} text={global.getText("elevatorOptions")[1]} />
                            <SelectionButton selected={this.address.elevator == 2} onClick={() => {
                                
                                this.address.elevator = 2
                                this.setState({elevatorSelectionError: false})
                                this.warningPopup();
                                }} text={global.getText("elevatorOptions")[2]} />
                            
                           
                            
                        </View> 
                        {/* <CheckBox checked={this.address.elevator == true} checkedColor={Colors.Blue} onPress={() => {
                            this.address.elevator = true;
                            this.forceUpdate();
                        }} title={global.getText("elevatorYes")} uncheckedColor={(this.state.errorValue[3])?'red':'gray'} containerStyle={{backgroundColor:"transparent", borderWidth:0, paddingLeft:0, marginTop:15, paddingTop:0,paddingBottom:0}} />
                        <CheckBox checked={this.address.elevator == false} checkedColor={Colors.Blue} onPress={() => {
                            this.address.elevator = false;
                            this.forceUpdate();
                        }} title={global.getText("elevatorNo")} uncheckedColor={(this.state.errorValue[3])?'red':'gray'} containerStyle={{backgroundColor:"transparent", borderWidth:0, paddingLeft:0, marginTop:10, paddingTop:0,paddingBottom:0}} /> */}
                        {/* {this.address.elevator == false && <Text style={{marginTop:0, color:"red", fontSize:12}}>{global.getText("elevatorExtraCharges")}</Text>} */}
                        {this.state.elevatorSelectionError == true && <Text style={{marginTop:5, color:"red", fontSize:12}}>{global.getText("chooseElevator")}</Text>}
                        {/* <CheckBox checkedColor={Colors.Blue} checked={this.state.saveAddress} onPress={() => this.setState({saveAddress: !this.state.saveAddress})} title={global.getText("saveAddress")} containerStyle={{backgroundColor:"none",paddingLeft:0,borderWidth:0,margin:0, marginTop:20,padding:0}} /> */}
                    </View>
                    <View style={{paddingTop:20, paddingBottom:20, backgroundColor:"white", paddingLeft:"5%", paddingRight:"5%"}}><TouchableOpacity onPress={() => {
                        // this.state.errorValue[0] = (!this.address.building)
                        // this.state.errorValue[1] = (!this.address.floor)
                        // this.state.errorValue[2] = (!this.address.door);
                        // this.state.errorValue[3] = (this.address.elevator == undefined);
                        if(this.address.elevator == undefined) return this.setState({elevatorSelectionError: true});
                        // if(this.state.errorValue[0] || this.state.errorValue[1] || this.state.errorValue[2] || this.state.errorValue[3]) {
                        //     this.forceUpdate();
                        //     return;
                        // }



                        Amplitude.track("WAYPOINT_SELECTED");
                        
                        var indx = global.addresses.recents.indexOf(this.oldAddress);

                        
                        global.setAddress(this.address);

                        if(indx == -1) {
                            global.addresses.recents.push(this.address);
                        } else {
                            global.addresses.recents[indx] = this.address;
                        }   
                        if(global.addresses.recents.length > 5) global.addresses.recents.splice(0,1);

                        AsyncStorage.setItem("searchAddresses", JSON.stringify(global.addresses));

                        this.navigation.pop();
                    }} style={{paddingTop:15, paddingBottom:15, backgroundColor:Colors.Blue, justifyContent:"center", alignItems:"center", borderRadius:10}}><Text style={{fontWeight:"500", color:"white",fontSize:16}}>{global.getText("confirm")}</Text></TouchableOpacity></View>
                  

                  </KeyboardAvoidingView>

                 


                
            <CustomModal scrollView={false} ref={(a) => this.contactModal = a}>
                <View style={{backgroundColor:Colors.Gray, paddingLeft:"5%", paddingRight:"5%", paddingTop:10, paddingBottom:10}}>
                    <TextInput value={this.state.contactSearch} onChangeText={(t) => {
                        this.setState({contactSearch: t});
                    }} style={{backgroundColor:"white", paddingBottom:10, paddingTop:10, paddingLeft:15, borderRadius:10}} placeholder={global.getText("search")} />
                </View>
                <FlatList style={{flex:1}} keyExtractor={(item, index) => index} data={(this.state.contacts)?this.state.contacts.slice().filter((s) => s.name != undefined && s.name.includes(this.state.contactSearch)):[]} renderItem={({item}) => 
                <TouchableOpacity onPress={() => {
                    this.address.contactName = item.name;
                    if(item.phoneNumbers.length > 0) {
                        
                        var number = item.phoneNumbers.find((s) => {
                            s.phone = s.number.replace(/ /g, "").replace("(","").replace(")","").replace("-","");
                            
                            return s.countryCode == 'tr' || s.phone.startsWith("+90") || s.phone.startsWith("05") || s.phone.startsWith("5")
                            
                        });
                        if(number) {
                            this.address.contactNO = number.phone.substring(number.phone.indexOf('5'));//number.number.substring(number.number.indexOf('0')+1).replace(/ /g, "").replace("(","").replace(")","");
                        } else {
                            this.address.contactNO = "";
                        }
                    } else {
                        this.address.contactNO = "";
                    }
                    this.forceUpdate();
                    this.contactModal.close();
                    
                }} style={{paddingTop:13, paddingBottom:13, backgroundColor:"white", borderTopWidth:1, borderColor:Colors.Gray, paddingLeft:"5%"}}><Text style={{fontSize:16, fontWeight:"500"}}>{item.name}</Text></TouchableOpacity>} />
            </CustomModal>

            {/* <KeyboardAccessoryNavigation previousDisabled={this.state.focusedInput <= 0} onNext={() => {
                this.state.focusedInput++;
                this.addressInputs[this.state.focusedInput].focus();
            }} 
            onPrevious={() => {
                this.state.focusedInput--;
                this.addressInputs[this.state.focusedInput].focus();
            }}
            nextDisabled={this.state.focusedInput >= 5} /> */}
        </View>
    }
}

const SelectionButton = (props) => <TouchableOpacity onPress={() => props.onClick()} style={{backgroundColor:props.selected?Colors.Blue:"white",padding:6, justifyContent:"center",alignItems:"center", flex:1,height:"100%", borderLeftWidth:(props.i == 1)?1:0,borderRightWidth:(props.i == 1)?1:0, borderColor:"lightgray", borderStyle:"solid"}}>
    
    <Text style={{textAlign:"center", color: (props.selected)?"white":"black", fontWeight:(props.selected)?"bold":"normal"}}>{props.text}</Text>

</TouchableOpacity>