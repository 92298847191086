import React from 'react';
import { View ,Text ,Image, TouchableOpacity, TouchableWithoutFeedback } from 'react-native';
import AntIcon from '@expo/vector-icons/AntDesign';
import Colors from '../Colors';
const cardIcons = {
    "american-express": require('../node_modules/react-native-credit-card-input/src/icons/stp_card_amex.png'),
    "diners-club": require('../node_modules/react-native-credit-card-input/src/icons/stp_card_cvc_amex.png'),
    "discover": require('../node_modules/react-native-credit-card-input/src/icons/stp_card_discover.png'),
    "jcb": require('../node_modules/react-native-credit-card-input/src/icons/stp_card_jcb.png'),
    "mastercard": require('../node_modules/react-native-credit-card-input/src/icons/stp_card_mastercard.png'),
    "unknown": require('../node_modules/react-native-credit-card-input/src/icons/stp_card_unknown.png'),
    "visa": require('../node_modules/react-native-credit-card-input/src/icons/stp_card_visa.png')
  }


export default class SavedCard extends React.Component {

    constructor(props) {
        super(props);

        
    }

    render() {
        return <TouchableWithoutFeedback onPress={() => {
            if(this.props.onPress) this.props.onPress();
        }} >
            <View style={{flexDirection:"row", alignItems:"center",...this.props.style, marginTop:(this.props.margin != undefined)?this.props.margin:20, marginBottom:(this.props.margin != undefined)?this.props.margin:0,justifyContent:"space-between"}}>
            {/* <IonIcon color={Colors.Blue}  name='add' size={25}/> */}
            
            <View style={{flexDirection:"row", alignItems:"center"}}>
            <Image style={{height:30,width:45}} resizeMode='contain'  source={cardIcons[this.props.card.type]} />
            <Text style={{fontSize:18, marginLeft:5}}>{this.props.card.name}</Text>
            </View>
            <View style={{marginLeft:15,flexDirection:"row", alignItems:"center"}}>
            <Text style={{fontSize:18, fontWeight:"600"}}>{this.props.card.number.substring(0,4)} {this.props.card.number.substring(4)}•• </Text>
            {this.props.selected && <AntIcon name='checkcircle' color={Colors.Blue} size={20} style={{marginLeft:10}} />}
            </View>
            </View>
        </TouchableWithoutFeedback>
    }
}