import * as React from "react"
import { Animated } from "react-native";
import {useSharedValue,useAnimatedProps} from "react-native-reanimated";
import Svg, { G, Path } from "react-native-svg"


export default class DemandIcon extends React.Component {

    constructor() {
        super();

        this.state = {value: 0};
        this.animatedValue = new Animated.Value(0);
        Animated.loop(Animated.timing(this.animatedValue, {toValue: 2, duration:3000, useNativeDriver:false, delay:0})).start();
        //Animated.loop(Animated.spring(this.animatedValue, {toValue: 1, useNativeDriver:false, })).start();

        
        this.animatedValue.addListener((s) => {
            //this.setState({value: s});
            this.setState({value:s.value});
        })
    }

    render() {
        var size = (this.props.size || 3);
        return <Svg
            xmlns="http://www.w3.org/2000/svg"
            width={55.76/(size-.05)}
            height={64.84/(size)}
            viewBox="222.12 217.58 55.76 64.84"
            xmlSpace="preserve"
            style={{marginRight:5}}
            {...this.props}
        >
            <G>
            <Path
                style={{
                stroke: "#000",
                strokeWidth: 0,
                strokeDasharray: "none",
                strokeLinecap: "butt",
                strokeDashoffset: 0,
                strokeLinejoin: "miter",
                strokeMiterlimit: 4,
                fill: "#ff0080",
                fillRule: "nonzero",
                opacity: Math.abs(1-Math.abs(0-this.state.value)),
                
                }}
                vectorEffect="non-scaling-stroke"
                transform="translate(236.07 268.48)"
                d="M-13.935 13.942V2.223l27.87-13.922v-2.243V2.223z"
            />
            <Path
                style={{
                stroke: "#000",
                strokeWidth: 0,
                strokeDasharray: "none",
                strokeLinecap: "butt",
                strokeDashoffset: 0,
                strokeLinejoin: "miter",
                strokeMiterlimit: 4,
                fill: "#ff0080",
                fillRule: "nonzero",
                opacity: Math.abs(1-Math.abs(0-this.state.value)),
                }}
                vectorEffect="non-scaling-stroke"
                transform="matrix(-1 0 0 1 263.94 268.48)"
                d="M-13.935 13.942V2.223l27.87-13.922v-2.243V2.223z"
            />
            <Path
                style={{
                stroke: "#000",
                strokeWidth: 0,
                strokeDasharray: "none",
                strokeLinecap: "butt",
                strokeDashoffset: 0,
                strokeLinejoin: "miter",
                strokeMiterlimit: 4,
                fill: "#ff0080",
                fillRule: "nonzero",
                opacity: Math.abs(1-Math.abs(.5-this.state.value)),
                }}
                vectorEffect="non-scaling-stroke"
                transform="translate(236.06 249.95)"
                d="M-13.935 13.942V2.223l27.87-13.922v-2.243V2.223z"
            />
            <Path
                style={{
                stroke: "#000",
                strokeWidth: 0,
                strokeDasharray: "none",
                strokeLinecap: "butt",
                strokeDashoffset: 0,
                strokeLinejoin: "miter",
                strokeMiterlimit: 4,
                fill: "#ff0080",
                fillRule: "nonzero",
                opacity: Math.abs(1-Math.abs(.5-this.state.value)),
                }}
                vectorEffect="non-scaling-stroke"
                transform="matrix(-1 0 0 1 263.94 249.95)"
                d="M-13.935 13.942V2.223l27.87-13.922v-2.243V2.223z"
            />
            <Path
                style={{
                stroke: "#000",
                strokeWidth: 0,
                strokeDasharray: "none",
                strokeLinecap: "butt",
                strokeDashoffset: 0,
                strokeLinejoin: "miter",
                strokeMiterlimit: 4,
                fill: "#ff0080",
                fillRule: "nonzero",
                opacity: Math.abs(1-Math.abs(1-this.state.value)),
                }}
                vectorEffect="non-scaling-stroke"
                transform="translate(236.06 231.52)"
                d="M-13.935 13.942V2.223l27.87-13.922v-2.243V2.223z"
            />
            <Path
                style={{
                stroke: "#000",
                strokeWidth: 0,
                strokeDasharray: "none",
                strokeLinecap: "butt",
                strokeDashoffset: 0,
                strokeLinejoin: "miter",
                strokeMiterlimit: 4,
                fill: "#ff0080",
                fillRule: "nonzero",
                opacity: Math.abs(1-Math.abs(1-this.state.value)),
                }}
                vectorEffect="non-scaling-stroke"
                transform="matrix(-1 0 0 1 263.94 231.52)"
                d="M-13.935 13.942V2.223l27.87-13.922v-2.243V2.223z"
            />
            </G>
        </Svg>
    
    }
}   


