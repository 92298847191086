import React from 'react';
import { TouchableOpacity, AppState, View, Vibration,Animated,Text, Dimensions, TextInput, KeyboardAvoidingView, ScrollView, ActivityIndicator, Platform } from 'react-native';
import { WebView } from 'react-native-webview';
import { NavigationContainer } from '@react-navigation/native';
import { createNativeStackNavigator } from '@react-navigation/native-stack';
import * as Notifications from 'expo-notifications';
import API from './API';
import IonIcon from '@expo/vector-icons/Ionicons';
import EvilIcon from '@expo/vector-icons/EvilIcons';
import Colors from './Colors';
import Icon from '@expo/vector-icons/FontAwesome';
import lang from './assets/lang.json';
import AsyncStorage from '@react-native-async-storage/async-storage';
import RouteSettingsMenu from './RouteSettings';
import OrderDetailsMenu from './OrderDetails';
import OrderCompleteScreen from './OrderComplete';
import FontistoIcon from '@expo/vector-icons/Fontisto';
import ChatScreen from './ChatScreen';
import HomeScreen from './HomeScreen';
import ReferralScreen from './ReferralScreen';
import LoadingPanel from './LoadingPanel';
import { StatusBar } from 'expo-status-bar';
import {io, Socket} from 'socket.io-client';
import RBSheet from 'react-native-raw-bottom-sheet';
import OTPInputView from './components/otp';
import * as Updates from 'expo-updates';
const Stack = createNativeStackNavigator();

//TODO: CHANGE FOR WEB
import * as StoreReview from 'expo-store-review';
import * as Sentry from 'sentry-expo';

Sentry.init({
  dsn: 'https://4e8c5ebf218a485398aa4987103d89af@o1284608.ingest.sentry.io/6495477',
  enableInExpoDevelopment: false,
  debug: true, // If `true`, Sentry will try to print out useful debugging information if something goes wrong with sending the event. Set it to `false` in production
  ignoreErrors: ['Network request failed', 'Failed to fetch', 'NetworkError', 'withrealtime/messaging'],
});


import { LogBox } from 'react-native';

const Amplitude = Platform.select({
  web: require('./FakeAmplitude'),
  default: require('@amplitude/analytics-react-native')
})

if(Platform.OS != 'web') {
  Amplitude.init("bbe2e4bee764bd1a42971796e6aae313");
}



// import { Amplitude } from '@amplitude/react-native';

// const ampInstance = Amplitude.getInstance();
// ampInstance.init("bbe2e4bee764bd1a42971796e6aae313");

LogBox.ignoreLogs([
  "[react-native-gesture-handler] Seems like you\'re using an old API with gesture components, check out new Gestures system!",
]);

import * as Localization from 'expo-localization';
import AntIcon from '@expo/vector-icons/AntDesign';
import FinalizeAddressScreen from './FinalizeAddressScreen';
import InitialScreen from './InitialScreen';
import CustomModal from './CustomModal';
import { Avatar } from 'react-native-elements';
import { TouchableWithoutFeedback } from 'react-native';
import { Keyboard } from 'react-native';
import { CheckBox } from 'react-native-elements';
import PaymentMethod from './components/PaymentMethod';
import SavedCard from './components/SavedCard';
const LiteCreditCardInput = Platform.select({
  web: () => <View></View>,
  default: () => require('react-native-credit-card-input').LiteCreditCardInput,
})();

import OnboardingModal from './components/OnboardingModal';
import LoginModal from './components/LoginModal';
import BusinessLoginModal from './components/BusinessLoginModal';
import LogoSplash from './assets/logosplash.png';
import { Image } from 'react-native';
// i18n.translations = require('./assets/lang.json');
// i18n.locale = Localization.locale.split("-")[0];
// if(i18n.locale != 'tr' && i18n.locale != 'en') i18n.locale = 'en';
// i18n.fallbacks = true;
// i18n.defaultLocale = 'en';

Notifications.setNotificationHandler({
  handleNotification: async () => ({
    shouldShowAlert: true,
    shouldPlaySound: true,
    shouldSetBadge: false,
  }),
});

function isNumeric(n) {
  return !isNaN(parseFloat(n)) && isFinite(n);
}

// export default Sentry.Native.wrap(App);

export default class App extends React.Component {

  constructor() {
    super();

    

    this.state = {loadingBar: false, loaded: false, reviewOrder: undefined, favoriteDriver: false, hideSplash: false, tryingConnection: true}
    this.connectionModalOpacity = new Animated.Value(1);
    global.showReview = (order,reviewCallback) => {
      this.setState({reviewOrder: order,starCount: 5,tip: 0,tipText:"",comment:"",tos: undefined, tosAgreed: false, favoriteDriver: false});
      if(this.reviewOrderModal) this.reviewOrderModal.open();

      this.reviewCallback = reviewCallback;
    }

    global.showLoadingPanel = () => {
      this.setState({loadingBar: true, error: undefined});
    }

    global.getText = (t,v) => {
      var val = lang[global.locale][t] || lang["en"][t] || t;

      for(var key in v) {
          val = val.replaceAll("{{"+key+"}}", v[key]);
      }
      return val;

      //return i18n.t(t,v);
    }

    global.hideLoadingPanel = () => {
      this.setState({loadingBar: false});
    }

    global.showError = (error,str) => {
      global.errorOKPressed = undefined;
      if(isNumeric(error)) {
        var nError = global.getText("errors")[error];
        if(!nError) error = global.getText("operationFailed") + error;
        else {error = nError};
      }

      if(str) error += ", " +str;
     
      this.setState({error: error,loadingBar:true});
    }

    global.showCVCModal = (callback) => {
      this.cvcModalCallback = callback;

      this.savedCardCVCModal.open();

      
      setTimeout(() => {
          this.savedCardCVCInput.focusField(0);
      }, 500);  
    }

    global.showTOS = (tos) => {
      
      fetch(tos.url).then((e) => e.text()).then((e) => {
        
        this.setState({tos: e.split("\n").join("<br>"),tosAgreed:false})
        this.agreementModal.open();
      })
      
    }


    // global.socket = {};
    // global.socketRegistery = {};
    // global.socket.on = (title, callback) => {
    //   if(!global.socketRegistery[title]) global.socketRegistery[title] = [];
    //   global.socketRegistery[title].push(callback);

    //   return {t: title, callback};
    // }

    // global.socket.unset = (data) => {
    //   if(!data.t) return;

    //   if(global.socketRegistery[data.t]) {
    //     var indx = global.socketRegistery[data.t].indexOf(data.callback);

    //     if(indx != -1) global.socketRegistery[data.t].splice(indx,1);
    //   }
    // }

      // if(!global.socketRegistery) global.socketRegistery = {};


    global.startPayment = (url, body, callback) => {
      global.paymentCallback = callback;

      if(typeof body.paymentMethod != 'number' && !body.paymentDetails.cvc) {
        this.savedCardCVCModal.open();

        setTimeout(() => {
          this.savedCardCVCInput.focusField(0);
      }, 500);  
        global.cvcEntered = (cvc) => {
          body.paymentDetails.cvc = cvc;
          global.startPayment(url,body, callback);
        }
        return;
      }

      global.showLoadingPanel();
      
      API.fetch(url, body).then((e) => {

          if(e.savedCard) {
            if(!API.user.cards) API.user.cards = [];
            API.user.cards.push(e.savedCard);
            this.forceUpdate();
          }

          if(e.error) {
            global.paymentCallback(e);
          } else if(e.UCD_URL) {
            global.hideLoadingPanel();
            this.setState({webViewSource: e.UCD_URL});
            this.secureCustomModal.open();
          } else {
            global.paymentCallback(e);
          }
      })
    }

    global.promptLogin = (a) => {
      if(!this.loginModal) return;

      global.onLoginCallback = a;
      this.loginModal.open();
      
    }
    
    global.promptLoginBusiness = (a) => {
      if(!this.loginModal) return;

      global.onLoginCallback = a;
      this.businessLoginModal.open();
    }


    this.popupAnimation = new Animated.Value(0);
    global.showPopup = (header, body, align) => {
      this.setState({popup: {header, body, custom: false}, popupAlign: align || "center"});
      Animated.timing(this.popupAnimation, {toValue:1, useNativeDriver: true, duration:100}).start();
    }

    global.showCustomPopup = (header, body, align) => {
      this.setState({popup: {header, body, custom: true}, popupAlign: align || "center"});
      Animated.timing(this.popupAnimation, {toValue:1, useNativeDriver: true, duration:100}).start();
    }

    global.checkSocketConnection = () => {

      
     
      


      var found = API.user != undefined;
      
      // if(API && API.user && API.user.orders) {
      //   API.user.orders.forEach((order) => {
      //     if(order.status >= 0 && order.status < 4) found = true;
      //   })
      // }

      
      
      if(found && (global.socket == null)) {
        
        var socket = io(API.SOCKET_ADDRESS, {auth: {
          type: 0,
          token: API.token
        }});
        

        socket.on("error", (e) => console.log(e));
        socket.onAny((event, e) => {
          console.log("123123");
          console.log("Any event ",event,e);
        })
        socket.bind = (emitTitle, callback) => {
          socket.on(emitTitle, callback);

          return () => {
            socket.off(emitTitle, callback);
          };
        }
        global.socket = socket;
      } else if(!found && global.socket != null) {
        

        try {
          global.socket.removeAllListeners();
          global.socket.disconnect();

        } catch {

        }
        global.socket = undefined;
      }
    }

    /*this.appStateSubscription = AppState.addEventListener('change', nextAppState => {

      if(nextAppState == 'active')
      {
        if(global.websocket) global.websocket.close();

        global.websocket = null;

        if(global.checkSocketConnection)
          global.checkSocketConnection();
      }
    })*/

    
    this.notifSub = Notifications.addNotificationResponseReceivedListener((s) => {
        var data = s.notification.request.content.data;

        if(data._id != undefined) {

          API.fetch('getorder', {_id: data._id}).then((data) => {
            if(data.error) return;

            // var or = API.user.orders.find((s) => s._id == data._id);

            // if(or != null) {
            //   var indx = API.user.orders.indexOf(or);

            //   API.user.orders.splice(indx, 1);
            //   API.user.orders.push(data.order);
            // }
            this.navigation.navigate('OrderPending', {order:data});
            
          })
        }

        if(data.review != undefined) {
          StoreReview.requestReview();
        }
        
        if(data._chat != undefined) {
          // API.fetch('getorder', {_id: data._chat}).then((order) => {
          //   if(!order.messages) data.order.messages = [];
            
          //   this.navigation.navigate('OrderPending', {order: order, pop:true});
          //   this.navigation.navigate('Chat',order);
            
          // })
        
          var driverDetails = (data.driverName)?{name: data.driverName}:undefined;
          this.navigation.navigate('Chat', {_id: data._chat, driverDetails}); 
        }

        if(data.support) {
          
        }
        
    });


    // global.socket.on('chat', ({_id, message}) => {
            
            
    //   Vibration.vibrate();
      
    //   var order = API.user.orders.find((s) => s._id == _id);
      
    //   order.notification = true;
    //   if(global.onChatMessage) global.onChatMessage(_id, message);
    //   //console.log(order);
      
    //   global.refreshMain();
    // })

    // global.socket.on('orderCharge', ({_id, charge}) => {
    
    //   var order = API.user.orders.find((s) => s._id == _id);
    //   if(!order) return;


    //   if(!order.charges) order.charges = [];

    //   order.notification = true;
    //   order.charges.push(charge);
    //   global.refreshMain();
    // })

    // global.socket.on('orderUpdate', ({_id, status, driverDetails, code}) => {
    //   var order = API.user.orders.find((s) => s._id == _id);

    //   if(order)
    //   {
    //     if(driverDetails) order.driverDetails = driverDetails;
    //     order.status = status;
    //     order.notification = true;
    //     if(code) order.code = code;
    //     global.refreshMain();
    //     //this.forceUpdate();
    //   }

    // })

    this.login();

    AsyncStorage.getItem("searchAddresses").then((e) => {
      
      if(e) {
        global.addresses = JSON.parse(e);
      } else {
        global.addresses = {recents: [], saved: []};
      }
      
      this.forceUpdate();
    })
  }

    
  
  async login() {
    
    //TODO: REMOVE
    //console.log(await Notifications.getDevicePushTokenAsync());

    if(Platform.OS == 'web' && window && window.location && window.location.search.includes("sessionToken")) {
      const webToken = window.location.search.substring(14);

      if(webToken) {
        global.locale = 'tr';
        API.fetch("webservices/token", {sessionToken: webToken}).then(e => {

          console.log(e);
          this.setState({loaded: true});
            Animated.timing(this.connectionModalOpacity, {toValue: 0, useNativeDriver: true, duration:200}).start(() => this.setState({hideSplash: true}));
        })

        return;


      }
    }



    var key = await AsyncStorage.getItem("bearerToken");
    
    if(key == undefined) {
      var v = await AsyncStorage.getItem("setLocale")
      if(v) {
        global.locale = v;
      }
      //this.setState({loaded: true});
      key = "";

    }
    this.showOnboardingModal = (Platform.OS != 'web' && await AsyncStorage.getItem("onboardingModal")) == undefined;
   
        API.fetch('token', {token: key},true).then(async (data) => {
          
            // if(data.user)
            //     this.redirectTo("Home");
            // else
            //     this.setState({loaded: true});
            if(data.error) {

              if(data.registerCampaigns) API.registerCampaigns = data.registerCampaigns;

              

              await AsyncStorage.removeItem("bearerToken");
              var v = await AsyncStorage.getItem("setLocale")
              if(v) {
                global.locale = v;
              }
            } else {
              if(API.user && !API.user.name && this.loginModal) {
                global.promptLogin();
              }
            }
            this.setState({loaded: true});
            Animated.timing(this.connectionModalOpacity, {toValue: 0, useNativeDriver: true, duration:200}).start(() => this.setState({hideSplash: true}));
        }).catch((e) => {
          this.setState({tryingConnection: false});
          global.errorOKPressed = () => {
            console.log("pressed");
            this.setState({tryingConnection: true});
            this.login();
          }
          
        })
    
  }
  
  componentWillUnmount() {
    this.notifSub.remove();
    if(global.eventSource) {
      global.eventSource.removeAllEventListeners();
        global.eventSource.close();
      global.eventSource = undefined;
      
    }
    AsyncStorage.setItem("searchAddresses", JSON.stringify(global.addresses));
    if(this.appStateEvent) this.appStateEvent.remove();
  }

  componentDidMount() {

   
    this.lastCheckedForUpdates = Date.now()
      this.appStateEvent = AppState.addEventListener("change",async (nextState) => {
          if(nextState == 'active' && this.lastCheckedForUpdates + 1800000 < Date.now()) {
            this.lastCheckedForUpdates = Date.now();
            try {
              const update = await Updates.checkForUpdateAsync()
              if (update.isAvailable) {
                global.showError(999);
                await Updates.fetchUpdateAsync()
                Updates.reloadAsync()
                // NOTIFY USER HERE
                
              }
            } catch (e) {

                // HANDLE ERROR HERE
            }
          }

      })
  }

  sendReview(paymentDetails,cvc) {
    
    global.showLoadingPanel();
    this.reviewOrderModal.close();
    
    API.fetch("review", {_id: this.state.reviewOrder._id,paymentDetails,cvc, favoriteDriver: this.state.favoriteDriver, review: {tip: this.state.tip, message: this.state.comment,star: this.state.starCount}}).then((e) => {
      console.log(e);
      if(!e.error || e.error >= 3000) {
        this.state.reviewOrder.review = true;
        
      } 

      if(e.UCD_URL) {
        global.paymentCallback = (response => {
          this.reviewOrderModal.close();
          if(!e.error) global.hideLoadingPanel();
          if(this.reviewCallback) this.reviewCallback();
          this.setState({reviewOrder: undefined});
        });
        this.setState({webViewSource: e.UCD_URL});
        this.secureCustomModal.open();
        
        global.hideLoadingPanel();
        return;
      }
      //this.state.reviewOrder.review = {tip: this.state.tip, message: this.state.comment,star: this.state.starCount};
     
      this.reviewOrderModal.close();
      if(!e.error) global.hideLoadingPanel();
      if(this.reviewCallback) this.reviewCallback();
      this.setState({reviewOrder: undefined});


      setTimeout(() => {
        try {
        StoreReview.requestReview();
        } catch {

        }
      }, 2000);
    })
  }

  render() {

    
      return <>
      {this.state.loaded && <NavigationContainer initialState={{index: 0, routes: [{name:"Home"}]}} ref={(a) => this.navigation = a}>

        <StatusBar style='dark' translucent={true} animated={false} />
        <Stack.Navigator>
          
          {/* <Stack.Screen name="Login" component={LoginScreen} options={{headerShown:false}} /> */}
          <Stack.Screen name="Home" component={HomeScreen} options={{headerShown:false}} />
          <Stack.Screen name='FinalizeAddress' component={FinalizeAddressScreen} options={({navigation})=>({title: global.getText("finalizeAddressTitle"),headerShown:false, headerShadowVisible:false, headerLeft: () => 
            <TouchableOpacity onPress={() => navigation.pop()}><EvilIcon name='chevron-left' size={50} style={{marginLeft:-10}} /></TouchableOpacity>
          })} />
          <Stack.Screen name='Route' component={RouteSettingsMenu} options={({navigation})=>({headerShown:false})} />
          <Stack.Screen name='Order' component={OrderDetailsMenu} options={({navigation})=>({headerShown:false})} />
          <Stack.Screen name='OrderPending' component={OrderCompleteScreen} options={{headerShown: false}} />
          <Stack.Screen name="Chat" component={ChatScreen} options={{headerShown: false}} />
          <Stack.Screen name='Referral' component={ReferralScreen} options={{headerShown: false}} />
       
        </Stack.Navigator>

        

        <CustomModal onClose={() => {
          API.user.reviewOrder = undefined;
          API.fetch("review", {_id: this.state.reviewOrder._id, review: undefined}).then((e) => {
            this.setState({reviewOrder: undefined});
            
          })
        }} backgroundColor="white" contentContainerStyle={{backgroundColor:"white"}} scrollView={false} title={global.getText("review")} ref={(a) => {
          if(a) {
            this.reviewOrderModal = a
            if(this.state.reviewOrder && !a.isOpened()) a.open();
          }
        }}>
           <View style={{flex:1}}>

            <ScrollView>
            {this.state.reviewOrder != undefined && <TouchableWithoutFeedback onPress={() => Keyboard.dismiss()}><KeyboardAvoidingView behavior='position' keyboardVerticalOffset={100} style={{paddingTop:20,flex:1}}>
             <View style={{width:"100%",justifyContent:"space-between"}}>
              <View style={{flex:1,alignItems:"center"}}>
            <Avatar imageProps={{resizeMode:"cover"}} source={{uri: 'https://tasimacim-driver-pictures.s3.eu-central-1.amazonaws.com/'+this.state.reviewOrder.driverDetails._id+'.jpeg'}}  size={80} rounded title={"D"} containerStyle={{backgroundColor:"#FEB600"}} />
            <Text style={{fontSize:25, marginTop:10, fontWeight:"400"}}>{this.state.reviewOrder.driverDetails.name}</Text>    
            
            <View style={{flexDirection:"row", marginTop:20}}>{starCount.map((s, i) => <Star onPress={() => this.setState({starCount: s})} selected={this.state.starCount >= s} i={i} key={i} />)}</View>
            
            <TextInput value={this.state.comment} onChangeText={(a) => this.setState({comment: a})} multiline={true} returnKeyType='done' blurOnSubmit={true} style={{padding:10,marginTop:20, borderWidth:1, borderColor:Colors.Gray, borderRadius:5, width:"80%", height:Dimensions.get('screen').height*.1}} placeholder={global.getText("writeReview")}  />
            <Text style={{textAlign:"left", color:"gray", marginTop:5}}>{global.getText("anonymousReview")}</Text>
            
            <Text style={{fontWeight:"500", marginTop:20, fontSize:16}}>{global.getText("addATip")}</Text>


            <View style={{width:"80%",flexDirection:"row",marginTop:10}}>
              {tipCount.map((x,i) => <TipButton selected={this.state.tip == x && this.state.tipText == ""} onPress={() => {
                if(this.state.tip == x && this.state.tipText == "") 
                  return this.setState({tip: 0, tipText: ""});
                
                this.setState({tip: x, tipText: ""})
              }} value={x} key={i} i={i} />)}
            </View>

            <TextInput value={this.state.tipText} onChangeText={(t)=> {
              var f = parseInt(t)
              
              
              this.setState({tipText: isNaN(f)?"":(f+""), tip: isNaN(f)?0:f});
             
            }} keyboardType='number-pad' placeholderTextColor={"gray"} style={{paddingBottom:5, textAlign:"center",marginTop:20,fontSize:16, width:"80%", borderBottomWidth:1, borderBottomColor:Colors.Gray}} placeholder={global.getText("bonusCustomAmount")} />
            
            <View style={{width:"80%",flexDirection:"column",marginTop:40}}>
              <CheckBox checked={this.state.favoriteDriver} onPress={() => this.setState({favoriteDriver: !this.state.favoriteDriver})} checkedColor={Colors.Blue} title={global.getText("addToFavorites")} containerStyle={{backgroundColor:"transparent", borderWidth:0, padding:0,margin:0,marginLeft:0,paddingLeft:0}} />
              <Text style={{color:"gray",marginTop:5}}>{global.getText("addToFavoritesDesc")}</Text>
            </View>
            
            </View>

            
            
            </View>


            

            </KeyboardAvoidingView>
            </TouchableWithoutFeedback>} 
            </ScrollView>

            <TouchableOpacity onPress={async () => {
            
              
              API.user.reviewOrder = undefined;
              if(this.state.tip) {

                return this.reviewOrderTipModal.open();
              }
              
              this.sendReview();
            //this.setState({editTime: false});


}} style={{backgroundColor:Colors.Blue, paddingBottom:11, paddingTop:11, display:"flex", justifyContent:"center", alignItems:"center", borderRadius:30,width:"90%",marginLeft:"5%",position:"absolute",bottom:25}}><Text style={{color:"white", fontWeight:"700", fontSize:17}}>{global.getText("submit")}</Text></TouchableOpacity>
            
            </View> 
            
            

            {API.user && <CustomModal ref={(a) => this.reviewOrderTipModal = a}>
              <View style={{backgroundColor:"white", paddingLeft:"5%", paddingRight:"5%", marginTop:10}}>

              <TouchableOpacity onPress={() => {
                this.onlinePaymentModal.open();
              }} style={{flexDirection:"row", paddingTop:10, paddingBottom:10, alignItems:"center", justifyContent:"space-between"}}>
                <View style={{flexDirection:"row", alignItems:"center"}}>
                <IonIcon name='card-outline' size={25}/>
                    <Text style={{fontSize:17, marginLeft:15}}>{global.getText("paymentMethods")[0]}</Text>
                </View>

                
                

                
                
              </TouchableOpacity>
                

                {API.user.cards.map((x, i) => <SavedCard onPress={() => {
                    
                    this.reviewOrderTipModal.close();
                    this.reviewCardId = x._id;
                    this.savedCardCVCModal.open();
                    setTimeout(() => {
                      this.savedCardCVCInput.focusField(0);
                    }, 500);
                    //this.sendReview(x._id);
                }} margin={8} card={x} key={i} />)}
              </View>




              
            </CustomModal>}

            
            

            <CustomModal headerShown={false} ref={(a) => this.onlinePaymentModal = a} scrollView={false} animationType='slide'>

        <View style={{backgroundColor:"#F2F4F3", flex:1}}>
            <View style={{height:90, marginBottom:20, backgroundColor:"white", width:"100%", justifyContent:"flex-end", display:"flex" ,alignItems:"center"}}>
                <Text style={{fontWeight:"700", bottom:14, fontSize:17, position:"absolute"}}>{global.getText("onlinePayment")}</Text>
                <TouchableOpacity onPress={() => this.onlinePaymentModal.close()} style={{position:"absolute", bottom:10, left:"5%"}}>
                <IonIcon name='close' size={30} />
                </TouchableOpacity>

                <TouchableOpacity onPress={() => {
                    
                    if(!this.onlinePaymentCardDetails.valid || this.state.onlinePaymentCardOwner.trim().length <= 1) return;

                    
                    this.sendReview({number: this.onlinePaymentCardDetails.values.number, expiry: this.onlinePaymentCardDetails.values.expiry, cvc: this.onlinePaymentCardDetails.values.cvc, owner: this.state.onlinePaymentCardOwner});
                    this.onlinePaymentModal.close();
                    this.reviewOrderTipModal.close();
                    // this.state.savedCards.push({type: capitalizeFirstLetter(this.newCardDetails.values.type), number: this.newCardDetails.values.number.split(" ")[this.newCardDetails.values.number.split(" ").length-1]});

                    // this.setState({creditCardModalVisible: false})

                    // setTimeout(() => {
                    //     this.setState({paymentMethodOpened: false});
                    // }, 20);
                    
                    }} style={{position:"absolute", bottom:15, right:"5%"}}>
                    <Text style={{fontSize:16, fontWeight:"600"}}>{global.getText("pay")}</Text>

                </TouchableOpacity>
            </View>
            <View style={{paddingLeft:"3%", paddingRight:"3%"}}>

                <FontistoIcon  name='credit-card' size={Dimensions.get('window').width/3} style={{textAlign:"center", marginBottom:20}} color={Colors.LightBlue}/>
                

                
                <View style={{borderWidth:1,borderRadius:5, borderColor:"#BCBBBF",backgroundColor:"white", marginTop:10}}>
            <TextInput autoCapitalize='words' ref={(s) => this.creditCardOwnerTextInput = s} value={this.state.onlinePaymentCardOwner} autoFocus={true} onChangeText={(e) => this.setState({onlinePaymentCardOwner: e})} returnKeyType='next' onSubmitEditing={() => {this.liteCreditCardInput.focus()}} placeholder={global.getText("cardOwner")} placeholderTextColor='gray' style={{paddingLeft:10,paddingRight:10, justifyContent:"center",height:40}} />
            </View>
            <View style={{borderWidth:1,borderRadius:5, borderColor:"#BCBBBF",backgroundColor:"white",marginTop:10}}>
            <LiteCreditCardInput ref={(a) => this.liteCreditCardInput = a} onChange={(a) => {
              this.onlinePaymentCardDetails = a
              
            }} cardFontFamily={''} />
            </View>
            
            
            
            </View>
        </View>
        
    </CustomModal>

    
            
            


        </CustomModal>







        <CustomModal closeShown={false} backgroundColor="white" contentContainerStyle={{backgroundColor:"white"}} scrollView={false} title={global.getText("customerAgreement")} ref={(a) => {
          if(a) {
            this.agreementModal = a
            
          }
        }}>
           <View style={{flex:1}}>
            
            <ScrollView contentContainerStyle={{flex:1, paddingLeft:"2%", paddingRight:"2%"}}>
              
            {this.state.tos != undefined && (Platform.OS == 'web'?<iframe style={{ borderWidth: 1, borderColor: 'transparent',flex:1}} srcDoc={this.state.tos} />:<WebView scrollEnabled style={{ borderWidth: 1, borderColor: 'transparent'}} originWhitelist={['*']} source={{html: this.state.tos}} />)}
            {/* agreement.split("\n").join("<br>") */}
            {this.state.tos == undefined && <ActivityIndicator />}
                {/* <Text>{agreement}</Text> */}
            </ScrollView>

            <View style={{paddingBottom:Platform.OS =='ios'?30:20, paddingLeft:"5%", paddingRight:"5%",borderTopWidth:2, borderTopColor:"rgba(0,0,0,.2)"}}>
              <CheckBox checked={this.state.tosAgreed} onPress={() => {
                if(this.state.tos == undefined) return;

                this.setState({tosAgreed: !this.state.tosAgreed})
                
                }} size={35} textStyle={{fontSize:15}} containerStyle={{padding:5, paddingLeft:0, backgroundColor:"white", borderWidth:0}} title={"Sözleşmeyi okudum ve kabul ediyorum"} />

              <TouchableOpacity onPress={() => {
                if(this.state.tos == undefined || !this.state.tosAgreed) return;
            
                global.showLoadingPanel();
                API.fetch("signtos").then((e) => {
                  if(!e.error) {
                    this.setState({tos: undefined, tosAgreed: false});
                    global.hideLoadingPanel();
                    this.agreementModal.close();
                  }
                })
  
          }} style={{backgroundColor:Colors.Blue, paddingBottom:12, paddingTop:12, display:"flex", justifyContent:"center", alignItems:"center", borderRadius:30}}><Text style={{color:"white", fontWeight:"700", fontSize:17}}>{global.getText("agree")}</Text></TouchableOpacity>
            </View>
            
            </View> 

            
            
            
        </CustomModal>


        

        <CustomModal closeShown={false} scrollView={false} title={"3D Secure"} ref={(a) => {
              
              this.secureCustomModal = a;
            }}>

              <WebView javaScriptEnabled={true} onMessage={(a) => {
                var json = JSON.parse(a.nativeEvent.data);
                
                if(!json || !json._id) {
                    global.showError(global.getText("errors")["2000"]+", "+json.str);
                    //this.state.reviewOrder.review = true;
                } else {
                    
                    global.hideLoadingPanel();
                    //this.state.reviewOrder.review = true;
                   
                    
                }
                /*this.state.reviewOrder = undefined;

                
                this.forceUpdate();
                if(this.reviewCallback) this.reviewCallback();
                this.reviewOrderModal.close();*/
                global.showLoadingPanel();
                this.secureCustomModal.close();
                global.paymentCallback(json);
                
              }} automaticallyAdjustContentInsets={true}  source={{uri: this.state.webViewSource}} />
        </CustomModal>

        <OnboardingModal active={this.showOnboardingModal} />
        
        <LoginModal ref={(a) => {
          this.loginModal = a
          if(a && API.user && !API.user.name) this.loginModal.open();
        }} />

        <BusinessLoginModal ref={(a) => {
          this.businessLoginModal = a;
        }} />


<RBSheet ref={(a) => {
                this.savedCardCVCModal = a
                }} height={200} customStyles={{container: {borderTopLeftRadius: 5, borderTopRightRadius:5}}} closeOnDragDown={false} closeOnPressMask={true} snapPoints={["40%"]}>
                    <View style={{display:"flex", justifyContent:"space-between",  flex:1, paddingBottom:40, paddingTop: 0, paddingLeft:"5%", paddingRight:"5%", paddingTop:20, borderTopLeftRadius: 5, borderTopRightRadius:5}}>
                        <View>
                        <Text style={{fontWeight:"600", fontSize:20}}>{global.getText("3dSecureTitle")}</Text>

                        <Text style={{color: "gray", fontSize:12, marginTop:5}}>{global.getText("3dSecureModalDescription")}</Text>
                        
                        </View>

                        <OTPInputView ref={(a) => this.savedCardCVCInput = a} onCodeFilled={(code) => {
                            
                            
                            this.savedCardCVCModal.close();
                            // this.reviewOrderModal.close();
                            // this.sendReview(this.reviewCardId,code);

                            global.cvcEntered(code);



                            


                        }} pinCount={3} style={{width:"45%", height:100,justifyContent:"flex-start"}} autoFocusOnLoad={false}  codeInputHighlightStyle={{borderWidth:0, borderBottomWidth:1, borderColor:Colors.Blue}} codeInputFieldStyle={{borderWidth:0, borderBottomWidth:0,fontSize:16,width:50,height:50, color:"black", backgroundColor:Colors.Gray, borderColor:Colors.Blue}}/>
                        <TouchableOpacity onPress={() => this.savedCardCVCModal.close()} style={{position:"absolute",right:10, top:20}}>
                            <AntIcon name='close' size={20} />
                        </TouchableOpacity>
                    </View>
                </RBSheet>

{/* <RBSheet ref={(a) => {
                this.savedCardCVCModal = a
                }} height={200} customStyles={{container: {borderTopLeftRadius: 5, borderTopRightRadius:5}}} closeOnDragDown={false} closeOnPressMask={true} snapPoints={["40%"]}>
                    <View style={{display:"flex", justifyContent:"space-between",  flex:1, paddingBottom:40, paddingTop: 0, paddingLeft:"5%", paddingRight:"5%", paddingTop:20, borderTopLeftRadius: 5, borderTopRightRadius:5}}>
                        <View>
                        <Text style={{fontWeight:"600", fontSize:20}}>{global.getText("3dSecureTitle")}</Text>

                        <Text style={{color: "gray", fontSize:12, marginTop:5}}>{global.getText("3dSecureModalDescription")}</Text>
                        
                        </View>

                        <OTPInputView ref={(a) => this.savedCardCVCInput = a} onCodeFilled={(code) => {
                            
                            
                            this.savedCardCVCModal.close();
                            this.cvcModalCallback(code);



                            


                        }} pinCount={3} style={{width:"45%", height:100,justifyContent:"flex-start"}} autoFocusOnLoad={false}  codeInputHighlightStyle={{borderWidth:0, borderBottomWidth:1, borderColor:Colors.Blue}} codeInputFieldStyle={{borderWidth:0, borderBottomWidth:0,fontSize:16,width:50,height:50, color:"black", backgroundColor:Colors.Gray, borderColor:Colors.Blue}}/>
                        <TouchableOpacity onPress={() => this.savedCardCVCModal.close()} style={{position:"absolute",right:10, top:20}}>
                            <AntIcon name='close' size={20} />
                        </TouchableOpacity>
                    </View>
                    
                </RBSheet> */}



        
      </NavigationContainer>}
      
      
      {!this.state.hideSplash && <Animated.View pointerEvents={'none'} style={{display:"flex",position:"absolute",left:0,right:0,top:0,bottom:0,opacity: this.connectionModalOpacity, flex:1,backgroundColor:"#0088bf", justifyContent:"center",zIndex:999999, alignItems:"center"}}>
          <Image source={LogoSplash} resizeMode='contain' style={{width:"100%"}} />

          <ActivityIndicator animating={this.state.tryingConnection} color={'white'} style={{position:"absolute", bottom:"20%"}} size={"large"} />
      </Animated.View>}

      {this.state.popup != undefined && <Animated.View style={{position:"absolute", left:0, right:0, top:0, bottom:0, backgroundColor:"rgba(0,0,0,.4)",opacity: this.popupAnimation, justifyContent:"center", alignItems:"center",zIndex:1000}}>
                <View style={{width:"80%", backgroundColor:"white", shadowColor: "#000",
shadowOffset: {
	width: 0,
	height: 2,
},
shadowOpacity: 0.25,
shadowRadius: 3.84,

elevation: 5, padding:20, alignItems:"center",borderRadius:10}}>

        {/* <TouchableOpacity onPress={() => {
            Animated.timing(this.popupAnimation, {useNativeDriver:true, toValue:0, duration:100}).start();
            setTimeout(() => {
                this.setState({popup: undefined});
            }, 100);
        }} style={{alignSelf:"flex-end",marginTop:-10, marginRight:-10}}><IonIcon name='close' size={23} /></TouchableOpacity> */}
    
    <Text style={{textAlign:"center",fontWeight:"600", marginTop:5}}>{this.state.popup.header}</Text>
    {!this.state.popup.custom && <Text style={{textAlign:this.state.popupAlign, marginTop:5}}>{this.state.popup.body}</Text>}
    {this.state.popup.custom && this.state.popup.body}

    <TouchableOpacity onPress={() => {
        Animated.timing(this.popupAnimation, {useNativeDriver:true, toValue:0, duration:100}).start();
        setTimeout(() => {
            this.setState({popup: undefined});
        }, 100);
    }} style={{backgroundColor:Colors.Blue, paddingTop:10, paddingBottom:10, marginTop:20,width:"100%", justifyContent:"center", alignItems:"center",borderRadius:50}}><Text style={{color:"white", fontSize:18, fontWeight:"600"}}>{global.getText("gotIt")}</Text></TouchableOpacity>
</View>
        </Animated.View>}

      {this.state.loadingBar && <LoadingPanel onPress={() => {
        this.setState({loadingBar:false})

        console.log("on press");
        if(global.errorOKPressed) global.errorOKPressed();
      }} error={this.state.error} />}



      </>
    
  }

}
var starCount = [1,2,3,4,5];
var tipCount =[5,15,30];

const TipButton =(props) => <TouchableOpacity onPress={() => props.onPress()} style={{borderWidth:(props.selected)?0:1,paddingTop:10, paddingBottom:10, flex:1,alignItems:"center",backgroundColor:(props.selected)?Colors.Blue:"white",borderRadius:5, borderColor:Colors.Gray,marginLeft:(props.i ==0)?0:10}}>
    <Text style={{fontWeight:"400",fontSize:16,color:(props.selected)?"white":"black"}}>{props.value}</Text>
</TouchableOpacity>

const Star = (props) => <TouchableOpacity onPress={() => {
  props.onPress()
}} style={{marginLeft:(props.i==0)?0:10}}><Icon name={(props.selected)?'star':'star'} color={(props.selected)?Colors.Blue:Colors.Gray} size={Dimensions.get('screen').width/10} /></TouchableOpacity>


// "cancelPrompts": {
//   "2": "Because your driver has reached the first waypoint you can only get a 50% refund on your order",
//   "1": "Because your driver is on the way you can only get a 75% refund on your order"
// },




async function initFacebook() {
  try {
    
    const {requestTrackingPermissionsAsync } = require('expo-tracking-transparency');
    const { status } = await requestTrackingPermissionsAsync(); 
   
  } catch(e) {
    console.error(e);
  }
}

initFacebook();