import React from 'react';
import {View, Text} from 'react-native';
import Colors from '../Colors';
import Icon from '@expo/vector-icons/FontAwesome';
import API from '../API';

export default class OrderReviewDestination extends React.Component {

    render() {
        return <View style={{alignItems:"center", display:"flex", flexDirection:"row", marginTop:10, paddingBottom:5}}>
            
            {this.props.first && <Icon  name='circle-o' color={Colors.Blue} size={24} style={{}} />}
            {!this.props.first && <Icon name='map-marker' color={Colors.Blue} size={30} style={{ marginLeft:2}} />}

             <Text style={{marginLeft:(this.props.first)?15:17, fontSize:15, flex:1}}>{API.getAddressLabel(this.props.selected)}</Text>
            {/* <Text style={{marginLeft:(this.props.first)?15:17, fontSize:15}}>1005 Lower Delta Rd, Singapore 099309</Text> */}
            </View>
    }
}