import React from 'react';
import { View, Text, TouchableOpacity } from 'react-native';
import FontAwesomeIcon from '@expo/vector-icons/FontAwesome';
import Colors from '../Colors';

export default class PaymentMethod extends React.Component {

    render() {
        return <View  style={{flexDirection:"row", paddingTop:10, paddingBottom:10, alignItems:"center", justifyContent:"space-between"}}>
        <View style={{flexDirection:"row", alignItems:"center"}}>
            {this.props.icon}
            <Text style={{fontSize:17, marginLeft:15}}>{this.props.title}</Text>
        </View>

        {!this.props.selected && <TouchableOpacity onPress={() => this.props.onPress()} style={{width:35,borderRadius:100, height:35, borderColor: "#A2A2A8", borderWidth:1.5, justifyContent:"center", alignItems:"center"}}>

        </TouchableOpacity>}

        {this.props.selected && <View style={{width:35, backgroundColor:Colors.Blue, borderRadius:100, height:35, justifyContent:"center", alignItems:"center"}}>
            <FontAwesomeIcon color={"white"} size={20} name='check' />

        </View>}
        
    </View>
    }
}