import React from 'react';
import { StyleSheet, Text, View, TouchableOpacity, Modal,Dimensions, TextInput, FlatList, KeyboardAvoidingView, ScrollView, Vibration, ActivityIndicator, Animated, Image, SafeAreaView, StatusBar, Platform, Alert, Keyboard, AppState } from 'react-native';
// import MapView, { Marker } from 'react-native-maps';

const MapView = Platform.select({
  web: () => require('./components/Map.web').default,
  default: () =>  require('./components/Map').default,
})();


// import * as MapView from './components/Map';
import Destination from './components/Destination';
import API from './API';
import AsyncStorage from '@react-native-async-storage/async-storage';
import Icon from '@expo/vector-icons/FontAwesome';
import Icon5 from '@expo/vector-icons/FontAwesome5';
import MaterialCommunityIcon from '@expo/vector-icons/MaterialCommunityIcons';
import AntIcon from '@expo/vector-icons/AntDesign';
import IonIcon from '@expo/vector-icons/Ionicons';
import { CommonActions } from '@react-navigation/native'
import Constants from 'expo-constants';
import RBSheet from "react-native-raw-bottom-sheet";
import Colors from './Colors';
import * as Location from 'expo-location';
//import Drawer from 'react-native-drawer';
class Drawer extends React.Component {
  constructor(props) {
    super(props);
  }

  render() {
    return <View style={{flex:1}}>{this.props.children}</View>
  }
}
import { Avatar } from 'react-native-elements';
import SidebarButton from './components/SidebarButton';
import OrderListElement from './components/OrderListElement';
import CustomModal from './CustomModal';
import CustomHorizontalModal from './CustomHorizontalModal';
// import * as Amplitude from '@amplitude/analytics-react-native';
const Amplitude = Platform.select({
  web: require('./FakeAmplitude').default,
  default: require('@amplitude/analytics-react-native')
})
import * as WebBrowser from 'expo-web-browser';
import * as MailComposer from 'expo-mail-composer';
import Popup from './components/Popup';
import MyDriversModal from './components/MyDriversModal';
import GoogleIcon from './assets/google.png';
import PromotionsModal from './components/PromotionsModal';
import PaymentModal from './components/PaymentModal';

function getInitials(name) {
  if(!name) return "";  
  const hasTokens = name.indexOf(' ') !== -1
    return name.substring(0, hasTokens ? 1 : 2) + (hasTokens ? name.charAt(name.lastIndexOf(' ') + 1) : '')
}

const rnd = (len, chars='ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789') => [...Array(len)].map(() => chars.charAt(Math.floor(Math.random() * chars.length))).join('')


export default class HomeScreen extends React.Component {

    constructor(props) {
      super(props);
      
      this.navigation = props.navigation;
      this.mapSelectionAnimator = new Animated.Value(0);

      

      
      this.state = {addressInput : false, ordersOpened: false, textInputText:"whereTo", emailAddressChange: "", orders: [], waypoints: (props.route.params && props.route.params.waypoints)?props.route.params.waypoints:[undefined, undefined], address: "", searchParameters: [], addressLocation: {}, addressMapSelectName:"", finalizingAddress: undefined, sidebarFade: new Animated.Value(0), addressSelectionModalVisible: false, addressSelectionText: "", addressSelectionAutocomplete: []};
      
      //LocationIQ.init("pk.84b5df6d558f715f4ff6a05417ba11eb");
  
      this.initMap();
      
      
      global.refreshMain = () => {
        this.forceUpdate();
      }

      // Facebook.initializeAsync({appId: "428688312402364",autoLogAppEvents: true}).then(async (e) => {
      //   //console.log(e);

        
      //   await Facebook.requestPermissionsAsync();
      //   // Facebook.getAdvertiserIDAsync().then((e) => {
      //   //   console.log(e);
      //   // })

      //   // Facebook.track("TestSync");
      // })

    

      // global.setAddress = (finalizingAddress) => {
      //   this.setState({addressSelectionModalVisible: false});
      //   //this.addressSelectionModal.close(true);

      //   if(this.state.addressInputIndex == -1) {
      //     this.state.waypoints.push(finalizingAddress);
      //   } else {
      //     this.state.waypoints[this.state.addressInputIndex] = finalizingAddress;
      //   }
      //   //this.setState({addressInput: false});

      //   var coordinates = [];
     
      //   this.state.waypoints.forEach((s) => {
      //       if(s)
      //         coordinates.push(s.region);
      //   })

      //   this.map.fitToCoordinates(coordinates, {edgePadding: {bottom: 40, top: 60, left:40, right: 40}, animated: true});
      // }

      global.getAddressInputIndex = () => {return this.state.addressInputIndex};
      
      global.setAddress = (address, close) => {



        if(this.state.addressInputIndex != -1)
        this.state.waypoints[this.state.addressInputIndex] = address;
      else
        this.state.waypoints.push(address);

        if(!close) {
        this.addressSelectionModal.close(true);
         if(this.selectOnMapModal) this.selectOnMapModal.close(true);
        }
        var coordinates = [];
     
        this.state.waypoints.forEach((s) => {
            if(s)
              coordinates.push({latitude: s.Point[1],longitude: s.Point[0], longitudeDelta: .01, latitudeDelta:.01});
        })
        if(coordinates.length > 1) {
        this.map.fitToCoordinates(coordinates, {edgePadding: {bottom: 40, top: 60, left:40, right: 40}, animated: true});
        } else {
          this.map.animateToRegion(coordinates[0], 1000);
        }


        console.log(this.state.waypoints);
      }

      
      //global.checkSocketConnection();
      this.autoCompleteSession = rnd(36);
      this.autoCompleteTries = 0;

      
    }


    
    
  
    async initMap() {

      

      return;
      const a = await Location.requestForegroundPermissionsAsync();
  
        //console.log(a);
  
        
  
      if(a.granted) {
        var loc = await Location.getCurrentPositionAsync({ accuracy: Location.Accuracy.Balanced});
        console.log(loc);
        //console.log(loc);
        loc.coords.latitudeDelta = 0.004;
        loc.coords.longitudeDelta = 0.004;
  
        var geo = (await Location.reverseGeocodeAsync(loc.coords))[0];
        geo = {city: geo.city, district: geo.district, region: geo.region, buildingNO: geo.streetNumber, contactNO:"",contactName:"", floorNO: "", doorNO:"", street: geo.street};
        var firstWaypoint = {geo, region: {latitude: loc.coords.latitude, longitude: loc.coords.longitude, longitudeDelta: 0.004, latitudeDelta: 0.004}};
        //this.setState({addressLocation: loc.coords});
        
  
        this.state.waypoints[0] = firstWaypoint;
        this.forceUpdate();
  
        this.map.animateToRegion(loc.coords, 1);
      }
    }
  
    componentDidMount() {
      
      // this.promotionsModal.open();
      if(API.user && API.user.reviewOrder) {
        
        global.showReview(API.user.reviewOrder);
        API.user.reviewOrder = undefined;
      }
      this.refreshNavigation = this.navigation.addListener('focus', () => this.forceUpdate());
      
      
      global.refreshMain = () => {
        this.forceUpdate();
      }
      

   

      
      //this.setState({ordersOpened:true});
      //this.sideMenu.open();
  
      //console.log(API.user);
      
      
    }

    async useCurrentLocation() {
      global.showLoadingPanel();
      const val = await this.reverseGeocode();
      
      if(val && !val.error && !val.err) {

        //this.setState({selectedAddress: val, addressSelectionText: val.Label});
        
        this.navigation.navigate('FinalizeAddress', {address: val});
        global.hideLoadingPanel();
      } else if(!val || !val.error) {
        global.showError(1001);
      }

      
      
    }

    

    reverseGeocode() {
      return new Promise(async resolve =>  {
        const res = await Location.requestForegroundPermissionsAsync();

        if(res.granted) {
          var loc = await Location.getCurrentPositionAsync({ accuracy: Location.Accuracy.Balanced});
          
          API.fetch('reversegeocode',{Position:[loc.coords.longitude, loc.coords.latitude]}).then((results) => {
            return resolve(results[0]);
          })
        } else global.hideLoadingPanel();
      })
      
      
    }

    openOrdersModal() {
      this.yourOrdersModal.open()
      this.setState({orders: []});
      global.showLoadingPanel();


      API.fetch('orders', {}).then((orders) => {
        this.setState({orders});
        global.hideLoadingPanel();
      })
    }
  
    componentWillUnmount() {
      if(this.refreshNavigation) this.refreshNavigation();

      
      global.refreshMain = () => {}

      if(this.appStateEvent) this.appStateEvent.remove();
    }
    
    //TODO: DRAWER CURRENTLY DOESNT HAVE A Animated.View COMPONENT SO YOU MUST GO INTO SOURCE CODE AND FIX IT
    render() {


      
      var orderFound = false;
  
      // API.user.orders.forEach((order) => {
      //   if(order.notification) orderFound = true;
      // })
  
      //PADING TOP WAS 70
    return (
      
      <View style={{ flex: 1}}>
        {/* <Animated.View style={{position:"absolute", left:0,right:0,top:0,bottom:0, backgroundColor:"black",zIndex:1000,opacity: this.state.sidebarFade}} pointerEvents="none"></Animated.View> */}
        <Drawer
          tweenHandler={(ratio) => ({
           
            mainOverlay: {opacity: (1-(2-ratio)/2), backgroundColor:"black"}
          })}
          ref={(ref) => this.sideMenu = ref}
          type="overlay"
          content={
            <View style={{backgroundColor:"white", width:"100%", height:"100%", paddingLeft:"7%", paddingRight:"7%", paddingTop:Constants.statusBarHeight+30, display:"flex", justifyContent:"space-between", paddingBottom:Platform.OS == 'ios'?30:20}}>
              <View>
              <TouchableOpacity onPress={() => {
                  setTimeout(() => {
                    if(API.user)
                    this.profileModal.open()
                    else global.promptLogin();
                }, 250); this.sideMenu.close();
              }} style={{display:"flex", alignItems:"center"}}>
                <Avatar size={50} rounded title={API.user?getInitials(API.user.name):""} icon={API.user?undefined:{name:"log-in", type:"feather", color:"white"}} containerStyle={{backgroundColor:(API.user)?Colors.Blue:"gray"}}/>
  
                <Text style={{color:"#A4A7AA", marginTop:10}}>{global.getText(API.user?(API.user.business != undefined?"business":"personal"):"guestAccount")}</Text>
                <Text style={{fontWeight:"600", marginTop:4, fontSize:16, color:(API.user)?"black":Colors.Blue}}>{API.user?API.user.name:global.getText("loginOrRegister")}</Text>
              </TouchableOpacity>
  
              <View style={{marginTop:20}}>
                
                <SidebarButton onPress={() => {setTimeout(() => {

                    if(API.user) this.openOrdersModal(); else global.promptLogin(() => this.openOrdersModal());
                    
                }, 250); this.sideMenu.close(); /*this.setState({ordersOpened: true})*/ }} title={global.getText("yourOrders")} icon={'documents-outline'} />
                {orderFound && <View style={{width:10,height:10, backgroundColor:"red", borderRadius:100, position:"absolute", left:20, top:20}}></View>}
                {/* <SidebarButton onPress={() => {}} title={'Favorite drivers'} icon={'heart-outline'}/> */}
                <SidebarButton onPress={() => {

                    setTimeout(() => {
                      if(API.user) this.paymentModal.open(); else global.promptLogin(() => this.paymentModal.open());
                    }, 250); this.sideMenu.close();
                    
                }} title={global.getText("payment")} icon={'wallet-outline'}/>
                {/* <SidebarButton onPress={() => {}} title={'News'} icon={'megaphone-outline'}/> */}
                <SidebarButton onPress={() => {
                  

                  setTimeout(() => {
                    
                    if(API.user) this.promotionsModal.open(); else global.promptLogin(() => this.promotionsModal.open());
                  }, 250); this.sideMenu.close();
                }} title={global.getText("promotions")} icon={'gift-outline'}/>

                <SidebarButton onPress={() => {setTimeout(() => {
                  if(API.user) this.mydriverModal.open(); else global.promptLogin(() => this.mydriverModal.open());
                    
                }, 250); this.sideMenu.close(); /*this.setState({ordersOpened: true})*/ }} title={global.getText("myDrivers")} icon={'people-outline'}/>


<SidebarButton onPress={() => {setTimeout(() => {
                  if(API.user) this.navigation.navigate('Referral'); else global.promptLogin(() => this.navigation.navigate('Referral'));
                }, 250); this.sideMenu.close(); }} title={global.getText("referallsTitle")} icon={'person-add-outline'}/> 

                <SidebarButton onPress={() => {setTimeout(() => {
                  if(API.user)  this.navigation.navigate('Chat', {_id: 0}); else global.promptLogin(() => setTimeout(() => {
                    this.navigation.navigate('Chat', {_id: 0})
                  }, 1000));
                    
                }, 250); this.sideMenu.close(); /*this.setState({ordersOpened: true})*/ }} title={global.getText("liveSupport")} icon={''}/>
                
                <SidebarButton onPress={() => {
                  WebBrowser.openBrowserAsync("https://blog.tasimacim.com/sikca-sorulan-sorular");
                }} title={global.getText("help")} icon={'help-outline'}/>

                <SidebarButton onPress={() => {setTimeout(() => {
                    this.settingsModal.open()
                }, 250); this.sideMenu.close(); /*this.setState({ordersOpened: true})*/ }} title={global.getText("settings")} icon={'settings-outline'}/>
              </View>
              </View>

              <View>

               
                <TouchableOpacity onPress={() => {
                  MailComposer.composeAsync({subject: "Taşımacım - " + global.getText("feedback"), recipients: ["destek@tasimacim.com"], body: global.getText("shareYourFeedback")})
                }} style={{borderRadius:100, borderWidth:2, borderColor:Colors.Blue, paddingTop:8, paddingBottom:8}}>
                  <Text style={{fontWeight:"600", textAlign:"center",  color:Colors.Blue}}>{global.getText("shareYourFeedback")}</Text>
                </TouchableOpacity>

                
              </View>
  
            </View>
          }
          acceptTap={true}
          styles={{main: {shadowColor: '#000000', shadowOpacity: 0.3, shadowRadius: 15}, mainOverlay: {backgroundColor:'transparent'}}}
          onOpen={() => {
            this.setState({drawerOpen: true})
          }}
          // onOpenStart={() => {
          //     Animated.timing(this.state.sidebarFade, {toValue: .4, duration:250, useNativeDriver: true}).start();
          // }}

          // onCloseStart={() => {
          //   Animated.timing(this.state.sidebarFade, {toValue: 0, duration:200, useNativeDriver: true}).start();
          // }}
          onClose={() => {
            this.setState({drawerOpen: false})
          }}
          captureGestures={true}
          tweenDuration={100}
          panThreshold={0.08}
          
          
          openDrawerOffset={(viewport) => {
            return 100
          }}
          closedDrawerOffset={() => 0}
          panOpenMask={0.2}
          // negotiatePan
          >
            
                    {/* height was 90   */}

                    
        <View style={{height:Constants.statusBarHeight+50, backgroundColor:"white", width:"100%", justifyContent:"flex-end", display:"flex" ,alignItems:"center",shadowColor: "#000",
shadowOffset: {
	width: 0,
	height: 1,
},
shadowOpacity: 0.20,
shadowRadius: 1.41,

elevation: 0,}}>
          {/* <SvgXml xml={logoSvg} width={100} height={100} style={{position:"absolute"}} /> */}

          {/* height was 40% */}
          <Image source={require('./assets/logo.png')} resizeMode={'contain'} style={{position:"absolute", height:"27%", bottom:13}}  />
          {/* <Text style={{fontWeight:"700", bottom:10, fontSize:20, position:"absolute"}}>GOGOX</Text> */}
          <TouchableOpacity onPress={() => this.sideMenu.open()} style={{position:"absolute", bottom:10, left:"5%"}}>
            <IonIcon name='menu' size={30} />
            {orderFound && <View style={{width:10,height:10, backgroundColor:"red", borderRadius:100, position:"absolute", right:0, top:5}}></View>}
          </TouchableOpacity>
        </View>
        {/* Old Address Selection Modal */}
  
        {/* <Modal animationType='slide' visible={this.state.ordersOpened}>
          <View style={{backgroundColor:"#F2F4F3", flex:1}}>
            <View style={{height:90, backgroundColor:"white", width:"100%", justifyContent:"flex-end", display:"flex" ,alignItems:"center"}}>
              <Text style={{fontWeight:"700", bottom:14, fontSize:17, position:"absolute"}}>Your orders</Text>
              <TouchableOpacity onPress={() => {

                  
                  this.yourOrdersModal.close();
              }} style={{position:"absolute", bottom:10, left:"5%"}}>
                <IonIcon name='close' size={30} />
              </TouchableOpacity>
            </View>
  
            <ScrollView style={{flex:1, paddingLeft:"5%", paddingRight:"5%"}}>
              {API.user.orders.slice(0).reverse().map((order,i) => 
              <OrderListElement first={i == 0} onPress={() => {
                
                this.setState({ordersOpened: false}); 
                this.navigation.navigate('OrderPending', {order,pop:true})
              
              }} order={order} key={i} />
              )}
            </ScrollView>
          </View>
        </Modal> */}
        

        <CustomModal title={global.getText("yourOrders")} ref={(a) => this.yourOrdersModal = a}>
            {this.state.orders.map((order,i) =>  //slice(0).reverse()
                <OrderListElement first={i == 0} onPress={() => {

                  global.showLoadingPanel();
                  API.fetch('getorder', {_id: order._id}).then((orderData) => {
                    global.hideLoadingPanel();
                    this.navigation.navigate('OrderPending', {order:orderData,pop:true})
                  })
                    
                }} order={order} key={i} />
            )}
        </CustomModal>

        
        
        
  
        <View style={{width: "100%", height:200}}><MapView zoomLevel={10} waypoints={this.state.waypoints} userInterfaceStyle='light' ref={(a) => this.map = a} initialRegion={{latitude: 	41.015137, longitude:28.979530, longitudeDelta: 0.3, latitudeDelta:0.3}} showsUserLocation={false} style={styles.map} >
          {/* {this.state.waypoints.map((waypoint, indx) => {

           
            if(waypoint) return <Marker key={indx} coordinate={{latitude: waypoint.Point[1], longitude: waypoint.Point[0]}} />
          })}  */}
        </MapView></View>
  
        <View style={{backgroundColor:"white", marginTop:-5, borderRadius:7, flex:1,width:"100%"}}>
  

          <ScrollView>
          {this.state.waypoints.map((waypoint,indx) => {
              return <Destination onRemove={(indx>1?() => {this.state.waypoints.splice(indx,1); this.forceUpdate();}:undefined)} onPress={async () => {
                
                
                this.setState({addressSelectionModalVisible: true,textInputText:indx==0?"whereFrom":"whereTo",addressSelectionAutocomplete: undefined,addressInputIndex: indx,addressSelectionText: (waypoint)?waypoint.Label:"", selectedAddress: (waypoint)?JSON.parse(JSON.stringify(waypoint)):undefined});
                this.addressSelectionModal.open();
                // setTimeout(() => {
                  
                // }, 100);
                
                // this.setState({addressInput: false, addressSearch: "", addressSearchSuggestions: [], addressInputIndex: indx, addressInputStartRegion: startRegion,finalizingAddress: waypoint});
              
                
              }} key={indx} first={indx == 0} selected={waypoint} />
          })}
          {/* <Destination selected={{a: "1003 Lower Delta Rd, Singapore 099308"}} first={true} />
          <Destination /> */}
          <TouchableOpacity onPress={async () =>{ 
  
            
            // var loc = await Location.getCurrentPositionAsync({ accuracy: Location.Accuracy.Balanced});
            // loc.coords.latitudeDelta = 0.004;
            // loc.coords.longitudeDelta = 0.004;
           
            this.setState({addressSelectionModalVisible: true,addressSelectionAutocomplete: undefined,addressInputIndex: -1,addressSelectionText:"", selectedAddress: undefined});
                
            this.addressSelectionModal.open();
            
            //this.addressSelectionModal.open();
            
            }} style={{display:"flex",flex:1, justifyContent:"center", backgroundColor:Colors.Gray, paddingTop:12,borderRadius:15, marginLeft:60, marginRight:20,paddingBottom:12, flexDirection:"row", marginBottom:20, alignItems:"center",  marginTop:20}}>
              
              <View style={{marginLeft:5, marginRight:10, width:20,alignItems:"center", justifyContent:"center", display:"flex", overflow:"visible"}}>
              <AntIcon color={"black"} size={20} name='pluscircleo' />
              </View>
              <Text style={{color:"black", fontWeight:"600"}}>{global.getText("addWaypoint")}</Text>
          </TouchableOpacity>

          </ScrollView>
            <View style={{paddingBottom:Platform.OS == 'ios'?30:20, paddingLeft:"5%", paddingRight:"5%", paddingTop:12}}>

              
          <TouchableOpacity onPress={() => {
            
            var foundWaypoint = false;
  
            this.state.waypoints.forEach((s) => {
              if(!s) foundWaypoint = true;
            })
  
            if(!foundWaypoint) {
              this.navigation.navigate('Route', {waypoints:this.state.waypoints});
              Amplitude.track("TO_ROUTESETTINGS");
            }
  
          }} style={{backgroundColor:Colors.Blue, paddingBottom:12, paddingTop:12, display:"flex", justifyContent:"center", alignItems:"center", borderRadius:30}}><Text style={{color:"white", fontWeight:"700", fontSize:17}}>{global.getText("next")}</Text></TouchableOpacity>
        
        </View>
        </View>
  
          <CustomModal title={global.getText("settings")} ref={(a) => this.settingsModal = a}>
              <View style={{paddingLeft:"5%", paddingRight:"5%", backgroundColor:"white"}}>
                <Text style={{fontWeight:"600", marginTop:20,marginBottom:20, fontSize:15}}>{global.getText("accountPreferences")}</Text>

                <TouchableOpacity onPress={() => {

                  if(!API.user) return global.promptLogin(() => {
                    this.profileModal.open();
                  });
                  this.profileModal.open();
                }} style={{flexDirection:"row", flex:1, alignItems:"center", marginBottom:10, justifyContent:"space-between"}}>
                  <View style={{flexDirection:"row", alignItems:"center"}}>
                    <Avatar icon={API.user?undefined:{name:"log-in", type:"feather", color:"white"}} size={30} rounded title={API.user?getInitials(API.user.name):""} containerStyle={{backgroundColor:"#FEB600"}} />

                    <View style={{marginLeft:10, justifyContent:"center"}}>
                     
                      <Text style={{fontSize:16}}>{global.getText(API.user?(API.user.business != undefined?"business":"personal"):"guestAccount")}</Text>
                      <Text numberOfLines={1} style={{color:"gray",maxWidth:Dimensions.get("screen").width*.6,flexWrap:"nowrap"}}>{API.user?API.user.business?API.user.business.name:API.user.name:global.getText("loginOrRegister")}</Text>
                    </View>
                  </View>

                  <Icon name='chevron-right' />
                </TouchableOpacity>
              </View>


              <View style={{paddingLeft:"5%", paddingRight:"5%", backgroundColor:"white", marginTop:10}}>
                <Text style={{fontWeight:"600", marginTop:20,marginBottom:20, fontSize:15}}>{global.getText("appPreferences")}</Text>

                <TouchableOpacity onPress={() => this.languageOptionsModal.open()} style={{flexDirection:"row", flex:1, alignItems:"center", marginBottom:10, justifyContent:"space-between"}}>
                  <View style={{flexDirection:"row", alignItems:"center"}}>

                    <IonIcon name='language' size={30} />
                    

                    <View style={{marginLeft:10, justifyContent:"center"}}>
                      <Text style={{fontSize:16}}>{global.getText("language")}</Text>
                      <Text style={{color:"gray"}}>{global.getText(global.locale)}</Text>
                    </View>
                  </View>

                  <Icon name='chevron-right' />
                </TouchableOpacity>
              </View>
          </CustomModal>
          
  
      {/* <View pointerEvents='none' style={{position:"absolute", left:0, right:0, top:0, bottom:0}}>
        </Drawer>
        </View> */}
         </Drawer>


          {/* New Address Modal */}
         <CustomModal scrollView={false} headerShown={false} ref={(a) => this.addressSelectionModal = a} >
           <View style={{flex:1,backgroundColor:"white",paddingTop: Platform.OS == 'ios'?40:20}}>
              {/* <SafeAreaView style={{flex:1}}> */}
                <KeyboardAvoidingView behavior='padding' style={{flex:1}}>
                <View style={{backgroundColor:"#F8F8F8",flex:1}}>
                  <View style={{paddingLeft:"5%", paddingRight:"5%", paddingTop:"4%", backgroundColor:"white", paddingBottom:10}}>
                    <View>
                      <TouchableOpacity onPress={() => this.addressSelectionModal.close()}>
                        <Text style={{color:Colors.Blue, fontWeight:"600", textAlign:"right", fontSize:17}}>{global.getText("cancel")}</Text>
                      </TouchableOpacity>
                    </View>

                    <View style={{width:"100%", flexDirection:"row", marginTop:30, alignItems:"center"}}>

                      <View style={{width:18, justifyContent:"center", alignItems:"center"}}>
                        {this.state.autocompleteLoading && <ActivityIndicator color={Colors.Blue} style={{position:"absolute"}} />}
                        {!this.state.autocompleteLoading && <Icon name='map-marker' color={Colors.Blue} size={30} />}
                      </View>

                      <View style={{flex:1, marginLeft:17, justifyContent:"center"}}>
                        <TextInput autoFocus={true} returnKeyType="search" selectTextOnFocus={true} value={(this.state.selectedAddress)?API.getAddressLabel(this.state.selectedAddress):this.state.addressSelectionText} onChangeText={(t) => this.onAddressSelectionTextChange(t)} multiline={false} placeholder={global.getText(this.state.textInputText)} placeholderTextColor='#8D98A2' style={{backgroundColor:Colors.LightBlue, borderRadius:15, paddingLeft:10, paddingTop:14, paddingBottom:14, paddingRight:30, fontSize:16 }} />

                        {this.state.addressSelectionText.length > 0 && <TouchableOpacity onPress={() => this.onAddressSelectionTextChange("")} style={{position:"absolute", height:"100%", justifyContent:"center", alignItems:"center",right:0, width:35}}>
                          <AntIcon name='closecircle' size={15}  />
                        </TouchableOpacity>}

                        {this.state.addressSelectionText.length == 0 && <TouchableOpacity onPress={async () => {
                          this.useCurrentLocation();
                        }} style={{position:"absolute", height:"100%", justifyContent:"center", alignItems:"center",right:0, width:35}}>
                          <Icon name='location-arrow' size={15}  />
                        </TouchableOpacity>}
                      </View>
                    </View>
                    
                    {this.state.waypoints[this.state.addressInputIndex] && <View style={{marginTop:15, flexDirection:"row", alignItems:"center"}}>
                    {/* <MaterialCommunityIcon name='map-marker' color={Colors.Blue} size={30} /> */}
                    <MaterialCommunityIcon name='office-building' color={Colors.Blue} size={30} style={{marginLeft:-6}} /> 
                     <TouchableOpacity onPress={( ) => {
                      
                      
                      
                      this.navigation.navigate('FinalizeAddress', {address: this.state.waypoints[this.state.addressInputIndex]}); //this.state.selectedAddress
                     
                    }} style={{flexDirection:"row", justifyContent:"space-between",flex:1, alignItems:"center", marginLeft:15}}>
                      <Text style={{color:Colors.Blue, fontWeight:"600", fontSize:16}}>{global.getText("editAddressDetails")}</Text>

                      <MaterialCommunityIcon name='pencil-outline' color={Colors.Blue} size={20} style={{marginLeft:-6}} /> 
                    </TouchableOpacity>
                    
                    </View>}


                    
                  </View>


                    
                  {/*
                  
                  TOP VIEW FOR HELP CAMPAIGNS


                  <TouchableOpacity onPress={() => {
                      global.setAddress({
                        Label: "Afet Yardımı",
                        help: true,
                        Point: [28.946699681823464,41.09372269346456]
                      },true);
                      
                      this.addressSelectionModal.close();
                      this.forceUpdate();
                    
                    }} style={{paddingLeft:"5%", paddingRight:"5%", backgroundColor:"white",paddingTop:10, flexDirection:"row", alignItems:"center", paddingBottom:10, marginTop:10}}>
                      <Icon5 name='hands-helping'size={30} style={{marginLeft:-6}} /> 

                      <View style={{flexDirection:"column", flex:1}}>
                      <Text style={{ fontSize:16, marginLeft:22}}>{global.getText("emergencyHelp")}</Text>
                      <Text style={{ fontSize:14, opacity:.8, marginTop:5, marginLeft:22}}>{global.getText("emergencyHelpDesc")}</Text>
</View>
                    
                    </TouchableOpacity> */}


                  {this.state.addressSelectionAutocomplete == undefined && <ScrollView contentContainerStyle={{flexGrow:1,paddingBottom:12}}>
                    
                    
                  <TouchableOpacity onPress={() => {
                      this.useCurrentLocation();
                    
                    }} style={{paddingLeft:"5%", paddingRight:"5%", backgroundColor:"white",paddingTop:10, flexDirection:"row", alignItems:"center", paddingBottom:10, marginTop:10}}>
                      <Icon name='location-arrow'  size={30} style={{marginLeft:-6}} /> 
                      <Text style={{ fontSize:16, marginLeft:22}}>{global.getText("useCurrentLocation")}</Text>
                    
                    </TouchableOpacity>
                    <TouchableOpacity onPress={() => {
                      this.setState({selectedOnMap: undefined}); 
                      this.selectOnMapModal.open()
                    
                    }} style={{paddingLeft:"5%", paddingRight:"5%", backgroundColor:"white",paddingTop:10, flexDirection:"row", alignItems:"center", paddingBottom:10, marginTop:4}}>
                      <MaterialCommunityIcon name='map-search-outline'  size={30} style={{marginLeft:-6}} /> 
                      <Text style={{ fontSize:16, marginLeft:15}}>{global.getText("selectOnMap")}</Text>
                    
                    </TouchableOpacity>

                  {global.addresses.saved.length > 0 && <Text style={{fontWeight:"500", paddingLeft:"5%", marginTop:20,fontSize:17}}>{global.getText("savedAddresses")}</Text>}
                  {global.addresses.saved.map((x, i) =>  <TouchableOpacity key={i} onPress={() => {
                    global.setAddress(x,true);

                    
                    this.addressSelectionModal.close();
                    this.forceUpdate();
                  }} style={{paddingLeft:"5%", paddingRight:"5%", backgroundColor:"white",paddingTop:12, flexDirection:"row", alignItems:"center", paddingBottom:12, marginTop:(i==0)?7:2,justifyContent:"space-between"}}>
                    <View style={{flexDirection:"row",alignItems:"center",flex:1}}>
                    <MaterialCommunityIcon name='file-star-outline'  size={30} style={{marginLeft:-6}} /> 
                    <View style={{flex:1, paddingRight:5}}>
                      <Text style={{ fontSize:16, marginLeft:15}}>{x.Label}</Text>
                      {API.getAddressExtras(x).length > 0 && <Text style={{ fontSize:14, color:"#A6A6A6", marginLeft:15}}>{API.getAddressExtras(x)}</Text>}
                    </View>
                    </View>


                    <TouchableOpacity pressRetentionOffset={{left:5,bottom:5,top:5,right:5}} onPress={() => {
                      global.addresses.recents.push(x);
                      global.addresses.saved.splice( global.addresses.saved.indexOf(x),1);
                      if(global.addresses.saved.length > 5) global.addresses.saved.splice(0,1);
                      this.forceUpdate();

                      AsyncStorage.setItem("searchAddresses", JSON.stringify(global.addresses));
                    }}>
                      <MaterialCommunityIcon name='trash-can-outline' size={30} />
                    </TouchableOpacity>
                  </TouchableOpacity>)}
                  {global.addresses.recents.length > 0 && <Text style={{fontWeight:"500", paddingLeft:"5%", marginTop:20,fontSize:17}}>{global.getText("recentAddresses")}</Text>}
                  {global.addresses.recents.slice(0).reverse().map((x, i) =>  <TouchableOpacity key={i} onPress={() => {
                    global.setAddress(x,true);
                    this.addressSelectionModal.close();
                    this.forceUpdate();
                  }} style={{paddingLeft:"5%", paddingRight:"5%", backgroundColor:"white",paddingTop:12, flexDirection:"row", alignItems:"center", paddingBottom:12, marginTop:(i==0)?7:2,justifyContent:"space-between"}}>
                    <View style={{flexDirection:"row",alignItems:"center",flex:1}}>
                    <MaterialCommunityIcon name='clock-outline'  size={30} style={{marginLeft:-6}} /> 
                    <View style={{flex:1, paddingRight:5}}>
                      <Text style={{ fontSize:16, marginLeft:15}}>{x.Label}</Text>
                      {API.getAddressExtras(x).length > 0 && <Text style={{ fontSize:14, color:"#A6A6A6", marginLeft:15}}>{API.getAddressExtras(x)}</Text>}
                    </View>
                    </View>


                    <TouchableOpacity onPress={() => {
                      global.addresses.saved.push(x);
                      global.addresses.recents.splice( global.addresses.recents.indexOf(x),1);
                      if(global.addresses.recents.length > 5) global.addresses.recents.splice(0,1);
                      this.forceUpdate();

                      AsyncStorage.setItem("searchAddresses", JSON.stringify(global.addresses));
                    }}>
                      <MaterialCommunityIcon name='star-outline' size={30} />
                    </TouchableOpacity>
                  </TouchableOpacity>)}
                  </ScrollView >}

                  

                  {this.state.addressSelectionAutocomplete && this.state.addressSelectionAutocomplete.length > 0 && <FlatList contentContainerStyle={{marginTop:10}} data={[...this.state.addressSelectionAutocomplete,1]} keyExtractor={(x,i) => i} renderItem={({item,index}) => {
                  
                  if(item == 1) return <Image style={{height:20,width:218, marginTop:10}} resizeMode='contain' source={GoogleIcon} />
                  return <TouchableOpacity onPress={() => {
                    // global.setAddress = (address) => {
                    //   if(this.state.addressInputIndex != -1)
                    //   this.state.waypoints[this.state.addressInputIndex] = address;
                    // else
                    //   this.state.waypoints.push(address);


                    //   this.addressSelectionModal.close(true);

                    // }

                    if(!item.allowed) {
                      Amplitude.track("AUTOCOMPLETE_FAIL", {q: item.Label})
                      Keyboard.dismiss();
                      return global.showError(1001);
                    }

                    if(item.tooUnspecific) {
                      Amplitude.track("AUTOCOMPLETE_FAIL", {q: item.Label})
                      Keyboard.dismiss();
                      return global.showError(1008);
                    }
                    
                    global.showLoadingPanel();
                      
                    Amplitude.track("AUTOCOMPLETE_ENDSESSION", {autoCompleteTries: this.autoCompleteTries});
                    
                    API.fetch('autocompleteendsession', {_id: item.place, Label: item.Label, session: this.autoCompleteSession}).then((val) => {
                      if(!val.error) {
                        global.hideLoadingPanel();
                        
                        val.Label = item.Label;
                        this.navigation.navigate('FinalizeAddress', {address: val});
                      }
                    })
                    this.autoCompleteSession = rnd(36);
                    this.autoCompleteTries = 0;
                    
                    return;
                    this.mapSelectionAnimator.setValue(0);
                    this.addressAnimDone = false;
                    this.addressSelectionHeight = undefined;
                    if(!item.extra) item.extra = {};
                    this.setState({selectedAddress: item});
                    this.newAddressModal.open();
                    
                    
                    // this.addressSelectionMap.animateToRegion({
                    //   latitude: this.state.selectedAddress.Point[1],
                    //   longitude: this.state.selectedAddress.Point[0],
                    //   latitudeDelta: .1, longitudeDelta:.1
                    // }, 1);
                    
                    return;
                    this.setState({selectedAddress: item, addressSelectionText: item.Label});

                  }} style={{paddingLeft:"5%", paddingRight:"5%",backgroundColor:"white", flexDirection:"row", paddingTop:15,paddingBottom:15}}>
                    <MaterialCommunityIcon name='map-marker-outline'  size={25} style={{marginLeft:-6}} /> 
                      <Text style={{ fontSize:16, marginLeft:15,flex:1}}>{item.Label}</Text>

                  </TouchableOpacity>}} />}
                  


                  
                </View>
                </KeyboardAvoidingView>
              {/* </SafeAreaView> */}
              </View>
              
              <CustomModal scrollView={false} title={global.getText("selectOnMap")} ref={(a) => this.selectOnMapModal = a}>
                  <SafeAreaView style={{display:"flex", flex:1}}>
                      <View style={{position:"absolute", backgroundColor:"white",zIndex:100,width:"100%", paddingTop:10, paddingBottom:10, flexDirection:"row", alignItems:"center", paddingLeft:"6.5%", paddingRight:"5%"}}>
                        <Icon name='map-marker' color={Colors.Blue} size={30} />
                        <Text style={{fontSize:17, marginLeft:10}}>{this.state.selectOnMapLoading?global.getText("mapLoading"):(this.state.selectedOnMap?this.state.selectedOnMap.Label:global.getText("geoChooseStreet"))}</Text>
                      </View>

                      <View style={{flex:1, justifyContent:"center",alignItems:"center"}} >
                      <MapView waypoints={[]} userInterfaceStyle='light' ref={(a) => this.selectOnMapMap = a} initialRegion={(this.state.selectedAddress?{
                        latitude: this.state.selectedAddress.Point[1],
                        longitude: this.state.selectedAddress.Point[0],
                        latitudeDelta: .1, longitudeDelta:.1
                      }:{latitude:41.015137,longitude: 28.979530, latitudeDelta: 1, longitudeDelta:1})} style={{height:"100%", width:"100%"}} showsMyLocationButton={true} showsUserLocation={true} onRegionChangeComplete={(a) => {

                          this.setState({selectedOnMap: undefined, selectOnMapLoading: true});
                          if(this.selectRegionTimeout) clearTimeout(this.selectRegionTimeout);

                          var time = setTimeout(() => {
                            

                              API.fetch('reversegeocode',{Position:[a.longitude, a.latitude]}).then((results) => {
                                if(this.selectRegionTimeout == time) {
                                  
                                  if(results[0] && !results.error) {
                                    this.setState({selectedOnMap: results[0], selectOnMapLoading: false});
                                  }  else {
                                    this.setState({selectOnMapLoading: false});
                                  }

                                  
                                }
                              })

                            
                          }, 300);
                          this.selectRegionTimeout = time;
                      }} >
                        
                      </MapView>
                      
                      <Icon name='map-marker' style={{position:"absolute", top:"47.5%"}} color={Colors.Blue} size={30} />
                        <TouchableOpacity onPress={async () => {
                          var loc = await Location.getCurrentPositionAsync({ accuracy: Location.Accuracy.Balanced});
                          
                          this.selectOnMapMap.setCamera({center: {latitude: loc.coords.latitude, longitude: loc.coords.longitude},altitude: 2000,zoom:1})
                          
                        }} style={{position:"absolute", bottom:20, right:20, backgroundColor:"white",width:35,height:35, borderRadius:50, justifyContent:"center", alignItems:"center",shadowColor: "#000",
shadowOffset: {
	width: 0,
	height: 2,
},
shadowOpacity: 0.25,
shadowRadius: 3.84,

elevation: 1,}}>
                          <MaterialCommunityIcon name='target' color={'black'} size={25} />
                      </TouchableOpacity>
                      
                      
                      </View>

                      
                      
                      <View style={{paddingBottom:20, paddingTop:20}}>
                      <TouchableOpacity onPress={() => {

                        if(this.state.selectedOnMap && this.state.selectedOnMap.Label)
                        {
                          this.navigation.navigate('FinalizeAddress', {address: this.state.selectedOnMap })
                          //this.setState({selectedAddress: this.state.selectedOnMap, addressSelectionText: this.state.selectedOnMap.Label});
                          //this.selectOnMapModal.close();
                        } else {
                          global.showError(1001);
                        }
                      }} style={{backgroundColor:Colors.Blue, marginLeft:"5%", marginRight:"5%", paddingBottom:12, paddingTop:12, display:"flex", justifyContent:"center", alignItems:"center", borderRadius:30}}><Text style={{color:"white", fontWeight:"700", fontSize:17}}>{global.getText("ok")}</Text></TouchableOpacity>
                      </View>

                      
                  </SafeAreaView>
                  
              </CustomModal>
        </CustomModal>
        

        <PaymentModal ref={a => this.paymentModal = a} />


        <PromotionsModal ref={a => this.promotionsModal = a} />

        <MyDriversModal ref={(a) => this.mydriverModal = a} />

        

      {API.user != undefined && <CustomModal title={global.getText("profile")} ref={(a) => this.profileModal = a}>
            <View style={{backgroundColor:"white", paddingTop:20, paddingBottom:15, paddingLeft:"5%", paddingRight:"5%", flexDirection:"row", alignItems:"center"}}>
              
            <Avatar  size={30} rounded title={getInitials(API.user.name)} containerStyle={{backgroundColor:Colors.Blue}} />
                <Text style={{marginLeft:20, fontSize:16}}>{global.getText((API.user && API.user.business)?"businessAccount":"personalAccount")}</Text>
            </View>


            <View style={{backgroundColor:"white", paddingTop:20, paddingBottom:15, paddingLeft:"5%", paddingRight:"5%",flexDirection:"row", justifyContent:"space-between",alignItems:"center"}}>
                <View style={{flexDirection:"row", alignItems:"center"}}>
                    <IonIcon name='mail-outline' size={30} />

                    {API.user.email != "" && API.user.email != undefined && <Text style={{marginLeft:20,fontSize:16}}>{API.user.email}</Text>}
                    {(API.user.email == "" || API.user.email == undefined) && <Text style={{marginLeft:20,fontSize:16,color:"gray"}}>E-mail</Text>}
               </View>

               <TouchableOpacity onPress={() => {
                this.setState({emailAddressChange: (API.user.email)?API.user.email:""});
                this.changeEmailSheet.open();
               }}>
                <MaterialCommunityIcon size={25} name='pencil-outline' color={Colors.Blue} />
               </TouchableOpacity>
            </View>
            


            {/* <View style={{justifyContent:"center", alignItems:"center", paddingTop:12, paddingBottom:12, marginTop:10, backgroundColor:"white"}}>
                <TouchableOpacity><Text style={{fontSize:17, color:Colors.Blue}}>{global.getText("changePassword")}</Text></TouchableOpacity>
            </View> */}

            <View style={{justifyContent:"center", alignItems:"center", paddingTop:12, paddingBottom:12, marginTop:10, backgroundColor:"white"}}>
                <TouchableOpacity onPress={async () => {
                    global.showLoadingPanel();
                    
                    await API.fetch('logout', {});




                    Amplitude.setUserId(null);

                    await AsyncStorage.removeItem("bearerToken");
                    await AsyncStorage.removeItem("paymentMethod");
                    API.user = undefined;
                    API.setToken(undefined);
                    this.profileModal.close();
                    this.settingsModal.close();

                    

                    global.hideLoadingPanel();
                    // this.navigation.dispatch(CommonActions.reset({
                    //     index:0,
                    //     routes: [{name: "Login"}]
                    // }));

                    global.checkSocketConnection();
                    this.forceUpdate();
                }}><Text style={{fontSize:17, color:"crimson"}}>{global.getText("logOut")}</Text></TouchableOpacity>


                
            </View>


            <View style={{justifyContent:"center", alignItems:"center", paddingTop:12, paddingBottom:12, marginTop:20, backgroundColor:"white"}}>
                <TouchableOpacity onPress={async () => {
                  Alert.alert(global.getText("sureDelete"), global.getText("sureDeleteDesc"), [{style:"cancel",text: global.getText("cancel")}, {style:"destructive", onPress: () => {
                    global.showLoadingPanel();

                    API.fetch("deleteaccount").then(async (e) => {
                      Amplitude.setUserId(null);
                      await AsyncStorage.removeItem("bearerToken");
                      API.user = undefined;
                      global.hideLoadingPanel();
                      this.navigation.dispatch(CommonActions.reset({
                          index:0,
                          routes: [{name: "Login"}]
                      }));
                      global.checkSocketConnection();
                    })
                  },text: global.getText("deleteAccount")}], {cancelable: true})
                    // global.showLoadingPanel();
                    
                    // await API.fetch('logout', {});

                    // Amplitude.setUserId(null);

                    // await AsyncStorage.removeItem("bearerToken");
                    // API.user = undefined;

                    

                    // global.hideLoadingPanel();
                    // this.navigation.dispatch(CommonActions.reset({
                    //     index:0,
                    //     routes: [{name: "Login"}]
                    // }));

                    // global.checkSocketConnection();
                }}><Text style={{fontSize:17, color:"crimson"}}>{global.getText("deleteAccount")}</Text></TouchableOpacity>


                
            </View>

            <RBSheet ref={(a) => this.changeEmailSheet = a} height={250} customStyles={{container: {borderTopLeftRadius: 5, borderTopRightRadius:5}}} closeOnDragDown={false} closeOnPressMask={true} snapPoints={["40%"]}>
                    <View style={{display:"flex", justifyContent:"space-between",  flex:1, paddingBottom:40, paddingTop: 0, paddingLeft:"5%", paddingRight:"5%", paddingTop:20, borderTopLeftRadius: 5, borderTopRightRadius:5}}>
                        <View>
                        <Text style={{fontWeight:"600", fontSize:20}}>{global.getText("changeEmail")}</Text>

                        {/* <Text style={{color: "gray", fontSize:10, marginTop:5}}>{global.getText("addingBonusNotify")}</Text> */}
                        <View style={{display:"flex", flexDirection:"row", marginTop:20}}>

                            <TextInput keyboardType='email-address' value={this.state.emailAddressChange} onChangeText={(e) => {
                              this.setState({emailAddressChange: e});
                            }} autoFocus={true} style={{paddingBottom:10, borderBottomWidth:1, borderBottomColor:Colors.Blue,flex:1,fontSize:17}} placeholderTextColor={'gray'} placeholder='E-mail' />
                            
                        </View>
                        </View>

                        <TouchableOpacity onPress={async () => {
            
                        global.showLoadingPanel();
                        this.changeEmailSheet.close();
                        
                        
                        if(this.state.emailAddressChange != API.user.email) {
                          API.fetch("updateinfo", {email: this.state.emailAddressChange}).then((e) => {
                            global.hideLoadingPanel();
                            
                            API.user.email = this.state.emailAddressChange;

                            this.forceUpdate();
                          })
                        }
                        //this.setState({editTime: false});
            

            }} style={{backgroundColor:Colors.Blue, paddingBottom:8, paddingTop:8, display:"flex", justifyContent:"center", alignItems:"center", borderRadius:30}}><Text style={{color:"white", fontWeight:"700", fontSize:17}}>{global.getText("save")}</Text></TouchableOpacity>
                    
                        <TouchableOpacity onPress={() => this.changeEmailSheet.close()} style={{position:"absolute",right:10, top:20}}>
                            <AntIcon name='close' size={20} />
                        </TouchableOpacity>
                    </View>
                </RBSheet>
        </CustomModal>}

        <CustomHorizontalModal title={global.getText("language")} ref={(a) => this.languageOptionsModal = a}>
            
            <TouchableOpacity onPress={() => {
              if(global.locale == 'en') return;
              global.showLoadingPanel();

              if(!API.user) {
                
                global.locale = 'en';
                return AsyncStorage.setItem("setLocale", 'en').then((e) => {
                  global.hideLoadingPanel();
                  global.refreshMain();
                })
                
              }

              API.fetch('updateinfo',{lang: 'en'}).then((e) => {
                if(!e.error) {
                  
                  global.locale = 'en';
                 
                  global.hideLoadingPanel();
                  global.refreshMain();
                }
              })
            }} style={{flexDirection:"row", justifyContent:"space-between",alignItems:"center", paddingLeft:"5%", paddingRight:"5%", backgroundColor:"white", height:55}}>
              <Text style={{fontSize:16}}>English</Text>

              
              {global.locale == 'en' && <View style={{width:35, backgroundColor:Colors.Blue, borderRadius:100, height:35, justifyContent:"center", alignItems:"center"}}>
            <Icon color={"white"} size={20} name='check' />

        </View>}
            </TouchableOpacity>


            <TouchableOpacity onPress={() => {
              if(global.locale == 'tr') return;

              global.showLoadingPanel();
              if(!API.user) {
                
                global.locale = 'tr';

                return AsyncStorage.setItem("setLocale", 'tr').then((e) => {
                  global.hideLoadingPanel();
                  global.refreshMain();
                })
                
              }
              
              API.fetch('updateinfo',{lang: 'tr'}).then((e) => {
                if(!e.error) {
                  
                  global.locale = 'tr';
                  global.hideLoadingPanel();
                  global.refreshMain();
                }
              })
            }} style={{flexDirection:"row", justifyContent:"space-between",alignItems:"center", paddingLeft:"5%", paddingRight:"5%", backgroundColor:"white", height:55}}>
              <Text style={{fontSize:16}}>Türkçe</Text>

              
              {global.locale == 'tr' && <View style={{width:35, backgroundColor:Colors.Blue, borderRadius:100, height:35, justifyContent:"center", alignItems:"center"}}>
            <Icon color={"white"} size={20} name='check' />

        </View>}
            </TouchableOpacity>
            
          </CustomHorizontalModal>


            
        
       

            <Popup ref={(a) => this.popup = a} />

            
      </View>
    );
    }



    onAddressSelectionTextChange = (e) => {
      


      

      if(this.addressSearchTimeout) clearTimeout(this.addressSearchTimeout);
      if(e != "") {

        this.addressSearchTimeout = setTimeout(() => {
          //TODO:
          Amplitude.track("AUTOCOMPLETE_SEARCH",{q: e});
          this.autoCompleteTries++;
          API.fetch('autocomplete',{q:e, session: this.autoCompleteSession}).then((results) => {
            if(this.state.addressSelectionText == e) {
              
              this.setState({addressSelectionAutocomplete: results, autocompleteLoading: false});
            }
          })

        }, 300);

      } else {
        this.state.addressSelectionAutocomplete = undefined;
      }

      this.setState({addressSelectionText: e, selectedAddress:undefined,autocompleteLoading: (e != "")});
    }
  }


  
const styles = StyleSheet.create({
    container: {
      flex: 1,
      backgroundColor: '#fff',
      alignItems: 'center',
      justifyContent: 'center',
    },
    map: {
      width: "100%",
      height: "25%",
      zIndex: -1
    },
    topPanel: {
      width:"100%", height:"10%",
      shadowOffset: { width: 10, height: 10 },  
    shadowColor: 'black',  
    shadowOpacity: 1,  
    elevation: 3,  
  
  
      
    }
  });
  