import React from 'react';
import Colors from '../Colors';
import API from '../API';
import * as Notifications from 'expo-notifications';
import {View, Text, KeyboardAvoidingView, TouchableOpacity, Image, ActivityIndicator,TextInput, FlatList, Dimensions, Animated, Platform} from 'react-native';
import Icon from '@expo/vector-icons/AntDesign';
import OTPInputView from './otp'
import AdditionalInformationInput from '../components/AdditionalInformationInput';
import CustomModal from '../CustomModal';
import IonIcon from '@expo/vector-icons/Ionicons';

export default class LoginModal extends React.Component {

    constructor(props) {
        super(props);   
        this.state = {email: '', password: '',renderReCaptcha: false, phone: "", codeSent: false, lastStep: false,currentIndex:0}

        
    }

    componentDidMount() {
        
    }


    requestReCaptcha() {

    }

    async getExpoToken() {

        if(Platform.OS == 'web') return undefined;
        
        const { status: existingStatus } = await Notifications.getPermissionsAsync();
        let finalStatus = existingStatus;
        if (existingStatus !== 'granted') {
        const { status } = await Notifications.requestPermissionsAsync();
        finalStatus = status;
        }


        if (finalStatus !== 'granted') {
        return undefined;
        }
        //token = (await Notifications.getExpoPushTokenAsync()).data;

        try {
        var push = (await Notifications.getExpoPushTokenAsync({projectId: "c7ff5046-c335-4ed6-9a03-1c9d417b4823"}));
        return push.data;//(await Notifications.getDevicePushTokenAsync()).data;
        } catch {}
        return undefined;
        //return (await Notifications.getExpoPushTokenAsync()).data;
    }
    requestCode() {
        global.showLoadingPanel();
        if(this.countdownInterval) {
            clearInterval(this.countdownInterval);
            this.countdownInterval = undefined;
        }
        API.fetch('requestcode', {phone: this.state.phone.replace(/ /g, ""),platform: Platform.OS=='ios'?0:Platform.OS == 'web'?3:1, lang: global.locale}).then((e) => {
            this.setState({codeSent:(!e.error), waitSeconds: 30});
            global.hideLoadingPanel();
            
            if(!e.error) {
                setTimeout(() => {
                    this.otpInput.focusField(0);
                   
                }, 700);

                if(this.countdownInterval) 
                    clearInterval(this.countdownInterval);

                this.countdownInterval = setInterval(() => {
                    this.state.waitSeconds--;
                    this.forceUpdate();

                    if(this.state.waitSeconds <= 0) {
                        clearInterval(this.countdownInterval);
                        this.countdownInterval = undefined;
                    }
                }, 1000);
                
            }
        })
    }
    open() {
        this.setState({email: '', password: '', phone: "", codeSent: false, lastStep: API.user && !API.user.name,currentIndex:0})
        this.modal.open();

        // setTimeout(() => {
            
        // }, 500);
    }

    close() {
        this.modal.close();
    }

    render() {
        return <CustomModal ref={(a) => this.modal = a} headerShown={false} scrollView={false}>
                {/* {this.state.renderReCaptcha && <WebView source={{uri: "https://tasimacim.com/auth/captcha"}} cacheEnabled={false} style={{width: 0, height: 0}} javaScriptEnabled={true} onMessage={(data) => {
                        console.log("Message received: " + data);
                }} />} */}
            {this.state.lastStep && <KeyboardAvoidingView keyboardVerticalOffset={0} behavior={Platform.OS=='ios'?'padding':'height'}  style={{display:"flex", flex:1, backgroundColor:"white", paddingRight:"5%", paddingLeft:"5%", paddingTop:50, paddingBottom:Platform.OS=='ios'?30:0, justifyContent:"space-between"}}>
                
                <View>
                <Text style={{marginTop:20, fontWeight:"500", fontSize:20, marginBottom:20}}>{global.getText("loginInformationRequired")}</Text>
                
                
                <AdditionalInformationInput keyboard={'default' }value={this.state.name} onChange={(a) => this.setState({name:a})} onSubmit={() => this.mailPop.focus()} label={global.getText("fullName")} />
                <AdditionalInformationInput keyboard={'email-address'} value={this.state.email} onChange={(a) => this.setState({email:a})} onSubmit={() => {}} last ref={(a) => this.mailPop = a} label={global.getText("email")} />
                </View>
                

                 <View style={{flexDirection:"row", justifyContent:"space-between", marginBottom:35}}>
                    <View></View>
                {/* <TouchableOpacity onPress={async () => {
                    var v = await AsyncStorage.removeItem("bearerToken");
                    
                    this.setState({getStarted: true,lastStep: false, codeSent:false,phone:""});
                }} style={{backgroundColor:Colors.Gray, height:50, width:50, borderRadius:100, justifyContent:"center", alignItems:"center"}}><Icon name='arrowleft' color={'black'} size={35}/></TouchableOpacity> */}
                <TouchableOpacity onPress={() => {
                    
                    global.showLoadingPanel();
                    API.fetch('updateinfo', {name: this.state.name, email: this.state.email, lang: global.locale}).then((e) => {
                        
                        if(!e.error)
                        {
                            this.close();

                            setTimeout(() => {
                                if(global.onLoginCallback) global.onLoginCallback();
                                global.hideLoadingPanel();
                            }, 500);
                            
                            global.refreshMain();
                        }
                        
                    })
                   
                }} disabled={!this.state.name || this.state.name.trim().length <= 1} style={{height:50, flexDirection:"row", backgroundColor:!this.state.name || this.state.name.trim().length <= 1?Colors.Gray:Colors.Blue, justifyContent:"center", alignItems:"center", paddingLeft:20, paddingRight:10, borderRadius:100, marginBottom:Platform.OS=='ios'?30:0}}>
                    <Text style={{fontSize:20, fontWeight:"500", color:!this.state.name || this.state.name.trim().length <= 1?"black":"white", marginRight:2}}>{global.getText("next")}</Text>
                    <Icon name='arrowright' size={35} color={!this.state.name || this.state.name.trim().length <= 1?"black":"white"}/>
                    
                </TouchableOpacity>
            </View>
                
            </KeyboardAvoidingView>}
        
        {this.state.codeSent && <KeyboardAvoidingView keyboardVerticalOffset={0} behavior={Platform.OS=='ios'?'padding':'height'}  style={{display:"flex", flex:1, backgroundColor:"white", paddingRight:"5%", paddingLeft:"5%", paddingTop:50, paddingBottom:30, justifyContent:"space-between"}}>
            <View>
            <Text style={{marginTop:20, fontWeight:"500", fontSize:20}}>{global.getText("enterOTP",{phone: this.state.phone})}</Text>
            
            <OTPInputView  ref={(a) => this.otpInput = a} onCodeFilled={async (code) => {
                global.showLoadingPanel();

                var expo = await this.getExpoToken();
                console.log(expo);
                 API.fetch('login', {phone: this.state.phone.replace(/ /g, ""),platform: Platform.OS=='ios'?0:Platform.OS == 'web'?3:1, code, expo}).then((e) => {
                     
                     
                     if(!e.error) {

                        if(API.user.name) {
                            this.close();

                            setTimeout(() => {
                                if(global.onLoginCallback) global.onLoginCallback();
                                global.hideLoadingPanel();
                            }, 500);
                            
                            global.refreshMain();
                            
                        }
                        else {
                            this.setState({codeSent: false, lastStep: true, email:"",name:""})
                            global.hideLoadingPanel();
                        }
                     }
                 })
            }} style={{width:"60%", height:100}} autoFocusOnLoad={false} pinCount={4} codeInputHighlightStyle={{borderWidth:0, borderBottomWidth:1, borderColor:Colors.Blue}} codeInputFieldStyle={{borderWidth:0, borderBottomWidth:0,fontSize:16,width:50,height:50, color:"black", backgroundColor:Colors.Gray, borderColor:Colors.Blue}} />

            <TouchableOpacity onPress={() => {
                this.requestCode();
            }} disabled={this.state.waitSeconds > 0} style={{backgroundColor:Colors.Gray, alignSelf:"flex-start",paddingLeft:10, paddingRight:10, paddingTop:10, paddingBottom:10, borderRadius:100}}>
                <Text style={{color:"gray"}}>{global.getText("resendOTP", {timer: (this.state.waitSeconds > 0)?"(00:" + digit(this.state.waitSeconds) + ")":""})}</Text>
            </TouchableOpacity>
            </View>
            
            <TouchableOpacity onPress={() => {
                    this.setState({codeSent: false});

                    if(this.countdownInterval) {
                        clearInterval(this.countdownInterval);
                        this.countdownInterval = undefined;
                    }
                }} style={{backgroundColor:Colors.Gray, height:50, width:50, borderRadius:100, justifyContent:"center", alignItems:"center", marginBottom:Platform.OS=='ios'?30:0}}><Icon name='arrowleft' color={'black'} size={35}/></TouchableOpacity>
        </KeyboardAvoidingView>}

        
        
            
        
        
        
      


         {!this.state.codeSent && !this.state.lastStep && <><KeyboardAvoidingView keyboardVerticalOffset={0} behavior={Platform.OS=='ios'?'padding':'height'}  style={{display:"flex", flex:1, backgroundColor:"white", paddingRight:"5%", paddingLeft:"5%", paddingTop:75, justifyContent:"space-between", paddingBottom:30}}>
            <View>
            <Text style={{marginTop:20, fontWeight:"500", fontSize:20}}>{global.getText("enterMobile")}</Text>
            <View style={{flexDirection:"row", backgroundColor:Colors.Gray, paddingLeft:10, marginTop:20, alignItems:"center"}}>
                <Text style={{fontSize:20}}>+90</Text>
                <TextInput autoFocus={true} value={this.state.phone} onChangeText={(e) => {
                    e = e.replace(/ /g, "");
                    var newPhone = "";
                    for(var i = 0; i < e.length; i++) {
                        if(i == 3 || i == 6 || i == 8) newPhone += " ";


                        newPhone += e[i];
                    }
                    this.setState({phone:newPhone})

                }} style={{fontSize:20, marginLeft:5, flex:1, paddingTop:15, paddingBottom:15}} maxLength={13} placeholderTextColor={"gray"} hitSlop={{top:10,bottom:10}} placeholder={global.getText("mobileNumberPlaceholder")} keyboardType='number-pad' />
            </View>

            <Text style={{marginTop:20, color:"gray"}}>{global.getText("loginPhoneNotify")}</Text>
            </View>

            <View style={{flexDirection:"row", justifyContent:"space-between", marginBottom:0}}>
                {/* <TouchableOpacity onPress={() => {
                    this.setState({getStarted: true});
                }} style={{backgroundColor:Colors.Gray, height:50, width:50, borderRadius:100, justifyContent:"center", alignItems:"center"}}><Icon name='arrowleft' color={'black'} size={35}/></TouchableOpacity> */}

                {/* <View></View> */}
                <TouchableOpacity onPress={() => {
                    this.close();
                    global.promptLoginBusiness();
                }} style={{justifyContent:"center", alignItems:"center",flexDirection:"column", height:50}}><Text style={{color:Colors.Blue,fontWeight:"bold",fontSize:14}}>{global.getText("toBusinessLogin")}</Text></TouchableOpacity>
                <TouchableOpacity onPress={() => {
                    global.showLoadingPanel();
                    this.requestCode();
                    // API.fetch('requestcode', {phone: this.state.phone.replace(/ /g, ""), lang: global.locale}).then((e) => {
                    //     this.setState({loading: false, codeSent:(!e.error)});

                    //     if(!e.error) {
                    //         setTimeout(() => {
                    //             this.otpInput.focusField(0);
                    //         }, 500);
                            
                    //     } else {
                    //         global.showError("2");
                    //     }
                    // })
                   
                }} disabled={!this.state.phone.startsWith("5") || this.state.phone.length != 13} style={{height:50, flexDirection:"row", backgroundColor:(this.state.phone.startsWith("5") && this.state.phone.length == 13)?Colors.Blue:Colors.Gray, justifyContent:"center", alignItems:"center", paddingLeft:20, paddingRight:10, borderRadius:100,marginBottom:Platform.OS=='ios'?30:0}}>
                    <Text style={{fontSize:20, fontWeight:"500", color:(this.state.phone.startsWith("5") && this.state.phone.length == 13)?"white":"black", marginRight:2}}>{global.getText("next")}</Text>
                    <Icon name='arrowright' size={35} color={(this.state.phone.startsWith("5") && this.state.phone.length == 13)?"white":"black"}/>
                    
                </TouchableOpacity>
            </View>
            {/* <TextInput style={{backgroundColor:"white", width:"70%", paddingTop:10, paddingBottom:10, paddingLeft:10, paddingRight:10, borderRadius:10}} value={this.state.email} onChangeText={(a) => this.setState({email: a})} keyboardType='email-address' placeholder='Email' />
            <TextInput style={{backgroundColor:"white", width:"70%", paddingTop:10, paddingBottom:10, paddingLeft:10, paddingRight:10, borderRadius:10, marginTop:10}} value={this.state.password} onChangeText={(a) => this.setState({password: a})} secureTextEntry placeholder='Password' />

            <TouchableOpacity onPress={async () => {
                const expo = await this.getExpoToken();
                API.fetch('login', {email: this.state.email, password: this.state.password, expo}).then((data) => {
                    
                    if(data.user)
                        this.navigation.replace("Home");
                });
            }} style={{width:"60%", backgroundColor: Colors.Blue, display:"flex", justifyContent:"center", flexDirection:"row", paddingTop:10, paddingBottom:10, borderRadius:10, marginTop:25}}><Text style={{color:"white", fontWeight:"700"}}>Login</Text></TouchableOpacity>
            <TouchableOpacity onPress={async () => {
                const expo = await this.getExpoToken();

                 API.fetch('register', {email: this.state.email, password: this.state.password, expo}).then((data) => {
                    if(data.user)
                        this.navigation.replace("Home");
                });
            }} style={{width:"60%", backgroundColor: "white", borderWidth:2, borderColor:Colors.Blue, display:"flex", justifyContent:"center", flexDirection:"row", paddingTop:10, paddingBottom:10, borderRadius:10, marginTop:10}}><Text style={{fontWeight:"700", color: Colors.Blue}}>Register</Text></TouchableOpacity>
             */}
             
        </KeyboardAvoidingView>
        
        <TouchableOpacity onPress={() => {
                            
                            
                    this.close()
                                       
                }} style={{position:"absolute", top:50, left:"5%"}}>
                            <IonIcon name='close' size={30} />
                        </TouchableOpacity>
        </>}
        </CustomModal>
    }
    
}

function digit(num) {
    return `${num}`.padStart(2, '0');
}