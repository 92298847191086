import React from 'react';
import {View, Text, TouchableOpacity, ScrollView, Animated, FlatList, Platform, Image, TouchableWithoutFeedback} from 'react-native';
import EvilIcon from '@expo/vector-icons/EvilIcons';

import {Picker} from '@react-native-picker/picker';
import API from './API.js';
import VehicleSelection from './components/VehicleSelection';
import Colors from './Colors';
const Amplitude = Platform.select({
    web: require('./FakeAmplitude').default,
    default: require('@amplitude/analytics-react-native')
  })

  import { MaterialCommunityIcons } from '@expo/vector-icons';
import RBSheet from "react-native-raw-bottom-sheet";
import AntIcon from '@expo/vector-icons/AntDesign';
import * as WebBrowser from 'expo-web-browser';
import Constants from 'expo-constants';
const undefinedVehicleImg = require('./assets/vehicles/Unknown.png');
var vehicleTypes = [
    {load: 5,storage:"(cm): 35 x 25 x 25", img: require('./assets/vehicles/Motor.png'), inside: require('./assets/vehicles/MotorInside.png')},
    {load: 20,storage:"(cm): 70 x 50 x 50"}, //, img: require('./assets/vehicles/Sedan.png')
    {load: 200,storage:"(m): 1.5 x 1.2 x 1.2", img: require('./assets/vehicles/Doblo.png'), inside: require('./assets/vehicles/DobloInside.png')},
    {load: 500,storage:"(m): 2.1 x 1.7 x 1.2"},//, img: require('./assets/vehicles/Transit.png')},
    {load: 800,storage:"(m): 2.5 x 1.5 x 1.5", img: require('./assets/vehicles/Panelvan.png'), inside: require('./assets/vehicles/PanelvanInside.png')},
    {load: 1200,storage:"(m): 2.8 x 2 x 2", img: require('./assets/vehicles/Kamyonet.png'), inside: require('./assets/vehicles/KamyonetInside.png')},
    {}
]

{/* <VehicleSelection onClick={() => this.setState({selectedVehicle: 0})} selected={this.state.selectedVehicle == 0} name={global.getText("vehicles")[0]} storage={"35 x 25 x 12 cm"} load={"5 kg"} img={require('./assets/Motorcycle.png')}/>
                <VehicleSelection onClick={() => this.setState({selectedVehicle: 1})} selected={this.state.selectedVehicle == 1} name={global.getText("vehicles")[1]} storage={"70 x 50 x 50 cm"} load={"20 kg"} img={require('./assets/Sedan.png')}/>
                <VehicleSelection onClick={() => this.setState({selectedVehicle: 2})} selected={this.state.selectedVehicle == 2} name={global.getText("vehicles")[2]} storage={"2.3 x 1.2 x 1.2 m"} load={"500 kg"} img={require('./assets/Van.png')}/>
                <VehicleSelection onClick={() => this.setState({selectedVehicle: 3})} selected={this.state.selectedVehicle == 3} name={global.getText("vehicles")[3]} storage={"3 x 1.5 x 1.5 m"} load={"1000 kg"} img={require('./assets/10ftLorry.png')}/>
                <VehicleSelection onClick={() => this.setState({selectedVehicle: 4})} selected={this.state.selectedVehicle == 4} name={global.getText("vehicles")[4]} storage={"4.2 x 2 x 2 m"} load={"2500 kg"} img={require('./assets/14ftLorry.png')}/> */}
export default class RouteSettingsMenu extends React.Component {

    constructor({route, navigation}) {
        super();

        this.navigation = navigation;
        this.state = {selectedVehicle: undefined, selectedDate: 0,motorcycleOpen: true, selectedTime:0, editTime: true}
        

        this.order = {date:0, time:0,waypoints: route.params.waypoints};
        //global.showLoadingPanel();
        //this.fetchPricing();
        this.times = [];
        this.timeSelectionDays = [];
        var now = new Date();
        for(var i = 0; i < 30; i++) {
            let date = new Date();
            date.setDate(now.getDate() + i);
            //this.timeSelectionDays.push({date: date, t: (i == 0?"Bugün":i==1?"Yarın":daysStr[date.getDay()])+" " + date.getDate() + " " + monthsStr[date.getMonth()]});
            this.timeSelectionDays.push((i < 2?global.getText((i ==0)?"today":"tomorrow"):global.getText("days")[date.getDay()])+" " + date.getDate() + " " + global.getText((Platform.OS == 'ios')?"months":"monthsFull")[date.getMonth()]);
        }


        
        
        this.help = this.order.waypoints.find(s => s.help) != null;
        
       this.updateTimes();
       this.popupAnimation = new Animated.Value(0);
    }

    componentDidMount() {

        if(this.help) {
            this.order.time = 0;
            this.order.date = 0;
            this.timeSheet.close();
            
            //this.forceUpdate();

            this.fetchPricing();
            return;
        }
        this.timeSheet.open();
    }


    async fetchPricing() {
        
        //var waypoints = [];
        //this.order.waypoints.forEach((s) => waypoints.push([s.Point[0], s.Point[1]]));
        global.showLoadingPanel();

        console.log("Fetch pricing called!");
        var startDate = new Date();
        
        if(this.order.date != 0) {
            startDate.setHours(startDate.getHours()+1);
            startDate.setHours(0);
            startDate.setMinutes(0);
        } else {
            //startDate.setMinutes(Math.floor(startDate.getMinutes()/30)*30);
        }
        
        this.order.realDate = startDate.getTime() + (86400000*this.order.date) + (1800000*this.order.time);
        Amplitude.track("CALCULATE",{date: this.order.realDate,str: new Date(this.order.realDate).toLocaleString("tr-TR"), now: (this.order.date == 0 && this.order.time == 0)});
        

        //Date.now() + (86400000*this.order.date) + (600000*this.order.time)
        
        var calculations = await API.fetch('calculate', {waypoints: this.order.waypoints, date:this.order.realDate});
        

        if(this.coupons == undefined) this.coupons = await API.fetch("getcoupons");
        API.setAdditionalPrices(calculations.additionalPrices);


        

        for(var vehicleId = 0; vehicleId < calculations.routes.length; vehicleId++) {
           if(!calculations.routes[vehicleId]) continue;
            var fakeOrder = {...this.order,calculation: calculations.routes[vehicleId],vehicle: vehicleId};
            fakeOrder.date = new Date(fakeOrder.date);

            this.coupons.forEach((coupon) => {
                if(!coupon.provoke) return;
                if(API.checkCoupon(coupon, fakeOrder)) {
                    var discount = API.calculateCouponDiscount(coupon, fakeOrder);
                    if(calculations.routes[vehicleId].discount == undefined || calculations.routes[vehicleId].discount < discount) {
                        calculations.routes[vehicleId].discount = discount;
                        calculations.routes[vehicleId].coupon = coupon;
                    }
                }
            })
        }

       


        //this.order.serviceTime = calculations.serviceTime;
        //console.log(calculations);
        

        
        if(this.state.selectedVehicle == 0 && !calculations.motorcycleOpen) this.state.selectedVehicle = 2;

       
        this.setState({calculations: calculations.routes, motorcycleOpen: calculations.motorcycleOpen});
        global.hideLoadingPanel();
    }

    updateTimes() {

        var newTimes = [];
        
        var hour = 0;
        var time = 0;

        var now = new Date();
        now.setHours(now.getHours()+1);
        if(this.state.selectedDate == 0) newTimes.push(global.getText("asap"));
        var found = false;
        for(var i = 0; i < 48; i++) {

            if(this.state.selectedDate == 0 && (now.getHours() > hour || (hour == now.getHours() && time <= now.getMinutes()))) {
            
            } else {
                newTimes.push(digit(hour)+":"+digit(time));

                if(!found && this.times && this.times[this.state.selectedTime] == digit(hour)+":"+digit(time)) {
                    found = true;
                    this.state.selectedTime = newTimes.length-1;
                }
                
            }
            time += 30;

            if(time >= 60) {
                time = 0;
                hour++;
            }
        }

        if(!found) this.state.selectedTime = 0;

        this.times = newTimes;
    }

    getCurrentDateTime() {
        var d = new Date();
        d.setDate((new Date().getDate()+this.order.date));

        var time = global.getText("asap");

        if(this.order.time != 0 || this.order.date != 0) {
            var dd = new Date();
            dd.setHours(dd.getHours()+1);
            if(this.order.date != 0) dd = new Date(0);

            let min = ((this.order.date != 0)?0:Math.floor(dd.getMinutes()/30))+(this.order.time);
            let hours = (this.order.date == 0?dd.getHours():0)+Math.floor(min/2);

            let minutes = ((this.order.date == 0?(Math.floor(dd.getMinutes()/30)):0)+(this.order.time)-(Math.floor(min/2)*2))*30;
            

            time =  digit(hours)+":" + digit(minutes);
        }

        return ((this.order.date==0)?global.getText("today"):this.order.date==1?global.getText("tomorrow"):global.getText("days")[d.getDay()]) + " " + d.getDate() + " " + global.getText((Platform.OS=='ios')?"months":"monthsFull")[d.getMonth()] + ", " +time;
    
    }

    render() {
        

        
        

        return <View style={{display:"flex", flex:1, backgroundColor:"white"}}>


<View style={{paddingTop:Constants.statusBarHeight+15, paddingBottom:15, backgroundColor:"white", justifyContent:"center",flexDirection:"row",alignItems:"center"}}>
                <Text style={{fontWeight:"500",fontSize:18}}>{global.getText("routeSettings")}</Text>
                <TouchableOpacity onPress={() => this.navigation.pop()} style={{position:"absolute", left:"5%",marginLeft:-10,bottom:5}}><EvilIcon name='chevron-left' size={50}  /></TouchableOpacity>
            </View>

            <TouchableOpacity onPress={() => {
                Amplitude.track("DATE_SELECTION_OPENED")
                this.state.selectedTime = this.order.time;
                this.state.selectedDate = this.order.date;
                this.updateTimes();

                

                this.timeSheet.open();
                //this.setState({editTime: true});
            }} style={{paddingLeft:"5%", paddingRight:"5%", paddingTop:15, paddingBottom:15, display:"flex", flexDirection:"row", alignItems:"center", justifyContent:"space-between"}}>
                <View style={{display:"flex", flexDirection:"row", alignItems:"center"}}>
                <AntIcon name='calendar' size={20} />

                <Text style={{marginLeft:15, fontSize:18,fontWeight:"600"}}>{this.state.calculations != undefined?this.getCurrentDateTime():global.getText("selectTime")}</Text>
                </View>
                <EvilIcon name='chevron-right' size={30} />

            </TouchableOpacity>

            <View style={{display:"flex", flex:1, backgroundColor:"#F9F7FB"}}>

                
                <TouchableOpacity disabled={this.state.selectedVehicle == undefined} onPress={() => {
                    if(this.state.selectedVehicle == undefined) return;

                    this.setState({moreInfoVehicle: this.state.selectedVehicle});
                    Animated.timing(this.popupAnimation, {toValue:1, useNativeDriver: true, duration:100}).start();
                }} style={{display:"flex", alignItems:"center",marginBottom:10}}>
                   <Image style={{width:"60%",height:200}} resizeMode='contain' source={this.state.selectedVehicle == undefined?undefinedVehicleImg:vehicleTypes[this.state.selectedVehicle].inside} />


                    <View style={{justifyContent:"center", opacity: (this.state.selectedVehicle != undefined?1:0)}}>

                    <View style={{display:"flex", flexDirection:"row", justifyContent:"center", gap: 10}}><Text style={{fontSize:20, fontWeight:"bold", textAlign:"center"}}>{global.getText("vehicles")[this.state.selectedVehicle || 0]}</Text><MaterialCommunityIcons name="information-outline" size={24} color="black" /></View>
                    <Text style={{color:"#86888B", textAlign:"center", fontSize:16, marginTop:5}}>{global.getText("vehicleInfoStorage")} {vehicleTypes[this.state.selectedVehicle || 0].storage} | {global.getText("vehicleInfoMaxLoad")}{vehicleTypes[this.state.selectedVehicle || 0].load}</Text>

                    </View>
                </TouchableOpacity>


                
                {(this.state.calculations && this.state.calculations.find((s) => s && s.traffic) != undefined) && <Text style={{textAlign:"left",left:25,fontSize:11, color:"#ff0080", marginTop:5}}>{global.getText("highTraffic")}</Text>}
            {this.state.calculations && <FlatList numColumns={3} contentContainerStyle={{paddingLeft:"5%", paddingRight:"5%", paddingBottom:"5%"}} data={vehicleTypes} keyExtractor={(item, key) => key} renderItem={({item,index}) => (index==1 || index==3)?<></>:index+1==vehicleTypes.length?<>
            
           
            <TouchableOpacity onPress={() => {
                
                Amplitude.track("OPEN_PRICING_GUIDE");
                WebBrowser.openBrowserAsync("https://www.tasimacim.com/fiyatlandirma");
            }} style={{marginTop:10}}><Text style={{color:Colors.Blue,fontSize:16, fontWeight:"600"}}>{global.getText("pricingGuide")}</Text></TouchableOpacity></>:
            
            
            
            <VehicleSelection disabled={index == 0 && !this.state.motorcycleOpen} indx={index} left={index%2 == 1} discount={this.state.calculations[index].discount} traffic={this.state.calculations[index].traffic} onClick={() => this.setState({selectedVehicle: index})} selected={this.state.selectedVehicle == index} name={global.getText("vehicles")[index]} load={item.load + " kg"} price={this.state.calculations[index].price} storage={item.storage} img={item.img} />} />}
            </View>
            
            
            {/* {(this.state.selectedDate == 0 && this.state.selectedTime <= 6) && <View style={{backgroundColor:"crimson", padding:10, paddingRight:18, display:"flex", flexDirection:"row", alignItems:"center", borderTopLeftRadius: 10, borderTopRightRadius:10}}>
                <AntIcon name='clockcircle' size={14} color={"white"} />
                <Text style={{color:"white", fontWeight:"bold", marginLeft:8, fontSize:14}}>{global.getText("nowWarning")}</Text>
            </View>} */}
            <TouchableOpacity onPress={() => {

if(!API.user) {
    return global.promptLogin(() => {
        this.coupons = undefined;
        console.log("logged in");
        this.fetchPricing();
    })
}


                if(this.state.selectedVehicle == undefined) return;

                
                var order = {...this.order};
                order.vehicle = this.state.selectedVehicle;
                //order.date = Date.now();
                order.date = order.realDate;
                order.calculation = this.state.calculations[this.state.selectedVehicle];
                
                Amplitude.track("TO_ORDERDETAILS", {vehicle: order.vehicle});
                this.navigation.navigate('Order', {order});

        }} style={{backgroundColor:Colors.Blue,marginBottom:Platform.OS=='ios'?30:20,marginLeft:"5%", width:"90%", marginTop:10,  paddingBottom:12, paddingTop:12, display:"flex", justifyContent:"center", alignItems:"center", borderRadius:30}}><Text style={{color:"white", fontWeight:"700", fontSize:17}}>{global.getText(API.user?"next":"toContinueLogin")}</Text></TouchableOpacity>
        
        
             <RBSheet ref={(a) => this.timeSheet = a} height={Platform.OS =='ios'?300:200} customStyles={{container: {borderTopLeftRadius: 5, borderTopRightRadius:5}}} closeOnDragDown={false} closeOnPressMask={this.state.calculations != undefined} snapPoints={["40%"]}>
                 <View style={{display:"flex", paddingBottom:50, paddingTop: 0, borderTopLeftRadius: 5, borderTopRightRadius:5}}>
                    <View style={{display:"flex", flexDirection:"row",height:Platform.OS=='ios'?"85%":"80%", paddingLeft:10, paddingRight:10}}>
                    
                    <Picker itemStyle={{textAlign:"right"}} onValueChange={(i) => {
                        this.state.selectedDate = i;
                        
                        this.updateTimes();
                        this.forceUpdate();
                        }} selectedValue={this.state.selectedDate} style={{width:"60%", paddingLeft:0, paddingRight:0}}>
                        {this.timeSelectionDays.map((val, i) => <Picker.Item  label={val} key={i} value={i} />)}
                        
                    </Picker>

                    <Picker itemStyle={{textAlign:"left"}} onValueChange={(i) => this.setState({selectedTime: i})} selectedValue={this.state.selectedTime} style={{width:"40%", paddingLeft:0, paddingRight:0}}>
                        {this.times.map((val, i) => <Picker.Item label={val} key={i} value={i} />)}
                        
                    </Picker> 
                    </View>

                    <TouchableOpacity onPress={() => {
          
                    this.order.time = this.state.selectedTime;
                    this.order.date = this.state.selectedDate;
                    this.timeSheet.close();
                   
                    //this.forceUpdate();

                    this.fetchPricing();
                    //this.setState({editTime: false});
         

        }} style={{backgroundColor:Colors.Blue, marginLeft:"3%", marginRight:"3%", paddingBottom:12, paddingTop:12, display:"flex", justifyContent:"center", alignItems:"center", borderRadius:30}}><Text style={{color:"white", fontWeight:"700", fontSize:17}}>OK</Text></TouchableOpacity>
                
                    {this.state.calculations != undefined && <TouchableOpacity onPress={() => this.timeSheet.close()} style={{position:"absolute",right:10, top:10}}>
                        <AntIcon name='close' size={20} />
                    </TouchableOpacity>}
                </View>
            </RBSheet>



            {/* {this.state.editTime &&<View style={{backgroundColor:"rgba(0,0,0,.2)", position:"absolute", left:0, right:0, top:0, bottom:0}}>
             <BottomSheet snapPoints={["40%"]}>
                <View style={{display:"flex", flexDirection:"row", flex:1}}>
                    
                    <Picker itemStyle={{textAlign:"right"}} onValueChange={(i) => {
                        this.state.selectedDate = i;
                        this.updateTimes();
                        this.forceUpdate();
                        }} selectedValue={this.state.selectedDate} style={{width:"60%", paddingLeft:0, paddingRight:0}}>
                        {this.timeSelectionDays.map((val, i) => <Picker.Item  label={val} key={i} value={i} />)}
                        
                    </Picker>

                    <Picker itemStyle={{textAlign:"left"}} onValueChange={(i) => this.setState({selectedTime: i})} selectedValue={this.state.selectedTime} style={{width:"40%", paddingLeft:0, paddingRight:0}}>
                        {this.times.map((val, i) => <Picker.Item label={val} key={i} value={i} />)}
                        
                    </Picker> 

                    <TouchableOpacity onPress={() => {
          
                    this.order.time = this.state.selectedTime;
                    this.order.date = this.state.selectedDate;
                    this.setState({editTime: false});
         

        }} style={{backgroundColor:Colors.Blue, position:"absolute", bottom:30, left:"5%", right:"5%", paddingBottom:12, paddingTop:12, display:"flex", justifyContent:"center", alignItems:"center", borderRadius:30}}><Text style={{color:"white", fontWeight:"700", fontSize:17}}>OK</Text></TouchableOpacity>
                </View>
            </BottomSheet>

            
            </View>} */}

            {this.state.moreInfoVehicle != undefined && <Animated.View onPointerDown={(e) => {this.closeMoreInfo()}} style={{position:"absolute", display:"flex", justifyContent:"center", alignItems:"center",pointerEvents:(this.state.moreInfoVehicle != undefined?"auto":"none"),opacity: this.popupAnimation, left:0 ,right:0, top:0,bottom:0, backgroundColor:"rgba(0,0,0,.3)"}}>

                <View style={{width:"90%",position:"relative", borderRadius:10,paddingBottom:20, paddingHorizontal:20,backgroundColor:"white", display:"flex",alignItems:"center"}}>
                
                
                <Image style={{height:200}} resizeMode='contain' source={vehicleTypes[this.state.moreInfoVehicle].inside} />
                <TouchableOpacity style={{position:"absolute",zIndex:1, right: 10, top: 10}} onPress={() => this.closeMoreInfo()}><MaterialCommunityIcons name="close" size={24} color="black" /></TouchableOpacity>
                

<View style={{justifyContent:"center"}}>

<Text style={{fontSize:20, fontWeight:"bold", textAlign:"center"}}>{global.getText("vehicles")[this.state.moreInfoVehicle || 0]}</Text>
<Text style={{color:"#86888B", textAlign:"center", fontSize:14, marginTop:5}}>{global.getText("vehicleInfoStorage")} {vehicleTypes[this.state.moreInfoVehicle || 0].storage} | {global.getText("vehicleInfoMaxLoad")}{vehicleTypes[this.state.moreInfoVehicle || 0].load}</Text>
</View>


                <Text style={{fontWeight:"600", color:"gray",fontSize:15, marginTop:15, marginBottom:20}}>{global.getText("approximateLoads")}</Text>

                <View style={{display:"flex",width:"100%",flexDirection:"row",flexWrap:"wrap",justifyContent:"space-between"}}>
                    {global.getText("vehicleDatas")[this.state.moreInfoVehicle].map((x, i) => <View key={i} style={{width:70, justifyContent:"center",alignItems:"center", display:"flex"}}>
                
                <MaterialCommunityIcons size={60} name={x.icon} />
                <Text style={{width:"100%", textAlign:"center", marginTop:10}}>{x.amount}x</Text>
                <Text style={{width:"100%", textAlign:"center"}}>{x.name}</Text>
            </View>)}
                </View>
                {/* <FlatList horizontal contentContainerStyle={{gap: 30, paddingLeft:10,paddingBottom:10, paddingRight:10}} renderItem={({item, index}) => <View style={{width:70, justifyContent:"center",alignItems:"center", display:"flex"}} key={index}>
                
                    <MaterialCommunityIcons size={60} name={item.icon} />
                    <Text style={{width:"100%", textAlign:"center", marginTop:10}}>{item.amount}x</Text>
                    <Text style={{width:"100%", textAlign:"center"}}>{item.name}</Text>
                </View>} data={global.getText("vehicleDatas")[this.state.moreInfoVehicle]} /> */}
                </View>

            </Animated.View>}
        </View>
    }

    closeMoreInfo() {
        Animated.timing(this.popupAnimation, {toValue:0, useNativeDriver: true, duration:100}).start();

        setTimeout(() => {
            this.setState({moreInfoVehicle: undefined});
        }, 150);
        

    }
}

function digit(num) {
    return `${num}`.padStart(2, '0');
}